import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import styles from "./SectionCTA2.module.scss";
import { useTheme } from "../../store/ui/selector";

export const SectionCTA2 = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { theme, defaultTheme } = useTheme();

  return (
    <section
      className="pt-24 relative"
      style={{ backgroundColor: "rgb(245,245,245)" }}
    >
      <div className="pt-24 md:pt-24 bg-blueGray-800 ">
        <div className="container mx-auto" style={{ marginTop: -100 }}>
          <div
            className={`${styles.cta2} flex flex-wrap items-center flex-col mx-6`}
            style={{
              background: theme?.Content?.styles?.cta2?.backgroundColor,
              color: theme?.Content?.styles?.cta2?.textColor
          }}
          >
            <h2 className="text-2xl md:text-4xl text-center font-semibold font-heading mb-10">
              {t("index.cta2.title")}
            </h2>
            <SecondaryButton
              onClick={() => {
                router.push("/products");
              }}
            >
              {t("index.cta2.button")}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </section>
  );
};
