import React, { useEffect, useState } from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import styles from "./Slider.module.scss";
import { gkb } from "../../services/gkb";
import { DispatchSetLoading } from "../../store/ui/actions";
import { useDispatch } from "react-redux";

export const Slider = () => {
  const { t, i18n } = useTranslation();
  const [slides, setSlides] = useState<any>([]);
  const { language } = i18n;
  const dispatch = useDispatch();

  const Container = ({ children }: any) => (
    <div className={styles.slider}>{children}</div>
  );
  const CarouselUI = ({ position, handleClick, children }) => (
    <Container>
      {children}
      <div
        className={styles.arrowLeft}
        onClick={handleClick}
        data-position={position - 1}
      >
        {"<"}
      </div>
      <div
        className={styles.arrowRight}
        onClick={handleClick}
        data-position={position + 1}
      >
        {">"}
      </div>
    </Container>
  );
  const Carousel = makeCarousel(CarouselUI);

  useEffect(() => {
    DispatchSetLoading(dispatch, true);

    gkb
      .ListApiResource(
        { name: "slides", filter: '"visible"=eq(true)' },
        { pageSize: 10, sort: '"order".ASC' }
      )
      .then((res) => {
        setSlides(res.Data);
        DispatchSetLoading(dispatch, false);
      })
      .catch();
  }, []);

  return (
    <Carousel
      defaultWait={50000} /* defaultWait={5000} wait for 1000 milliseconds */
    >
      {slides.map((s, i) => (
        <Fade right key={`slider_${i}`}>
          <div className="container px-4 mx-auto flex items-center justify-center h-full ">
            <div className="grid md:grid-cols-2 md:gap-4 px-10 md:px-0">
              <div className="relative md:flex flex-wrap -mx-4 py-30 hidden">
                <img alt="slide" src={s.Image} style={{ maxHeight: 500 }} />
              </div>
              <div className="relative flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 px-4 py-0" />
                <div className="relative w-full lg:w-1/2 px-4 order-first lg:order-last">
                  <div className="relative lg:pl-10 max-w-lg mb-10 lg:mb-0 text-center md:text-left">
                    <div
                      className={"slide-content"}
                      dangerouslySetInnerHTML={{ __html: s.Content[language] }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      ))}
    </Carousel>
  );
};
