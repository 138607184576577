import React, { useState } from "react";
import { notify } from "reapop";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ValidateEmail } from "../../services/validators";
import { Profile } from "../../models/user";
import { gkb } from "../../services/gkb";
import google from "../../assets/img/google.svg";
import linkedin from "../../assets/img/linkedin.svg";
import ReactFlagsSelect from "react-flags-select";
import styles from "./Profile.module.scss";
import esCountries from "../../models/countries.json";
import ReactGA from "react-ga";
import { useSession } from "react-session-persist";
import { CountrySelect } from "../CountrySelect/CountrySelect";

interface PersonalDataProps {
  profile: Profile;
}

export const PersonalData = ({ profile }: PersonalDataProps) => {
  const { t } = useTranslation("profile");
  const [FirstName, setFirstName] = useState(profile.FirstName);
  const [LastName, setLastName] = useState(profile.LastName);
  const [Email, setEmail] = useState(profile.Email);
  const [Password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const { authenticated, session, saveUser, saveSession, user } = useSession();

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let errors = false;
    if (Password !== confirm) {
      setPasswordError(t("error-password-not-match"));
      errors = true;
    }

    if (profile.ProviderType === "credentials") {
      if (Email === "") {
        setEmailError(t("profile.error-email-empty"));
        errors = true;
      } else if (!ValidateEmail(Email)) {
        setEmailError(t("profile.error-email-invalid"));
        errors = true;
      }
    }

    if (FirstName === "") {
      setFirstNameError(t("profile.error-name-empty"));
      errors = true;
    }
    if (LastName === "") {
      setLastNameError(t("profile.error-lastname-empty"));
      errors = true;
    }

    if (errors) {
      return;
    }

    const newProfile = {
      firstName: FirstName,
      lastName: LastName,
      email: Email.toLowerCase(),
      password: Password,
    };
    setSaving(true);
    const response = await gkb.UpdateApiResource({
      name: "accounts",
      id: profile.ID,
      body: newProfile,
    });

    if (response) {
      const usr = {
        FirstName: response.FirstName,
        LastName: response.LastName,
        Email: response.Email !== "" ? response.Email.toLowerCase() : Email,
        Name: `${response.FirstName} ${response.LastName}`,
      };
      dispatch({
        type: "@SESSION/SET_PROFILE",
        payload: { ...usr },
      });
      dispatch(notify(t("profile.profile-saved-success"), "success"));
      saveUser( {...user, ...usr} );
      setPassword("");
      setConfirm("");
      ReactGA.event({
        category: "profile",
        action: `Profile saved successfully`,
      });
    } else {
      dispatch(notify(t("profile.profile-saved-error"), "error"));
      ReactGA.event({
        category: "profile",
        action: `Profile saved error`,
      });
    }

    setSaving(false);
  };

  return (
    <div className="flex-auto px-4 lg:px-10 py-10" style={{ maxWidth: 700 }}>
      <form onSubmit={handleSaveChanges}>
        <div className="flex flex-wrap w-full">
          <div className="w-full lg:w-full px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("profile.email")}
            </label>
            <div className="flex items-center justify-end">
              <input
                disabled={profile.ProviderType !== "credentials"}
                id="grid-email"
                type="email"
                className={`${
                  emailError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={Email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value.toLowerCase());
                  setEmailError("");
                }}
              />

              {profile.ProviderType === "google" && (
                <div className="icon ml-4 static">
                  <img src={google} height="32px" width="32px" />
                </div>
              )}
              {profile.ProviderType === "linkedin" && (
                <div className="icon ml-4 relative">
                  <img src={linkedin} height="32px" width="32px" />
                </div>
              )}
            </div>
          </div>
        </div>
        {emailError && (
          <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
            {emailError}
          </div>
        )}
        <div className="flex flex-wrap w-full my-10">
          <div className="w-full px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-country"
            >
              {t("profile.country")}
            </label>
            <CountrySelect
              searchable={true}
              selected={profile.Country}
              onSelect={() => {}}
              disabled={true}
              showSelectedLabel
              showOptionLabel
              placeholder={t("register.select-country")}
              searchPlaceholder={t("register.search-country")}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-10">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-name"
            >
              {t("profile.name")}
            </label>
            <input
              id="grid-name"
              type="text"
              className={`${
                firstNameError !== "" ? "border-1 border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              defaultValue={FirstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
            {firstNameError && (
              <div className="text-red-600 italic text-sm text-left">
                {firstNameError}
              </div>
            )}
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-lastname"
            >
              {t("profile.lastname")}
            </label>
            <input
              id="grid-lastname"
              type="text"
              className={`${
                lastNameError !== "" ? "border-1 border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              defaultValue={LastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
            {lastNameError && (
              <div className="text-red-600 italic text-sm text-left">
                {lastNameError}
              </div>
            )}
          </div>
        </div>
        {profile.ProviderType === "credentials" && (
          <div className="flex flex-wrap w-full mt-10">
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-password"
              >
                {t("profile.password")}
              </label>
              <input
                id="grid-password"
                type="password"
                disabled={profile.ProviderType !== "credentials"}
                className={`${
                  passwordError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={Password}
                placeholder="****************************"
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setPasswordError("");
                }}
              />
            </div>
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-confirm"
              >
                {t("profile.confirm")}
              </label>
              <input
                id="grid-confirm"
                type="password"
                disabled={profile.ProviderType !== "credentials"}
                className={`${
                  passwordError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={confirm}
                placeholder="****************************"
                onChange={(e) => {
                  setConfirm(e.currentTarget.value);
                  setPasswordError("");
                }}
              />
            </div>
          </div>
        )}
        {passwordError && (
          <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
            {passwordError}
          </div>
        )}
        <div className="flex flex-wrap w-full mt-10 justify-around">
          <PrimaryButton loading={saving} type={"submit"}>
            {t("profile.save")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
