import React, { createRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/Table/Table";
import { gkb } from "../../../services/gkb";

const SubscriptionsPage = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const tableRef = createRef();

  const tableSettings = {
    columns: [
      { text: "#", field: "ID", width: 100 },
      {
        text: t("subscriptions.status"),
        width: 248,
        field: "Status",
        getTextStyle: (value) => {
          switch (value.toLowerCase()) {
            case "canceled":
              return "rounded bg-red-200 py-1 px-3";
            case "active":
              return "rounded bg-green-200 py-1 px-3";
            case "past_due":
              return "rounded bg-red-200 py-1 px-3 ";
            case "pending-cancel":
            case "incomplete":
              return "rounded bg-yellow-200 py-1 px-3 ";
            case "trialing":
              return "rounded bg-blue-200 py-1 px-3 ";
            default:
              return "";
          }
        },
      },
      {
        text: t("type"),
        field: "Type",
      },
      {
        text: t("subscriptions.created_at"),
        field: "CreatedAt",
        type: "date",
      },
      {
        text: t("subscriptions.next-payment"),
        field: "CurrentPeriodEnd",
        type: "date",
      },
      {
        text: t("subscriptions.total"),
        field: "Total",
        type: "currency",
        currencyField: "LatestInvoice.Currency",
      },
      { text: "", field: "actions" },
    ],
    actions: [
      {
        text: t("subscriptions.cancel-subscription"),
        action: "cancel",
        color: "text-red-600",
        icon: "fa-window-close",
      },
      {
        text: t("subscriptions.reactivate"),
        action: "activate",
        color: "text-green-600",
        icon: "fa-check-square",
      },
      {
        text: t("subscriptions.vew-details"),
        action: "view",
        color: "text-green-600",
        icon: "fa-file-contract",
      },
    ],
  };

  const fetchData = async (pageSize, page) => {
    try {
      const response = await gkb.ListApiResource(
        { name: "subscriptions", filter: "" },
        { pageSize, page }
      );

      return {
        data: response.Data.map((s) => ({
          ...s,
          Status:
            s.Status === "active" && s.CancelAtPeriodEnd
              ? "pending-cancel"
              : s.Status,
          CurrentPeriodEnd:
            s.Status === "active" && !s.CancelAtPeriodEnd
              ? s.CurrentPeriodEnd
              : "",
          Total: s.LatestInvoice?.Total ?? 0,
        })),
        totalPages: response.TotalPages,
      };
    } catch (err) {
      return {
        data: [],
        totalCount: 0,
      };
    }
  };

  const handleTableAction = async (action, row) => {
    if (action === "view") {
      router.push(`/my-account/subscriptions/${row.ID}`, { subscription: row });
    }

    // if (action === 'cancel') {
    //   const newSubs = await cancelSubscription(row.id);
    //   (tableRef.current as any).reload();
    //   return;
    // }
    //
    // if (action === 'activate') {
    //   const newSubs = await activateSubscription(row.id);
    //   (tableRef.current as any).reload();
    // }
  };

  const handleRenderAction = (action, row) => {
    if (action === "cancel") {
      return row.status === "active";
    }

    if (action === "activate") {
      return row.status === "pending-cancel";
    }

    return true;
  };

  const handleRenderText = (value, column, row) => {
    if (column.field === "Status") {
      if (value === "incomplete") {
        return t("subscriptions.pending-payment");
      }
      return t(value);
    }
    if (column.field === "BillingReason") {
      return t(value);
    }
    if (column.field === "Type") {
      if (row.Items[0].Sku === "reseller") {
        return t("reseller");
      }
      return t(row.Items[0].Sku.split(":")[1]);
    }
    if (column.field === "CurrentPeriodEnd" && row.Status === "trialing") {
      return row.TrialEnd;
    }
    return value;
  };

  return (
    <section className="px-4 text-center w-full" style={{ minHeight: 650 }}>
      <Table
        title={t("subscriptions.title")}
        ref={tableRef}
        settings={tableSettings}
        fetchData={fetchData}
        onAction={handleTableAction}
        renderAction={handleRenderAction}
        renderText={handleRenderText}
      />
    </section>
  );
};

export default SubscriptionsPage;
