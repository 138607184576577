import React, { useEffect, useState } from "react";

import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";
import esCountries from "../../models/countries.json";

import styles from "../Profile/Profile.module.scss";
import { Profile } from "../../models/user";
import { CountrySelect } from "../CountrySelect/CountrySelect";

interface BillingDataProps {
  profile: Profile;
  handleChange: (BillingData) => void;
}

export const CartBillingDataComponent = ({
  profile,
  handleChange,
}: BillingDataProps) => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState(
    profile.BillingDataName ?? `${profile.FirstName} ${profile.LastName}`
  );
  const [cif, setCIF] = useState(profile.BillingDataVat ?? "");
  const [phone, setPhone] = useState(profile.BillingDataPhone ?? "");
  const [address, setAddress] = useState(profile.BillingDataAddress ?? "");
  const [email, setEmail] = useState(
    profile.BillingDataEmail ?? `${profile.Email}`
  );
  const [city, setCity] = useState(profile.BillingDataCity ?? "");
  const [country, setCountry] = useState(
    profile.BillingDataCountry?.toUpperCase() ?? "ES"
  );
  const [state, setState] = useState(profile.BillingDataState ?? "");
  const [zipCode, setZipCode] = useState(profile.BillingDataZipCode ?? "");

  const [saveAsDefault, setSaveAsDefault] = useState(true);

  const handleChangeLanguage = (code) => {
    setCountry(code);
  };

  useEffect(() => {
    const bd = {
      name,
      cif,
      phone,
      address,
      email,
      city,
      country,
      state,
      zipCode,
      saveAsDefault,
    };
    bd.phone = `+${phone.replace("+", "")}`;

    handleChange(bd);
  }, [
    name,
    cif,
    phone,
    address,
    email,
    city,
    country,
    state,
    zipCode,
    saveAsDefault,
  ]);

  const locale = i18n.language?.toLowerCase() ?? "";
  return (
    <div className="flex-auto lg:px-10 pt-5 pb-10" style={{ maxWidth: 700 }}>
      <form>
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-name"
            >
              {t("profile.business-name")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-name"
                type="text"
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full md:w-6/12 md:pr-2">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-cif"
            >
              {t("profile.cif")}
            </label>
            <input
              id="grid-cif"
              type="text"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear"
              value={cif}
              onChange={(e) => setCIF(e.currentTarget.value)}
            />
          </div>
          <div className="w-full md:w-6/12 md:pl-2 mt-5 md:mt-0">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-phone"
            >
              {t("profile.phone")}
            </label>
            <PhoneInput
              inputClass="text-md"
              localization={locale === "es" ? es : {}}
              country="es"
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full lg:w-full">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("profile.email")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-email"
                type="email"
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value.toLowerCase());
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full lg:w-full">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-address"
            >
              {t("profile.address")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-address"
                type="text"
                className={`border-0  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={address}
                onChange={(e) => {
                  setAddress(e.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full md:w-6/12 md:pr-2">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-country"
            >
              {t("profile.country")}
            </label>
            <CountrySelect
              searchable
              selected={country}
              onSelect={handleChangeLanguage}
              showSelectedLabel
              showOptionLabel
            />
          </div>
          <div className="w-full md:w-6/12 md:pl-2 mt-5 md:mt-0">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-state"
            >
              {t("profile.state")}
            </label>
            <input
              id="grid-state"
              type="text"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full md:w-6/12 md:pr-2">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-city"
            >
              {t("profile.city")}
            </label>
            <input
              id="grid-city"
              type="text"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </div>
          <div className="w-full md:w-6/12 md:pl-2 mt-5 md:mt-0">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-zip"
            >
              {t("profile.zip-code")}
            </label>
            <input
              id="grid-zip"
              type="text"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={zipCode}
              onChange={(e) => setZipCode(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-5">
          <input
            className="text-md"
            type="checkbox"
            checked={saveAsDefault}
            onChange={(e) => {
              setSaveAsDefault(e.target.checked);
            }}
          />
          <span className="ml-2">{t("save-as-default")}</span>
        </div>
      </form>
    </div>
  );
};
