import { AnyAction } from "redux";

export interface ProductsState {
  products: any;
  countries: any;
}

export const ProductsInitialState = {
  products: [],
  countries: [],
};

const reducer = (
  state: ProductsState = ProductsInitialState,
  action: AnyAction
): any => {
  switch (action.type) {
    case "@PRODUCTS/SET_DATA":
      return { ...state, products: [...action.payload] };
    case "@PRODUCTS/SET_COUNTRIES":
      return { ...state, countries: [...action.payload] };
    default:
      return state;
  }
};

export default reducer;
