import React, { useRef, useState } from "react";
import { GKBilling } from "gkshared";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ValidateEmail } from "../../services/validators";
import { notify } from "reapop";
import { useTranslation } from "react-i18next";
import { LostPasswordModal } from "../Modals/LostPassword";
import { SetCountryModal } from "../Modals/SetCountry";
import ReactGA from "react-ga";
import { useTheme } from "../../store/ui/selector";
const gkb = new GKBilling((process.env.APPLICATION_ID as string) || "");

export default function LoginFormComponent({ prepareToken }: any) {
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [token, setToken] = useState<any>(null);
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const { theme } = useTheme();

  const router = useHistory();
  const dispatch = useDispatch();

  const formValid = () => {
    if (password === "") {
      return false;
    }

    if (username === "") {
      return false;
    }

    if (!ValidateEmail(username)) {
      return false;
    }

    return true;
  };

  const handleSetCountry = (country) => {
    setToken(null);
    prepareToken(token);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!formValid()) {
      return;
    }

    setLoading(true);
    try {
      const { token, error } = await gkb.Login(
        username,
        password,
        "credentials"
      );

      if (!error) {
        if (token.account.Country === "") {
          setToken(token);
          setShowCountryModal(true);
          return;
        }
        prepareToken(token);
      } else if (
        error.status === 401 &&
        error.error.details === "email not verified"
      ) {
        router.push(`/not-verified?email=${username}` /* '/not-verified' */);
      } else {
        if (error.error?.details) {
          dispatch(notify(error.error.details, "error"));
        } else {
          dispatch(notify(t("login.network-error"), "error"));
        }
      }
    } catch (err) {
      dispatch(notify(err.message, "error"));
    }
    setLoading(false);
  };

  const cancelResetPassword = () => {
    setShowResetPasswordModal(false);
  };

  const aceptResetPassword = async (newEmail) => {
    setShowResetPasswordModal(false);
    setLoading(true);
    try {
      const { token, error } = await gkb.ResetPassword(newEmail);
      dispatch(notify(t("login.reset-password-email-sent-ok"), "success"));
      ReactGA.event({
        category: "login",
        action: `Reset password request`,
      });
    } catch (err) {
      dispatch(notify(t("login.reset-password-email-sent-error"), "error"));
    }
    setLoading(false);
  };

  return (
    <>
      <LostPasswordModal
        show={showResetPasswordModal}
        onChange={aceptResetPassword}
        onClose={cancelResetPassword}
      />
      <SetCountryModal
        userID={token?.user_id || 0}
        show={showCountryModal}
        onClose={() => setShowCountryModal(false)}
        onChange={handleSetCountry}
      />
      <section className="px-4 text-center pb-10 w-full lg:w-full mt-10">
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="w-full lg:w-6/12 mx-auto">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("login.email")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-email"
                type="email"
                className={`${
                  emailError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full lg:w-6/12 mt-10 mx-auto">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-password"
            >
              {t("login.password")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-password"
                type="password"
                className={`px-3 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear `}
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <p
              className="text-right italic text-sm text-green-600 cursor-pointer hover:underline"
              onClick={() => setShowResetPasswordModal(true)}
              style={{
                color: theme?.Content?.styles?.primaryColor ,
              }}

            >
              {t("login.lost-my-password")}
            </p>
          </div>

          <div className="mt-10">
            <PrimaryButton
              id="submit-login"
              //onClick={handleSubmit}
              disabled={!formValid()}
              loading={loading}
              type={"submit"}
            >
              {t("login.login")}
            </PrimaryButton>
          </div>
        </form>
      </section>
    </>
  );
}
