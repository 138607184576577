import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "../Subscription/SubscriptionDetails.module.scss";
import pending from "../../assets/img/pending.svg";
import verified from "../../assets/img/verified.svg";
import unverified from "../../assets/img/unverified.svg";

export const ResellerInfo = ({ reseller }: any) => {
  const { t } = useTranslation();
  const router = useHistory();

  if (!reseller) {
    return null;
  }

  const resAccount = reseller.Reseller;
  const strAccount = reseller.Account;

  if (!resAccount || !strAccount) {
    return null;
  }

  const image =
    strAccount.individual.verification.status === "unverified"
      ? unverified
      : strAccount.individual.verification.status === "pending"
      ? pending
      : verified;

  return (
    <div className="w-full rounded bg-white shadow-lg">
      <ul className="">
        <li
          className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
        >
          <div className="flex-1 text-green-600 text-left font-bold">#</div>
          <div className="flex-1 font-bold text-center">
            {t("reseller.type")}
          </div>
          <div className="flex-1 font-bold text-center">
            {t("reseller.name")}
          </div>
          <div className="flex-1 font-bold text-center">
            {t("reseller.status")}
          </div>
        </li>
        <li
          className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600 items-center`}
        >
          <div className="flex-1 text-gray-600 text-left">{resAccount.ID}</div>
          <div className="flex-1 text-gray-600 text-center">Individual</div>
          <div className="flex-1 text-gray-600 text-center flex justify-center items-center">
            {`${strAccount.individual.first_name} ${strAccount.individual.last_name}`}
          </div>
          <div className="flex-1 text-gray-600 text-center flex justify-center items-center cursor-pointer">
            <span className="tooltip">
              <img
                onClick={() => {
                  if (
                    strAccount.individual.verification.status === "unverified"
                  ) {
                    router.push("/my-account/reseller/onboarding");
                  }
                }}
                alt={strAccount.individual.verification.status}
                src={image}
                width={32}
                height={32}
              />
              <span className="tooltiptext">
                {t(strAccount.individual.verification.status)}
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};
