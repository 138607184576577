import { AnyAction } from "redux";

export interface CartState {
  items: any;
}

export const CartInitialState = {
  items: [],
};

const reducer = (
  state: CartState = CartInitialState,
  action: AnyAction
): any => {
  switch (action.type) {
    case "@CART/SET_DATA":
      return { items: [...action.payload] };
    case "@CART/ADD_ITEM": {
      const { user, product, id } = action.payload;
      const items = [
        {
          quantity: 1,
          subs: 0,
          type: product.Sku,
          user_id: user,
          id,
          product: action.payload.product,
          period: action.payload.period,
          product_id: action.payload.product_id,
          trial: action.payload.trial,
          company: action.payload.company,
          coupon: null,
        },
      ];

      // let items = [...state.items];
      // let found = false;
      // if (product === 'professional') {
      //   for (let i = 0; i < items.length; i++) {
      //     if (
      //       items[i].type === 'professional' &&
      //       items[i].period === action.payload.period
      //     ) {
      //       items[i] = {
      //         ...items[i],
      //         quantity: items[i].quantity + 1,
      //       };
      //       found = true;
      //       break;
      //     }
      //   }

      //   if (!found) {
      //     items = [
      //       ...items,
      //       {
      //         quantity: 1,
      //         subs: 0,
      //         type: 'professional',
      //         user_id: user,
      //         id,
      //         period: action.payload.period,
      //         product_id: action.payload.product_id,
      //         coupon: action.payload.coupon,
      //       },
      //     ];
      //   }
      // } else {
      //   // Only one corporate or fidelium for cart
      //   const aux = items.filter((item) => item.type === product);
      //   if (aux.length === 0) {
      //     items = [
      //       ...items,
      //       {
      //         quantity: 1,
      //         subs: 1,
      //         type: action.payload.product,
      //         user_id: user,
      //         id,
      //         period: action.payload.period,
      //         product_id: action.payload.product_id,
      //         coupon: action.payload.coupon,
      //       },
      //     ];
      //   }
      // }

      return { ...state, items };
    }

    case "@CART/REMOVE_ITEM": {
      const items = [
        ...state.items.filter((i: any) => i.id !== action.payload.id),
      ];
      return { ...state, items };
    }
    case "@CART/DECREMENT_QUANTITY": {
      const items = [
        ...state.items.map((i: any) =>
          i.id === action.payload.id
            ? {
                ...i,
                quantity: i.quantity - action.payload.quantity,
              }
            : i
        ),
      ];
      return { ...state, items };
    }
    case "@CART/INCREMENT_QUANTITY": {
      const items = [
        ...state.items.map((i: any) =>
          i.id === action.payload.id
            ? {
                ...i,
                quantity: i.quantity + action.payload.quantity,
              }
            : i
        ),
      ];
      return { ...state, items };
    }
    case "@CART/APPLY_COUPON": {
      const items = [
        ...state.items.map((i: any) => ({
          ...i,
          coupon: action.payload.coupon,
        })),
      ];
      return { items };
    }
    default:
      return state;
  }
};

export default reducer;
