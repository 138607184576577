import React from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import styles from "./CountrySelect.module.scss";
import esCountries from "../../models/countries.json";
import allCountries from "../../models/allCountries.json";

interface CountrySelectProps {
  searchable: boolean;
  selected: string;
  onSelect: (code: string) => void;
  showSelectedLabel: boolean;
  showOptionLabel: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({
  searchable,
  selected,
  onSelect,
  showSelectedLabel,
  showOptionLabel,
  disabled = false,
  placeholder = "",
  searchPlaceholder = "",
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language?.toLowerCase() ?? "";

  return (
    <ReactFlagsSelect
      searchable={searchable}
      selectButtonClassName={styles.countrySelect}
      countries={allCountries}
      className={styles.countryFlags}
      selected={selected}
      onSelect={onSelect}
      showSelectedLabel={showSelectedLabel}
      showOptionLabel={showOptionLabel}
      disabled={disabled}
      customLabels={locale === "es" ? esCountries : {}}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
    />
  );
};
