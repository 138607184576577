import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gkb } from "../services/gkb";
import { DispatchSetLoading } from "../store/ui/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "../store/ui/selector";

type LegalPageProps = {
  page: string;
};

export default function LegalPage({ page }: LegalPageProps) {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [content, setContent] = useState<any>(null);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const location = useLocation();

  useEffect(() => {
    // Recarga la página cuando cambia la ruta
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        { name: "legals", filter: `"name"=eq(${page})` },
        { pageSize: 1 }
      )
      .then((res) => {
        if(res.Data.length === 0) {
          router.push("/404");
          return;
        }
        setContent(res.Data[0]);
      })
      .catch().finally(() => {
        DispatchSetLoading(dispatch, false);
      });

  }, [location.pathname]);

  return (
    <>
      <section className="pt-12 pb-40 px-4 text-center">
        <div className="text-justify"
          dangerouslySetInnerHTML={{ __html: content?.Content[language] }}
        >
        </div>
      </section>
    </>
  );
}

