import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../store/ui/selector';

const CookieConsent: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { theme } = useTheme();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setShowBanner(consent === null);
  }, []);

  const handleConsent = (): void => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
  };

  const handleCancel = (): void => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true"></div>
      <div className="fixed bottom-0 left-0 right-0 bg-gray-200 p-4 text-center z-50"
      style={{ backgroundColor: theme?.Content?.cookies?.backgroundColor }}>
        <p className="text-gray-700 text-xl"
           style={{ color: theme?.Content?.cookies?.color }}>
          Este sitio utiliza cookies para mejorar la experiencia del usuario.
          <Link to="/legal/cookies" className="border-b-2 hover:opacity-70"> Más información</Link>.
          ¿Aceptas su uso?
        </p>
        <button className="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mr-2" onClick={handleConsent}
                style={{ ...theme?.Content?.cookies?.acept }}>
          Aceptar
        </button>
        <button className="mt-2 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded" onClick={handleCancel}
                style={{ ...theme?.Content?.cookies?.cancel }}>
          Cancelar
        </button>
      </div>
    </>
  );
};

export default CookieConsent;
