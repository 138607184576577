import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { Verification } from "../../../models/reseller";
import { gkb } from "../../../services/gkb";
import { SecondaryButton } from "../../Buttons/SecondaryButton";

export const OnBoardingStep5 = ({ onSubmit, previousStep }: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Verification>({
    IdentityFile: null,
    AddressFile: null,
  });

  const handleSubmit = async () => {
    setLoading(true);

    const idFile = await gkb.UploadDocument(
      "identity_document",
      state.IdentityFile
    );

    const addFile = await gkb.UploadDocument(
      "additional_verification",
      state.AddressFile
    );

    await onSubmit(4, {
      IdentityFile: idFile.id,
      AddressFile: addFile.id,
    });
    setLoading(false);
  };

  return (
    <div className="w-full px-4 lg:px-10 py-4 justify-center">
      <form>
        <div className="flex flex-wrap w-full mt-6">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-file-identity"
            >
              {t("profile.identity-file")}
            </label>
            <input
              id="grid-file-identity"
              type="file"
              className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear"
              onChange={(e) => {
                if (e?.target?.files && e?.target?.files.length > 0) {
                  setState({ ...state, IdentityFile: e.target.files[0] });
                }
              }}
            />
            <div className={"text-left text-xs italic"}>
              {t("profile.identity-file2")}
            </div>
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-file-identity"
            >
              {t("profile.additional-file")}
            </label>
            <input
              id="grid-file-address"
              type="file"
              className="border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear"
              onChange={(e) => {
                if (e?.target?.files && e?.target?.files.length > 0) {
                  setState({ ...state, AddressFile: e.target.files[0] });
                }
              }}
            />
            <div className={"text-left text-xs italic"}>
              {t("profile.additional-file2")}
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end mt-12">
          {!loading && (
            <SecondaryButton onClick={previousStep} className="mr-2">
              {t("back")}
            </SecondaryButton>
          )}

          <PrimaryButton
            onClick={handleSubmit}
            loading={loading}
            disabled={!state.IdentityFile || !state.AddressFile}
          >
            {t("next")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
