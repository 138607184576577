import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DateTime } from "luxon";
import ReactPaginate from "react-paginate";

import { useTranslation } from "react-i18next";
import LocalLoader from "../bgLoader/LocalLoader";
import styles from "./table.module.scss";
import empty from "../../assets/img/no-data.svg";
import { getCurrencySymbol } from "../../services/currency";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import { DownloadUrl } from "../../services/file-download";
import { getIn } from "../../utils";
import { useDebounce } from "use-debounce";
import { useTheme } from "../../store/ui/selector";

const dl = navigator.language || (navigator as any).userLanguage;

const showPrice = (price: number, currency: string) => {
  if (!currency) {
    return price;
  }

  const symbol = getCurrencySymbol(currency);

  const pr = Number(price).toLocaleString(dl, {
    currency: currency?.toUpperCase() || "€",
    minimumFractionDigits: 2,
  });

  if (symbol.p === "right") {
    return `${pr} ${symbol.s}`;
  } else {
    return `${symbol.s} ${pr}`;
  }
};

const renderValue = (value, column, row, textStyle, renderText) => {
  if (column.type === "date") {
    let v;
    if (renderText) {
      v = renderText(value, column, row);
    } else {
      v = value;
    }
    const dt = DateTime.fromISO(v);
    return dt.isValid ? (
      <div className={textStyle}>
        {dt.toLocaleString(Object.assign(DateTime.DATE_FULL, { locale: dl }))}
      </div>
    ) : (
      <div className={textStyle}>---</div>
    );
  }
  if (column.type === "currency") {
    const currency = getIn(row, column.currencyField);

    return (
      <div className={`${textStyle} text-right`}>
        {showPrice(value / 100, currency)}
      </div>
    );
  }

  let v = value;
  if (renderText) {
    v = renderText(value, column, row);
  }

  return <div className={`${textStyle}`}>{v}</div>;
};

const TableRow = ({
  row,
  data,
  onAction = null,
  renderAction = null,
  renderText = null,
  loading = false,
}) => {
  const backColor = data.rowColor ? data.rowColor(row) : "bg-white";
  if (row.dummy) {
    return (
      <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0" />
    );
  }
  return (
    <tr
      className={`${
        loading ? "opacity-10" : ""
      } ${backColor} lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0`}
    >
      {data.columns.map((c) => {
        if (c.hidden) return null;

        if (c.field !== "actions") {
          const value = row[c.field] ?? "";
          let textStyle = "";
          if (c.getTextStyle) {
            textStyle = c.getTextStyle(value);
          }
          return (
            <td
              key={c.field}
              className="w-full lg:w-auto p-3 text-gray-800 text-center border-r border-b block lg:table-cell relative lg:static"
            >
              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">
                {c.text}
              </span>
              {renderValue(value, c, row, textStyle, renderText)}
            </td>
          );
        }
        return (
          <td
            key={c.field}
            className="w-full lg:w-auto p-3 text-gray-800 text-right border-r border-b block lg:table-cell relative lg:static"
            style={{ width: 50 * data.actions.length }}
          >
            {data.actions.map((a) => {
              if (renderAction && renderAction(a.action, row) === false) {
                return null;
              }
              if (a.icon) {
                return (
                  <span className="tooltip" key={a.action}>
                    <i
                      key={a.action}
                      className={`fal ${a.icon} ${
                        a.color ?? ""
                      } text-lg mx-2 cursor-pointer hover:opacity-50`}
                      onClick={() => {
                        if (onAction) {
                          // @ts-ignore
                          onAction(a.action, row);
                        }
                      }}
                    />
                    <span className="tooltiptext">{a.text}</span>
                  </span>
                );
              }
              return (
                <span
                  key={a.action}
                  className={`${
                    a.color ?? ""
                  } hover:underline mx-2 cursor-pointer hover:opacity-50`}
                  onClick={() => {
                    if (onAction) {
                      // @ts-ignore
                      onAction(a.action, row);
                    }
                  }}
                >
                  {a.text}
                </span>
              );
            })}
          </td>
        );
      })}
    </tr>
  );
};

export const Table = forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const {
    settings,
    fetchData,
    onAction = null,
    renderAction = null,
    renderText = null,
    emptyText = t("common.no-data"),
    title = "",
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [data, setData] = useState<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch] = useDebounce(searchText, 500);
  const { theme } = useTheme();
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const HeaderCell = ({
    width = "auto",
    color = "",
    children = null,
    field = null,
  }) => {
    const isSortedField = field?sortField === field:false;
    const arrow = isSortedField ? (sortDirection === 'asc' ? '↑' : '↓') : '';
    const cursorClass = field ? 'cursor-pointer' : '';

    return (
      <th
        className={`p-3 font-bold uppercase text-green-600 border-r border-b hidden lg:table-cell ${cursorClass}`}
        style={{ width, color }}
        onClick={() => {
          if (field === null) {
            return;
          }
          if (sortField === field) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
          } else {
            setSortField(field);
            setSortDirection("asc");
          }
        }}
      >
        <div className="flex">{children} <span className="text-black">{arrow}</span></div>
      </th>
    );
  };

  const loadData = async () => {
    setLoading(true);
    const response = await fetchData(
      pageSize,
      currentPage + 1,
      debouncedSearch,
      false,
      sortField,
      sortDirection
    );
    if (response && response.data) {
      setData(response.data);
      const tp = response.totalPages;
      setTotalPages(tp);
    }
    setLoading(false);
  };

  const exportData = async (output: string) => {
    setExporting(true);
    const response = await fetchData(pageSize, currentPage + 1, output, true);
    setExporting(false);
    return response;
  };

  useImperativeHandle(ref, () => ({
    reload() {
      loadData()
        .then(() => {})
        .catch(() => {});
    },
  }));

  useEffect(() => {
    window.addEventListener("reload-table", loadData);
    return () => {
      window.removeEventListener("reload-table", loadData);
    };
  });

  useEffect(() => {
    loadData()
      .then(() => {})
      .catch(() => {});
  }, [currentPage, pageSize, debouncedSearch, sortField, sortDirection]);

  const handleOnPageChange = (cp) => {
    setCurrentPage(cp.selected);
  };

  const handleExportToExcel = async () => {
    await exportData("excel");
  };

  return (
    <div>
      <div className="w-full flex flex-1 justify-start  mb-2">
        <ul className="text-left pb-4 text-green-600 text-xl mr-2">
          <li
            className="text-gray-600"
            style={{ color: theme?.Content?.styles?.primaryColor }}
          >
            <span>{t(title)}</span>
          </li>
        </ul>
        {settings.search && (
          <div style={{ zIndex: 99 }}>
            <input
              type="text"
              className="border rounded px-2 mr-2"
              style={{ width: "400px" }}
              onChange={(evt) => {
                setSearchText(evt.target.value);
              }}
            />

            <SecondaryButton className="mr-2" onClick={() => {}}>
              <i className="fal fa-search" />
            </SecondaryButton>
          </div>
        )}

        <div style={{ zIndex: 99 }}>
          <SecondaryButton
            onClick={loadData}
            loading={loading}
            disabled={exporting}
          >
            <i className="fal fa-sync" />
          </SecondaryButton>
        </div>
        {settings.export?.toExcel && (
          <div style={{ zIndex: 99 }}>
            <SecondaryButton
              onClick={handleExportToExcel}
              loading={exporting}
              className={"ml-2"}
            >
              <i className="fal fa-file-excel" />
            </SecondaryButton>
          </div>
        )}

        {/*{settings.order && (*/}
        {/*  <div style={{ zIndex: 99, alignSelf: "right" }}>*/}
        {/*    <select*/}
        {/*      className="border rounded px-2 ml-2"*/}
        {/*      style={{ width: "200px" }}*/}
        {/*      placeholder="Ordenar por"*/}
        {/*      onChange={(evt) => {*/}
        {/*        setSearchText(evt.target.value);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      { settings.order.fields.map((o) => (<option value={o.field}>{o.label}</option> )) }*/}
        {/*    </select>*/}

        {/*    /!*<input type="text" className="border rounded px-2 mr-2" style={{ width: "400px" }} onChange={(evt) => {*!/*/}
        {/*    /!*  setSearchText(evt.target.value);*!/*/}
        {/*    /!*}} />*!/*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div className="border rounded shadow-lg" style={{ minHeight: 400 }}>
        <table className={`${styles.gktable} border-0 w-full`}>
          <thead className="bg-transparent">
            <tr>
              {settings.columns.map((c) =>
                c.hidden ? null : (
                  <HeaderCell
                    key={c.field}
                    width={c.width}
                    color={theme?.Content?.styles?.primaryColor}
                    field={c.sort ? c.field : null}
                  >
                    {c.text}
                  </HeaderCell>
                )
              )}
            </tr>
          </thead>
          <tbody
            className={`${loading ? styles.TBodyLoading : ""} relative w-full`}
          >
            {loading && (
              <tr>
                <td colSpan={5}>
                  <div className="absolute w-full h-80">
                    <LocalLoader />
                  </div>
                </td>
              </tr>
            )}
            {data &&
              data.map((r) => {
                return (
                  <TableRow
                    key={r.ID}
                    onAction={onAction}
                    row={r}
                    data={settings}
                    renderAction={renderAction}
                    renderText={renderText}
                    loading={loading}
                  />
                );
              })}
            {data?.length === 0 && (
              <tr>
                <td colSpan={100}>
                  <div
                    className={`${
                      loading ? "opacity-0" : ""
                    } font-semibold w-full text-center py-10 text-2xl flex items-center flex-col`}
                    style={{ minHeight: 400 }}
                  >
                    {emptyText}
                    <img
                      src={theme?.NoDataImage || empty}
                      width="300px"
                      className={"mt-10"}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {data?.length > 0 && totalPages > 1 && (
          <ReactPaginate
            initialPage={0}
            disableInitialCallback
            pageCount={totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={handleOnPageChange}
            previousLabel={t("previous")}
            nextLabel={t("next")}
            containerClassName={styles.paginator}
            pageLinkClassName={styles.page}
            activeLinkClassName={styles.active}
            previousLinkClassName={styles.page}
            nextLinkClassName={styles.page}
            disabledClassName={styles.disabled}
            breakLinkClassName={styles.breakLink}
          />
        )}
      </div>
    </div>
  );
});
