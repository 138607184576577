import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "../Subscription/SubscriptionDetails.module.scss";
import { PaymentIcon } from "../Subscription/PaymentIcon";

export const BankAccountInfo = ({ reseller }: any) => {
  const { t } = useTranslation();

  if (!reseller) {
    return null;
  }

  const bank = reseller.Account?.external_accounts?.data[0]?.BankAccount;
  if (!bank) {
    return null;
  }

  return (
    <div className="w-full rounded bg-white shadow-lg">
      <ul className="">
        <li
          className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
        >
          <div className="flex-1 text-green-600 text-left font-bold">
            {t("reseller.bank-name")}
          </div>
          <div className="flex-1 font-bold text-center">
            {t("reseller.account-number")}
          </div>
          <div className="flex-1 font-bold text-center">
            {t("reseller.titular")}
          </div>
        </li>
        <li
          className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600 items-center`}
        >
          <div className="flex-1 text-gray-600 text-left">{bank.bank_name}</div>
          <div className="flex-1 text-gray-600 text-center flex justify-center items-center">
            <PaymentIcon icon="bank" style={{ margin: 10, width: 30 }} />
            <p className="text-xs ml-2 italic">{`···· ${
              bank.last4 ?? "····"
            }`}</p>
          </div>
          <div className="flex-1 text-gray-600 text-center">
            {bank.account_holder_name}
          </div>
        </li>{" "}
      </ul>
    </div>
  );
};
