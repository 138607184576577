import { AnyAction } from "redux";

export interface UIState {
  loading: number;
  theme: any;
  defaultTheme: any;
}

export const UIInitialState = {
  loading: 0,
  theme: {},
  defaultTheme: {
    Content: {
      slider: {
        slideTitle: 'mt-8 mb-6 lg:mb-10 lg:pr-8 text-6xl font-semibold font-heading text-white'
      },
      styles: {
        primaryColor: '#002F59'
      }
    }
    }

};

const reducer = (state: UIState = UIInitialState, action: AnyAction): any => {
  switch (action.type) {
    case "@UI/SET_LOADING":
      return {
        ...state,
        loading: action.payload ? state.loading + 1 : state.loading - 1,
      };
    case "@UI/SET_THEME":
      return {
        ...state,
        theme: { ...action.payload, Content: JSON.parse(action.payload.Content) },
      };
    default:
      return state;
  }
};

export default reducer;
