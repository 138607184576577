import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DispatchSetLoading } from "../../store/ui/actions";
import { gkb } from "../../services/gkb";

export const ResellerCounters = () => {
  const { t } = useTranslation();
  const [counters, setCounters] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);

    gkb
      .GetResellerCounters()
      .then((data: any) => {
        if (data) {
          setCounters({ ...data });
        }
      })
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  return (
    <div className="w-full flex">
      <div className="w-3/12 rounded bg-white shadow-lg mr-2">
        <h1 className="text-green-600 text-xl mb-2">
          {t("reseller.total-sells")}
        </h1>
        {counters && (
          <p className="text-green-600 text-2xl font-bold mb-2">
            {counters.TotalAmount || 0} €
          </p>
        )}
      </div>
      <div className="w-3/12 rounded bg-white shadow-lg mr-2">
        <h1 className="text-green-600 text-xl mb-2">
          {t("reseller.direct-sells")}
        </h1>
        {counters && (
          <p className="text-green-600 text-2xl font-bold mb-2">
            {counters.DirectSells || 0}
          </p>
        )}
      </div>
      <div className="w-3/12 rounded bg-white shadow-lg ml-1 mr-1">
        <h1 className="text-green-600 text-xl mb-2">
          {t("reseller.direct-amount")}
        </h1>
        {counters && (
          <p className="text-green-600 text-2xl font-bold mb-2">
            {counters.DirectAmount || 0} €
          </p>
        )}
      </div>
      <div className="w-3/12 rounded bg-white shadow-lg ml-1 mr-1">
        <h1 className="text-green-600 text-xl mb-2">
          {t("reseller.network-sells")}
        </h1>
        {counters && (
          <p className="text-green-600 text-2xl font-bold mb-2">
            {counters.NetworkSells || 0}
          </p>
        )}
      </div>
      <div className="w-3/12 rounded bg-white shadow-lg ml-2">
        <h1 className="text-green-600 text-xl mb-2">
          {t("reseller.network-amount")}
        </h1>
        {counters && (
          <p className="text-green-600 text-2xl font-bold mb-2">
            {counters.NetworkAmount || 0} €
          </p>
        )}
      </div>
    </div>
  );
};
