// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AlYZ5kXENMo19u5Foo126{width:400px;margin:40px;padding:10px;border:1px solid}._21af76GB2wvI3FRLu51hsa{width:100%;border-top:5px solid red}", "",{"version":3,"sources":["webpack://./src/components/Sections/SectionHowItWorks.module.scss"],"names":[],"mappings":"AAAA,yBACE,WAAA,CACA,WAAA,CACA,YAAA,CACA,gBAAA,CAGF,yBACE,UAAA,CACA,wBAAA","sourcesContent":[".card {\n  width: 400px;\n  margin: 40px;\n  padding: 10px;\n  border: 1px solid;\n}\n\n.divider {\n  width: 100%;\n  border-top: 5px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "_2AlYZ5kXENMo19u5Foo126",
	"divider": "_21af76GB2wvI3FRLu51hsa"
};
export default ___CSS_LOADER_EXPORT___;
