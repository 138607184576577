import React, { useEffect, useState } from "react";
import { gkb } from "../../services/gkb";
import { useTranslation } from "react-i18next";
import styles from "./NotificationModal/NotificationModal.module.scss";
import { DangerButton } from "../Buttons/DangerButton";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import ReactFlagsSelect, { Co } from "react-flags-select";
import esCountries from "../../models/countries.json";
import { getBrowserCountry } from "../../services/country";
import { CountrySelect } from "../CountrySelect/CountrySelect";

export const SetCountryModal = ({ show, onClose, onChange, userID }) => {
  const { t, i18n } = useTranslation();
  const lng = getBrowserCountry();

  const [loading, setLoading] = useState(false);
  const [Country, setCountry] = useState(lng);
  const locale = i18n.language?.toLowerCase() ?? "";

  const handleOnChange = async () => {
    setLoading(true);
    try {
      await gkb.UpdateApiResource({
        name: "accounts",
        id: userID,
        body: {
          Country: Country,
        },
      });
      onChange(Country);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCountry = (code) => {
    setCountry(code);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={`${styles.backdrop} fixed z-1000 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-3/12">
        <div className="w-full text-center text-2xl p-4 bg-green-600 text-white font-bold">
          {t("login.set-country-modal")}
        </div>
        <div className="w-full text-center p-4 text-gray-600 mt-4">
          {t("login.set-country")}
        </div>
        <form>
          <div className="flex flex-wrap w-full" style={{ height: 200 }}>
            <div className="w-full px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-country"
              >
                {t("profile.country")}
              </label>
              <CountrySelect
                searchable={true}
                selected={Country}
                onSelect={handleChangeCountry}
                showSelectedLabel
                showOptionLabel
                placeholder={t("register.select-country")}
                searchPlaceholder={t("register.search-country")}
              />
              <p
                className={
                  "text-sm text-left flex items-center text-yellow-600"
                }
              >
                {t("login.country-info")}
              </p>
            </div>
          </div>
        </form>
        <div className="w-full text-right p-4 bg-gray-200 text-white font-bold flex justify-end">
          <div className="flex-1 text-right">
            <DangerButton onClick={onClose} className={"mr-2"}>
              <span className="uppercase">{t("editor.cancel")}</span>
            </DangerButton>
            <PrimaryButton
              disabled={Country === ""}
              loading={loading}
              onClick={handleOnChange}
            >
              <span className="uppercase font-bold">{t("editor.acept")}</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
