import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContactForm } from "../components/Sections/ContactForm";
import image from "../assets/img/welcome2.svg";

export default function ContactPage() {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-blueGray-200">
      <ContactForm
        form="contact"
        title={t("contact.question")}
        subtitle={t("contact.subtitle")}
        titleClassName={
          "text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10"
        }
        subtitleClassName={
          "text-xl leading-tight font-semibold font-heading text-gray-600 my-10"
        }
      />
    </div>
  );
}
