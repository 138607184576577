import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/Table/Table";
import { gkb } from "../../../services/gkb";
import { DownloadUrl } from "../../../services/file-download";
import { PeriodDropDown } from "../../../components/PeriodDropDown/PeriodDropDown";

const TransfersPage = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const tableRef = useRef<any>(null);
  useEffect(() => {
    tableRef.current.reload();
  }, [selectedOption]);

  const settings = {
    columns: [
      { text: "#", field: "ID" },
      {
        text: t("commissions.date"),
        field: "CreatedAt",
        type: "date",
      },
      { text: t("commissions.description"), field: "Description" },
      {
        text: t("commissions.total"),
        field: "Amount",
        type: "currency",
        currencyField: "Currency",
      },
      { text: t("commissions.level"), field: "Level" },
      { text: "", field: "actions" },
    ],
    actions: [],
    export: {
      toExcel: true,
    },
  };

  const fetchData = async (pageSize, page, output) => {
    let filter = `created_at=period(${selectedOption.value})`;
    if (output) {
      filter = `${filter}&output=${output}`;
    }
    try {
      const response = await gkb.ListApiResource(
        {
          name: "transfers",
          filter,
        },
        { pageSize, page }
      );

      if (response.Data) {
        return {
          data: response.Data.map((s) => ({
            ...s,
          })),
          totalPages: response.TotalPages,
        };
      } else {
        return response;
      }
    } catch (err) {
      return {
        data: [],
        totalCount: 0,
      };
    }
  };

  const handleTableAction = async (action, row) => {
    if (action === "view") {
      router.push(`/my-account/payments/${row.ID}`);
    } else if (action === "view-invoice") {
      const response = await gkb.DownloadInvoice(row.ID);
      if (response?.data) {
        DownloadUrl(response.data.InvoiceUrl, response.data.FileName);
      }
    }
  };

  const handleRenderText = (value, column) => {
    if (column.field === "status") {
      return t(value);
    }
    if (column.field === "billing_reason") {
      return t(value);
    }
    return value;
  };

  const handleRenderAction = (action, row) => {
    if (action === "view-invoice") {
      return row.PaymentStatus === "SUCCEEDED";
    }

    return true;
  };

  const handleChangePeriod = (value) => {
    setSelectedOption(value);
  };

  return (
    <section className="px-4 text-center pb-40 w-full">
      <ul className="text-left pb-4 flex text-green-600 text-lg">
        <li className="underline hover:opacity-50">
          <Link to="/my-account/reseller">{t("reseller.title")} </Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li>
          <span className="text-gray-600">{t("reseller.my-commissions")} </span>
        </li>
      </ul>{" "}
      <div className="mb-2">
        <PeriodDropDown onChange={handleChangePeriod} />
      </div>
      <Table
        ref={tableRef}
        settings={settings}
        fetchData={fetchData}
        onAction={handleTableAction}
        renderText={handleRenderText}
        renderAction={handleRenderAction}
      />
    </section>
  );
};

export default TransfersPage;
