import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { Verification } from "../../../models/reseller";
import { SecondaryButton } from "../../Buttons/SecondaryButton";

export const OnBoardingStep1 = ({ onSubmit, nextStep, previousStep }: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Verification>({
    BusinessType: "individual",
    TOSAccepted: true,
  });

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(0, state);
    nextStep();
    setLoading(false);
  };

  return (
    <div>
      <div className="w-full px-4 lg:px-10 py-4 flex justify-center">
        <form className="w-full flex-col flex items-center">
          <h1 className="text-xl text-green-600 mb-4">{t("onboard1.title")}</h1>
          <div className="text-left">
            <div className="w-full lg:w-full px-4">
              <div className="m-4">
                <input
                  className="cursor-pointer text-md"
                  type="radio"
                  id="individual"
                  name="business_type"
                  value="individual"
                  checked={state.BusinessType === "individual"}
                  onChange={() => {
                    setState({ ...state, BusinessType: "individual" });
                  }}
                />
                <label
                  className="ml-2 cursor-pointer text-lg"
                  htmlFor="individual"
                >
                  {t("onboard1.independent")}
                </label>{" "}
              </div>
              <div className="m-4">
                <input
                  disabled={true}
                  className="cursor-pointer text-md"
                  type="radio"
                  id="business"
                  name="business_type"
                  value="business"
                  checked={state.BusinessType === "company"}
                  onChange={() => {
                    setState({ ...state, BusinessType: "company" });
                  }}
                />
                <label
                  className="ml-2 cursor-pointer text-lg"
                  htmlFor="business"
                >
                  {t("onboard1.company")}
                </label>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end mt-12">
            {!loading && (
              <SecondaryButton onClick={previousStep} className="mr-2">
                {t("back")}
              </SecondaryButton>
            )}

            <PrimaryButton
              loading={loading}
              onClick={handleSubmit}
              disabled={!state.TOSAccepted}
            >
              {t("next")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};
