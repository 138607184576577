import React, { useRef, useState } from "react";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { gkb } from "../../services/gkb";
import { useDispatch } from "react-redux";
import { notify } from "reapop";
import image from "../../assets/img/welcome2.svg";
import ReactGA from "react-ga";

export const ContactForm = ({
  form,
  title,
  subtitle,
  titleClassName,
  subtitleClassName,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [sending, setSending] = useState(false);
  const formRef = useRef<any>(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setSending(true);
      const response = await gkb.CreateApiResource({
        name: "contactMessages",
        body: {
          name,
          email,
          message,
          form,
        },
      });

      if (response?.ID) {
        dispatch(notify(t("contact.message-sended-successfully"), "success"));
        setMessageSent(true);
        ReactGA.event({
          category: "form",
          action: `Form ${form} submitted from ${email}`,
        });
      } else {
        dispatch(notify(t("contact.message-sended-error"), "error"));
      }
    } catch (e) {
      dispatch(notify(t("contact.message-sended-error"), "error"));
    } finally {
      setSending(false);
    }
  };

  if (messageSent) {
    return (
      <section className="pt-12 pb-40 px-4 text-center">
        <h1 className={titleClassName}>{t("contact.message-sent")}</h1>
        <h2 className={subtitleClassName}>{t("contact.message-sent2")}</h2>
        <div className="w-full text-center flex justify-center">
          <img src={image} width={400} />
        </div>
      </section>
    );
  }

  return (
    <section className="pt-12 md:pb-40 px-4 text-center">
      <h1 className={titleClassName}>{title}</h1>
      <h2 className={subtitleClassName}>{subtitle}</h2>
      <div className="w-full max-w-2xl mx-auto mb-8">
        <form ref={formRef} onSubmit={handleSendMessage} action={"#"}>
          <div className="flex flex-col md:flex-row mb-4 -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <input
                className="appearance-none block w-full py-3 px-4 text-md leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                type="text"
                placeholder={t("contact.name")}
                onChange={(e: any) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <input
                className="appearance-none block w-full py-3 px-4 text-md leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                type="email"
                placeholder={t("contact.email")}
                onChange={(e: any) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <textarea
              className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
              placeholder={t("contact.write-message")}
              rows={5}
              onChange={(e: any) => {
                setMessage(e.currentTarget.value);
              }}
            />
          </div>
          <div>
            <PrimaryButton onClick={handleSendMessage} loading={sending}>
              {t("contact.send")}
            </PrimaryButton>
          </div>
        </form>
      </div>
      <div className="text-center">
        <p className="mb-2 text-gray-400 ">{t("footer.send-message")}</p>
        <span className="text-green-600 hover:underline">
          {t("support.support-email")}
        </span>
      </div>
    </section>
  );
};
