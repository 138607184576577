import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { removeSession, useSession } from "react-session-persist/lib";
import styles from "./MyAccountNav.module.scss";
import BgLoader from "../bgLoader/BgLoader";
import { Avatar } from "../Avatar/Avatar";
import { gkb } from "../../services/gkb";
import { useTheme } from "../../store/ui/selector";

export default function MyAccountNav() {
  const { pathname } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const { user } = useSession();
  const profile: any = user;
  const { theme } = useTheme();

  const { t } = useTranslation();
  const url = pathname;

  const handleSignout = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await gkb.Logout();
    await removeSession();
  };

  if (loading || !profile) {
    return <BgLoader />;
  }


  return (
    <nav
      className={`${styles.navWrapper} rounded shadow-lg border`}
      style={{ backgroundColor: "white" }}
    >
      <div className="border-b p-4 py-8 flex">
        <div>
          {profile.ImageUrl && <Avatar image={profile.ImageUrl} />}
          {!profile.ImageUrl && (
            <div
              style={{ width: 50, height: 50 }}
              className="rounded flex justify-center items-center bg-blueGray-600 font-bold text-2xl text-white"
            >
              {profile.FirstName.charAt(0)}
            </div>
          )}
        </div>
        <div className="mx-4 text-left -mt-1">
          <div><strong>{t("navbar.hello")}</strong></div>
          <div className="font-bold text-green-600 text-xl"
               style={{ color: theme?.Content?.styles?.primaryColor }}
          >
            {profile.FirstName}
          </div>
        </div>
      </div>
      <div className="">
        <ul className="text-lg text-left">
          <Link to="/my-account">
            <li
              className={`${
                url === "/my-account" ? styles.selected : ""
              } p-4 cursor-pointer py-2 mt-4 hover:bg-green-100`}
              style={{backgroundColor: url === "/my-account" ? theme?.Content?.styles?.primaryColor : ""}}
            >
              <span>
                <i className="fal fa-user mr-5 w-5" />
                {t("navbar.profile")}
              </span>
            </li>
          </Link>
          <Link to="/my-account/cards">
            <li
              className={`${
                url.startsWith("/my-account/cards") ? styles.selected : ""
              } p-4 cursor-pointer py-2 hover:bg-green-100`}
              style={{backgroundColor: url === "/my-account/cards" ? theme?.Content?.styles?.primaryColor : ""}}
            >
              <span>
                <i className="fal fa-id-card mr-5 w-5" />
                {t("navbar.cards")}
              </span>
            </li>
          </Link>
          <Link to="/my-account/subscriptions">
            <li
              className={`${
                url.startsWith("/my-account/subscriptions")
                  ? styles.selected
                  : ""
              } p-4 cursor-pointer py-2 hover:bg-green-100`}
              style={{backgroundColor: url === "/my-account/subscriptions" ? theme?.Content?.styles?.primaryColor : ""}}
            >
              <span>
                <i className="fal fa-file-contract mr-5 w-5" />
                {t("navbar.subscriptions")}
              </span>
            </li>
          </Link>
          <Link to="/my-account/payments">
            <li
              className={`${
                url.startsWith("/my-account/payments") ? styles.selected : ""
              } p-4 cursor-pointer py-2 hover:bg-green-100`}
              style={{backgroundColor: url === "/my-account/payments" ? theme?.Content?.styles?.primaryColor : ""}}
            >
              <span>
                <i className="fal fa-credit-card mr-5 w-5" />
                {t("navbar.payments")}
              </span>
            </li>
          </Link>
          <li
            className={`${
              url === "" ? styles.selected : ""
            } p-4 cursor-pointer border-t mt-4 bg-black text-white rounded-b hover:opacity-60`}
            onClick={handleSignout}
          >
            <i className="fal fa-sign-out-alt mr-5 transform rotate-180 w-5" />
            {t("navbar.logout")}
          </li>
        </ul>
      </div>
    </nav>
  );
}
