import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import zxcvbn from "zxcvbn";

const strengthColors = ["red", "yellow", "yellow", "#a1c434", "#a1c434"];

const _PasswordInput = ({
  label,
  disabled,
  value,
  onChange,
  showValidation,
}) => {
  const { t } = useTranslation();
  const [Password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [strength, setStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false); // Nuevo estado para mostrar/ocultar contraseña

  const handleOnChange = useCallback((e: any) => {
    if (showValidation) {
      const score = zxcvbn(e.currentTarget.value).score;
      setStrength(score);
      if (score < 3) {
        setPasswordError(true);
        onChange({ score, password: e.currentTarget.value });
      } else {
        setPasswordError(false);
        onChange({ score, password: e.currentTarget.value });
      }
    } else {
      setPasswordError(false);
      onChange({ score: 6, password: e.currentTarget.value });
    }
    setPassword(e.currentTarget.value);
  }, []);

  const toggleShowPassword = () => {
    // Función para cambiar el estado de showPassword
    setShowPassword(!showPassword);
  };

  return (
    <>
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
        htmlFor="grid-password"
      >
        {label}
      </label>
      <div className="relative">
        {" "}
        <input
          id="grid-password"
          type={showPassword ? "text" : "password"}
          disabled={disabled}
          className={`${
            passwordError ? "border-1 border-red-600" : "border-0"
          }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
          value={Password}
          placeholder="****************"
          onChange={handleOnChange}
        />
        <button
          className="absolute inset-y-0 right-0 pr-3 flex items-center" // Posiciona el botón absolutamente dentro del contenedor
          style={{ border: 'none', boxShadow: 'none', outline: 'none' }} // Añade 'border: none' y 'box-shadow: none' para eliminar el borde y la sombra
          onClick={toggleShowPassword} type="button">
          {" "}
          {/* Botón para cambiar el estado de showPassword */}
          {showPassword ? (
            <i className="fas fa-eye-slash text-black-500 hover:text-black-400 text" />
          ) : (
            <i className="fas fa-eye text-black-500 hover:text-red-black text" />
          )}
        </button>
      </div>
      {showValidation && (
        <div className="flex mt-1">
          <div
            className={"flex-1  mr-2 rounded"}
            style={{
              height: 4,
              backgroundColor:
                Password.length === 0 ? "#e4e4e4" : strengthColors[strength],
            }}
          />
          <div
            className={"flex-1 mx-1 rounded"}
            style={{
              height: 4,
              backgroundColor:
                Password.length === 0 || strength < 1
                  ? "#e4e4e4"
                  : strengthColors[strength],
            }}
          />
          <div
            className={"flex-1 mx-1 rounded"}
            style={{
              height: 4,
              backgroundColor:
                Password.length === 0 || strength < 2
                  ? "#e4e4e4"
                  : strengthColors[strength],
            }}
          />
          <div
            className={"flex-1 ml-2 rounded"}
            style={{
              height: 4,
              backgroundColor:
                Password.length === 0 || strength < 3
                  ? "#e4e4e4"
                  : strengthColors[strength],
            }}
          />
          <div
            className={"flex-1 ml-2 rounded"}
            style={{
              height: 4,
              backgroundColor:
                Password.length === 0 || strength < 4
                  ? "#e4e4e4"
                  : strengthColors[strength],
            }}
          />
        </div>
      )}
    </>
  );
};

export const PasswordInput = React.memo(_PasswordInput);
