import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import change from "../assets/img/password.svg";
import { PrimaryButton } from "../components/Buttons/PrimaryButton";
import { gkb } from "../services/gkb";
import { notify } from "reapop";

export default function ChangePasswordPage() {
  const { t } = useTranslation();
  const [Password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [saving, setSaving] = useState(false);
  const { code } = useParams<{ code: string }>();
  const dispatch = useDispatch();
  const router = useHistory();

  const formValid = () => {
    if (Password === "") {
      return false;
    }

    return Password === confirm;
  };

  const handleChangePassword = async () => {
    try {
      setSaving(true);
      const response = await gkb.ChangePassword(code, Password);
      if (response.status === 200) {
        dispatch(notify(t("changepassword.success"), "success"));
        router.push("/login");
      } else {
        dispatch(notify(t("changepassword.error"), "error"));
      }
    } catch (err) {
      dispatch(notify(t("changepassword.error"), "error"));
    } finally {
      setSaving(false);
    }
  };

  return (
    <section className="pt-12 pb-40 px-4 text-center bg-blueGray-100">
      <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10">
        {t("changepassword.title")}
      </h2>
      <p className="text-gray-400 text-xl mt-10">{t("changepassword.info")}</p>
      <div className="w-full lg:w-6/12 py-4 px-10 mx-auto">
        <div className="flex-auto px-4 lg:px-10 py-10">
          <form>
            <div className="flex flex-wrap w-full mt-10 items-center">
              <div className="w-6/12 flex justify-center">
                <img
                  alt="change password"
                  src={change}
                  height={400}
                  width={400}
                />
              </div>
              <div className="w-6/12 bg-gray-100 shadow-xl py-8 rounded">
                <div className="w-full px-8">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-password"
                  >
                    {t("login.password")}
                  </label>
                  <input
                    id="grid-password"
                    type="password"
                    className={`${
                      passwordError !== ""
                        ? "border-1 border-red-600"
                        : "border-0"
                    }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                    value={Password}
                    placeholder="****************************"
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                      setPasswordError("");
                    }}
                  />
                </div>
                <div className="w-full px-8 mt-8">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-confirm"
                  >
                    {t("login.confirm")}
                  </label>
                  <input
                    id="grid-confirm"
                    type="password"
                    className={`${
                      passwordError !== ""
                        ? "border-1 border-red-600"
                        : "border-0"
                    }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                    value={confirm}
                    placeholder="****************************"
                    onChange={(e) => {
                      setConfirm(e.currentTarget.value);
                      setPasswordError("");
                    }}
                  />
                </div>
                {passwordError && (
                  <div className="w-6/12 lg:w-6/12 px-8 text-red-600 italic text-sm text-left">
                    {passwordError}
                  </div>
                )}
                <div className="flex flex-wrap w-full mt-10 justify-around">
                  <PrimaryButton
                    onClick={handleChangePassword}
                    loading={saving}
                    disabled={!formValid()}
                  >
                    {t("changepassword.change")}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
