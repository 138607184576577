/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";

// components
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSession } from "react-session-persist";
import LoginComponent from "../components/login/login";

export default function LoginPage({ selectedTab }: any) {
  const { t } = useTranslation();
  const { authenticated, session, saveUser, saveSession, user } = useSession();
  const [currentTab, setCurrentTab] = useState(selectedTab);
  const router = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") {
      setCurrentTab(0);
    } else if (location.pathname === "/register") {
      setCurrentTab(1);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (authenticated) {
      if (location.state?.redirectTo) {
        router.push(location.state.redirectTo);
        return;
      }
      router.push("/");
    }
  }, [session]);

  const setAuthInfo = async (authInfo: any) => {
    await saveSession({ userId: authInfo.userId });
    await saveUser({ ...authInfo.profile });
  };

  return (
    <>
      <section className="py-8 px-4 text-center bg-gray-100">
        <LoginComponent setAuthInfo={setAuthInfo} selectedTab={currentTab} />
      </section>
    </>
  );
}
