import React from "react";
import { useTheme } from "../../store/ui/selector";
import styles from "./Buttons.module.scss";

type ButtonsProperties = {
  children: any;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  id?: string;
  className?: string;
  type?: "button" | "submit";
};

export const PrimaryButton = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  full = false,
  id = "",
  className = "",
  type = "button",
}: ButtonsProperties) => {
  const { theme, defaultTheme } = useTheme();
  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  if (loading) {
    return (
      <button
        id={id}
        style={{ backgroundColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
        className={`${styles.disabled} opacity-50 bg-green-700 text-white text font-bold uppercase px-4 py-2 rounded shadow  outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type={type}
      >
        <i className="fas fa-spinner animate-spin" />
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        id={id}
        style={{ backgroundColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
        className={`${styles.disabled} ${
          full ? "w-full" : ""
        } bg-gray-400 text font-bold uppercase px-4 py-2 rounded shadow outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type={type}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      id={id}
      style={{ backgroundColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
      className={`bg-green-700 ${
        full ? "w-full" : ""
      }   text-white active:bg-green-600 hover:bg-green-600 text font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
      type={type}
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};
