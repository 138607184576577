import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { gkb } from "../../services/gkb";
import { DispatchSetLoading } from "../../store/ui/actions";
import { useDispatch } from "react-redux";

export const Section = ({ id }) => {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [section, setSection] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .GetSectionByName(id)
      .then((res) => {
        setSection(res);
      })
      .catch()
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  const cl = language.toLowerCase();

  if (!section) {
    return null;
  }

  return (
    <section
      className="pt-24 pb-40 relative"
      style={{ backgroundColor: section?.BackgroundColor || "white" }}
    >
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="lg:w-full px-8 text-center">
              <div
                className={"flex flex-col h-full justify-center"}
                dangerouslySetInnerHTML={{
                  __html: section?.LeftContent[cl] ?? "",
                }}
              />
              {section?.LeftButton?.Text && (
                <div>
                  <PrimaryButton
                    onClick={() => {
                      router.push(section?.LeftButton?.Url ?? "");
                    }}
                  >
                    {section?.LeftButton?.Text && section?.LeftButton?.Text[cl]}
                  </PrimaryButton>
                </div>
              )}
            </div>
            <div className="lg:w-full px-8 text-center">
              <div
                className={"flex flex-col h-full justify-center"}
                dangerouslySetInnerHTML={{
                  __html: section?.RightContent[cl] ?? "",
                }}
              />
              {section?.RightButton?.Text[cl] && (
                <div>
                  <PrimaryButton
                    onClick={() => {
                      router.push(section?.RightButton?.Url ?? "");
                    }}
                  >
                    {section?.RightButton?.Text &&
                      section?.RightButton?.Text[cl]}
                  </PrimaryButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
