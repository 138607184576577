import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notify } from "reapop";
import { ResellerCounters } from "../../../components/Reseller/ResellerCounters";
import LocalLoader from "../../../components/bgLoader/LocalLoader";
import { gkb } from "../../../services/gkb";
import { ResellerInfo } from "../../../components/Reseller/ResellerInfo";

import { BankAccountInfo } from "../../../components/Reseller/BankAccountInfo";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";

import image from "../../../assets/img/no-reseller.svg";

const ResellerPage = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [info, setInfo] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    gkb
      .GetResellerAccount()
      .then((acct: any) => {
        if (acct) {
          setInfo(acct);
        }
        if (acct?.Reseller) {
          if (acct?.Reseller.VerificationStatus === "pending-onboard") {
            router.replace("/my-account/reseller/onboarding");
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const domain = window.location.origin;

  if (loading) {
    return (
      <div className="absolute w-8/12 h-80">
        <LocalLoader />
      </div>
    );
  }

  if (info && !info.Reseller) {
    return (
      <section className="px-4 text-center w-full" style={{ minHeight: 650 }}>
        <div className="flex items-center flex-col">
          <div>
            <h1 className="text-2xl font-bold text-green-600 mb-8">
              {t("resseller.no-reseller")}
            </h1>
          </div>
          <div>
            <h1 className="text-xl text-gray-600 mb-8">
              {t("resseller.waiting")}
            </h1>
          </div>

          <div>
            <img
              alt="no-reseller"
              src={image}
              width={400}
              style={{ transform: "rotateY(180deg)" }}
            />
          </div>
          <div className="mt-8">
            <SecondaryButton
              onClick={() => {
                router.push({
                  pathname: "/resellers-info",
                });
              }}
            >
              {t("resellers.button")}
            </SecondaryButton>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="px-4 text-center w-full" style={{ minHeight: 650 }}>
      <ul className="text-left pb-4 text-green-600 text-xl">
        <li className="text-gray-600">
          <span>{t("reseller.title")}</span>
        </li>
      </ul>
      <div>
        <ResellerCounters />
      </div>

      <div className="mt-4">
        <ResellerInfo reseller={info} />
      </div>
      {info && (
        <div className="w-full rounded bg-white shadow-lg flex mt-4 p-4">
          <div className="font-bold text-green-600">
            {t("reseller.reseller-link")}:
          </div>
          <div className="ml-4">
            <span>{`${domain}?reseller=${info?.Reseller.ID || "0"}`}</span>
            <CopyToClipboard
              text={`${domain}?reseller=${info?.Reseller.ID || "0"}`}
              onCopy={() => {
                dispatch(notify(t("reseller.link-copied-success"), "success"));
              }}
            >
              <span>
                <i className="fal fa-copy cursor-pointer hover:opacity-50 ml-4" />
              </span>
            </CopyToClipboard>
          </div>
        </div>
      )}
      <div className="mt-4">
        <BankAccountInfo reseller={info} />
      </div>
      <div className="mt-4 flex justify-end">
        <div>
          <PrimaryButton
            onClick={() => {
              router.push("/my-account/reseller/network");
            }}
          >
            {t("reseller.show-my-net")}
          </PrimaryButton>
        </div>
        <div className="ml-4">
          <PrimaryButton
            onClick={() => {
              router.push("/my-account/reseller/transactions");
            }}
          >
            {t("reseller.show-commissions")}
          </PrimaryButton>
        </div>
      </div>
    </section>
  );
};

export default ResellerPage;
