/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResellerNetInfo } from "../../../components/Reseller/ResellerNetInfo";

export default function ResellersNetworkPage() {
  const { t } = useTranslation();

  return (
    <section className="px-4 text-center pb-40 w-full">
      <ul className="text-left pb-4 flex text-green-600 text-lg">
        <li className="underline hover:opacity-50">
          <Link to="/my-account/reseller">{t("reseller.title")} </Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li>
          <span className="text-gray-600">{t("reseller.my-network")} </span>
        </li>
      </ul>
      <ResellerNetInfo />
    </section>
  );
}
