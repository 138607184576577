import React from "react";
import { useTranslation } from "react-i18next";
import img404 from "../assets/img/404.svg";
import { ContactForm } from "../components/Sections/ContactForm";

export default function Page404() {
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <section className="pt-12 px-4 text-center">
        <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10">
          {t("404.title")}
        </h2>
        <h2 className="text-xl mb-2 leading-tight font-semibold font-heading text-gray-600 my-10">
          {t("404.subtitle")}
        </h2>
        <div className="w-full flex justify-center">
          <img alt="page not found" src={img404} height={400} width={400} />
        </div>
      </section>
      <section className="px-4 text-center pt-5 pb-40 bg-blueGray-800">
        <ContactForm
          form="errorpage"
          title={t("errorpage.question1")}
          subtitle={t("errorpage.send-message")}
          titleClassName={
            "text-4xl my-10 text-center font-semibold font-heading text-white"
          }
          subtitleClassName="text-2xl text-gray-400 mt-10 mb-20 leading-tight font-semibold font-heading"
        />
      </section>
    </>
  );
}
