import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/pages/products.module.scss";
import { SectionPricing } from "../components/Sections/SectionPricing";
import { gkb } from "../services/gkb";
import { StoreState } from "../store";
import { DispatchSetLoading } from "../store/ui/actions";
import { getCurrency } from "../services/currency";

export default function ProductsPage() {
  const { t, i18n } = useTranslation();
  const prs = useSelector((state: StoreState) => state.products.products);
  const crs = useSelector((state: StoreState) => state.products.countries);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState("eur");
  const [countries, setCountries] = useState(crs);
  const [products, setProducts] = useState(
    prs.filter((p: any) => p.Category === "card")
  );

  useEffect(() => {
    console.log("Countries: ", countries)
    const dc = getCurrency(countries);
    setCurrency(dc);
  }, [countries]);

  useEffect(() => {
    if (prs.length === 0) {
      DispatchSetLoading(dispatch, true);
      gkb
        .ListApiResource(
          {
            name: "products",
            filter: "",
          },
          { sort: "show_order.asc" }
        )
        .then((resp: any) => {
          if (resp?.Data) {
            setProducts(resp.Data.filter((p: any) => p.Category === "card"));
          }
        })
        .catch(() => {})
        .finally(() => {
          DispatchSetLoading(dispatch, false);
        });
    }
  }, []);

  useEffect(() => {
    if (crs.length === 0) {
      DispatchSetLoading(dispatch, true);
      gkb
        .ListApiResource(
          {
            name: "countries",
            filter: "",
          },
          { pageSize: 500 }
        )
        .then((resp: any) => {
          if (resp?.Data) {
            setCountries(resp.Data);
          }
        })
        .catch(() => {})
        .finally(() => {
          DispatchSetLoading(dispatch, false);
        });
    }
  }, []);

  const SectionHeader = () => (
    <section className={`${styles.section1} py-40 px-4 text-center`}>
      <div className="w-full max-w-6xl mx-auto">
        <h2 className="text-5xl text-white mt-10 mb-20 leading-tight font-semibold font-heading">
          {t("products.title")}
        </h2>
        <p className="text-gray-900 text-2xl">{t("products.subtitle")}</p>
      </div>
    </section>
  );

  const SectionFooter = () => (
    <section className="px-4 text-center pt-5 pb-40 bg-blueGray-800">
      <div className="w-full max-w-6xl mx-auto">
        <h2 className="text-4xl text-gray-50 mt-10 mb-20 leading-tight font-semibold font-heading">
          {t("products.questions")}
        </h2>
        <Link to="/faqs">
          <p className="text-green-400 text-xl hover:opacity-60">
            {t("products.goto-faq")}
          </p>
        </Link>
      </div>
    </section>
  );

  return (
    <>
      <SectionHeader />
      {products && <SectionPricing products={products} currency={currency} banner={t('store.products.banner')}/>}
      <SectionFooter />
    </>
  );
}
