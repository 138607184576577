import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSession } from "react-session-persist";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import { GetLocalizedText } from "../../services/localizedStrings";
import logo from "../../assets/img/stripe-ssl2.png";
import { getCurrencySymbol } from "../../services/currency";
import { useTheme } from "../../store/ui/selector";
import  ProductsBanner  from "../ProductsBanner/productsBanner";

export const SectionPricing = ({ products, currency, banner = "" }: any) => {
  const [showAnual, setShowAnual] = useState(true);
  const { t, i18n } = useTranslation();
  const { authenticated } = useSession() as any;
  const [symbol, setSymbol] = useState({ s: "$", p: "left" });
  const router = useHistory();
  const { theme } = useTheme();

  const prs = products.map((p: any) => {
    const newP = { ...p };
    newP.PricesByInterval = [];
    const prices = p.Prices.filter((price: any) => price.Currency === currency);

    for (let i = 0; i < prices.length; i++) {
      newP.PricesByInterval[prices[i].Interval] = prices[i];
    }

    return newP;
  });

  const PriceToggle = () => (
    <div className="justify-center items-center flex mb-4">
      <span className="mx-2">{t("products.pricing.price-month")}</span>
      <Toggle
        defaultChecked={showAnual}
        icons={false}
        onChange={() => setShowAnual(!showAnual)}
      />
      <span className="mx-2 text-green-800 font-bold">
        {t("products.pricing.price-year")}
      </span>
    </div>
  );

  useEffect(() => {
    const symbol = getCurrencySymbol(currency);
    setSymbol(symbol);
  }, [currency]);

  const showPrice = (price: number) => {
    if (symbol.p === "right") {
      return `${price / 100} ${symbol.s}`;
    } else {
      return `${symbol.s} ${price / 100}`;
    }
  };

  return (
    <section className="py-8 pb-10">
      <div className="w-full flex justify-center">
        <ProductsBanner banner={banner} />
      </div>

      <div className="flex flex-wrap max-w-7xl mx-auto">
        {prs.map((p) => {
          const description = GetLocalizedText(p.Description, i18n.language);
          const features = GetLocalizedText(p.Features, i18n.language);

          const prices = p.PricesByInterval;

          const priceYear = showPrice(prices.year?.Amount);
          const priceMonth = prices.month
            ? showPrice(prices.month?.Amount)
            : priceYear;
          const perYear = t("products.pricing.per-year");
          const perMonth = prices.month
            ? t("products.pricing.per-month")
            : perYear;

          return (
            <div
              key={p.ID}
              className="w-full md:w-1/3 py-10 px-6 flex flex-col"
            >
              {p.Prices?.filter((p) => p.Currency === currency).length > 1 && (
                <PriceToggle />
              )}
              {p.Prices?.filter((p) => p.Currency === currency).length ===
                1 && <div style={{ height: 42 }} />}
              <div className="border rounded h-full flex flex-col justify-start py-10">
                <h3 className="text-5xl text-green-800  mb-10 font-heading font-semibold text-center underline flex-0"
                    style={{ ...theme?.Content?.styles?.priceTitle }}>
                  {t(p.Name)}
                </h3>
                <div className="flex justify-items-center items-center">
                  <img
                    alt="Product"
                    src={p.ImageUrl}
                    style={{ height: 200, width: "100%" }}
                  />
                </div>
                <p className="px-2 mb-8 text-gray-600 text-center text-lg leading-relaxed mt-10 border-b pb-4">
                  {t(description)}
                </p>
                <div className="text-5xl text-green-800  mb-10 font-heading font-semibold text-center border-b pb-4"
                     style={{ ...theme?.Content?.styles?.priceTitle }}>
                  {showAnual ? priceYear : priceMonth}{" "}
                  <span className="text-gray-400 text-lg align-text-top">
                    {showAnual ? perYear : perMonth}
                  </span>
                  {p.FreeTrialDays > 0 && (
                    <p className="text-lg" style={{ color: "#FF6B0D" }}>
                      {`${t("editor.free-trial-1")} ${p.FreeTrialDays} ${t(
                        "editor.free-trial-2"
                      )}`}
                    </p>
                  )}
                </div>
                <div
                  className="mb-10 px-4 list-inside list-disc text-gray-400 flex-1 text-left"
                  dangerouslySetInnerHTML={{ __html: features }}
                />
                <SecondaryButton
                  className={"w-max self-center"}
                  onClick={() => {
                    if (!authenticated) {
                      router.push("/login", {
                        redirectTo: `/cart?add-to-cart=${p.Sku}-${
                          showAnual ? "year" : "month"
                        }`,
                      });
                    } else {
                      (window as any).gtag("event", "view_item", {
                        currency: "EUR",
                        value: showAnual
                          ? prices.year.Amount / 100
                          : prices.month.Amount / 100,
                        items: [
                          {
                            id: p.Sku,
                            name: p.Name,
                            category: "Subscription",
                            quantity: 1,
                            price: showAnual
                              ? prices.year.Amount / 100
                              : prices.month.Amount / 100,
                          },
                        ],
                      });
                      (window as any).gtag("event", "add_to_cart", {
                        currency: "EUR",
                        value: showAnual
                          ? prices.year.Amount / 100
                          : prices.month.Amount / 100,
                        items: [
                          {
                            id: p.Sku,
                            name: p.Name,
                            category: "Subscription",
                            quantity: 1,
                            price: showAnual
                              ? prices.year.Amount / 100
                              : prices.month.Amount / 100,
                          },
                        ],
                      });

                      router.push({
                        pathname: "/cart",
                        search: `?add-to-cart=${p.Sku}-${
                          showAnual ? "year" : "month"
                        }`,
                      });
                    }
                  }}
                >
                  {t(
                    p.FreeTrialDays > 0
                      ? "products.try-now"
                      : "products.pricing.buy-now"
                  )}
                </SecondaryButton>
              </div>
            </div>
          );
        })}
        <div className="w-full flex hidden md:block px-6 -mt-8">
          <div className="">
            <img src={logo} width={300} />
          </div>
          <div className="text-right flex-1">
            <p className="text-xs italic">{t("products.pricing.note1")}</p>
            <p className="text-xs italic">{t("products.pricing.note2")}</p>
            <p className="text-xs italic">{t("products.pricing.note3")}</p>
          </div>
        </div>
        <div className="w-full flex md:hidden px-6 -mt-8 flex-col">
          <div className="text-left flex-1">
            <p className="text-xs italic">{t("products.pricing.note1")}</p>
            <p className="text-xs italic">{t("products.pricing.note2")}</p>
            <p className="text-xs italic">{t("products.pricing.note3")}</p>
          </div>
          <div className="self-center mt-10">
            <img src={logo} width={300} />
          </div>
        </div>
      </div>
    </section>
  );
};
