import React from 'react';
import styles from './NotificationModal.module.scss';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import { SecondaryButton } from '../../Buttons/SecondaryButton';

export const NotificationModal = ({ show, title, content, onClose, onCancel = null }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={`${styles.backdrop} fixed z-1000 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-4/12">
        <div className="w-full text-center text-2xl p-4 bg-green-600 text-white font-bold">
          {title}
        </div>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full text-lg text-center my-4 font-bold">
              {content}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
        { onCancel? <div className="w-full text-left p-4 bg-gray-200 text-white font-bold">
          <SecondaryButton onClick={onCancel}>Cancelar</SecondaryButton>
        </div>:null
        }
        <div className="w-full text-right p-4 bg-gray-200 text-white font-bold">
          <PrimaryButton onClick={onClose}>Aceptar</PrimaryButton>
        </div>
        </div>
      </div>
    </div>
  );
};
