import React, { useState } from "react";
import { notify } from "reapop";
import { useDispatch } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ValidateEmail } from "../../services/validators";
import { gkb } from "../../services/gkb";

import styles from "./Profile.module.scss";
import { Profile } from "../../models/user";
import ReactGA from "react-ga";
import { useSession } from "react-session-persist";
import { CountrySelect } from "../CountrySelect/CountrySelect";

interface BillingDataProps {
  profile: Profile;
}

export const BillingDataComponent = ({ profile }: BillingDataProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(profile.BillingDataName ?? "");
  const [cif, setCIF] = useState(profile.BillingDataVat ?? "");
  const [phone, setPhone] = useState(profile.BillingDataPhone ?? "");
  const [address, setAddress] = useState(profile.BillingDataAddress ?? "");
  const [email, setEmail] = useState(profile.BillingDataEmail ?? "");
  const [city, setCity] = useState(profile.BillingDataCity ?? "");
  const [country, setCountry] = useState(
    profile.BillingDataCountry?.toUpperCase() ?? ""
  );
  const [state, setState] = useState(profile.BillingDataState ?? "");
  const [zipCode, setZipCode] = useState(profile.BillingDataZipCode ?? "");

  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const { saveUser, user } = useSession();

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (email !== "") {
      if (!ValidateEmail(email)) {
        setEmailError(t("profile.error-email-invalid"));
        return;
      }
    }

    setSaving(true);

    let phoneAux = phone;
    if (!phone.startsWith("+")) {
      phoneAux = `+${phone}`;
    }

    const newProfile = { ...profile,
      BillingDataAddress: address,
      BillingDataVat: cif,
      BillingDataCity: city,
      BillingDataCountry: country,
      BillingDataEmail: email,
      BillingDataName: name,
      BillingDataPhone: phone,
      BillingDataState: state,
      BillingDataZipCode: zipCode    };
    delete (newProfile as any).VerifiedAt;

    const usr = await gkb.UpdateApiResource({
      name: "accounts",
      id: profile.ID,
      body: newProfile,
    });

    // const response = await UpdateBillingData(profile.ID, billingData);
    if (usr) {
      dispatch(notify(t("profile.billing-saved-success"), "success"));
      ReactGA.event({
        category: "profile",
        action: `Billing data saved successfully`,
      });
      saveUser( {...user, ...usr} );
    } else {
      dispatch(notify(t("profile.billing-saved-error"), "error"));
      ReactGA.event({
        category: "profile",
        action: `Billing data saved error`,
      });
    }
    setSaving(false);
    //window.location.reload();
  };

  const handleChangeLanguage = (code) => {
    setCountry(code);
  };

  const locale = i18n.language?.toLowerCase() ?? "";
  return (
    <div
      className="flex-auto px-4 lg:px-10 pt-5 pb-10 py-10"
      style={{ maxWidth: 700 }}
    >
      <form onSubmit={handleSaveChanges}>
        <div className="flex flex-wrap w-full">
          <div className="w-full lg:w-full px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-name"
            >
              {t("profile.business-name")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-name"
                type="text"
                className={`${
                  nameError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                  setNameError("");
                }}
              />
            </div>
          </div>
        </div>
        {nameError && (
          <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
            {nameError}
          </div>
        )}

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-cif"
            >
              {t("profile.cif")}
            </label>
            <input
              id="grid-cif"
              type="text"
              className={`border-0  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={cif}
              onChange={(e) => setCIF(e.currentTarget.value)}
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-phone"
            >
              {t("profile.phone")}
            </label>
            <PhoneInput
              inputClass="text-md"
              localization={locale === "es" ? es : {}}
              country="es"
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full lg:w-full px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("profile.email")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-email"
                type="email"
                className={`${
                  emailError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value.toLowerCase());
                  setEmailError("");
                }}
              />
            </div>
          </div>
        </div>
        {emailError && (
          <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
            {emailError}
          </div>
        )}

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-full lg:w-full px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-address"
            >
              {t("profile.address")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-address"
                type="text"
                className={`${
                  addressError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={address}
                onChange={(e) => {
                  setAddress(e.currentTarget.value);
                  setAddressError("");
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-country"
            >
              {t("profile.country")}
            </label>
            <CountrySelect
              searchable
              selected={country}
              onSelect={handleChangeLanguage}
              showSelectedLabel
              showOptionLabel
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-state"
            >
              {t("profile.state")}
            </label>
            <input
              id="grid-state"
              type="text"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-city"
            >
              {t("profile.city")}
            </label>
            <input
              id="grid-city"
              type="text"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-zip"
            >
              {t("profile.zip-code")}
            </label>
            <input
              id="grid-zip"
              type="text"
              className={`border-0  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={zipCode}
              onChange={(e) => setZipCode(e.currentTarget.value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-10 justify-center">
          <PrimaryButton loading={saving} type={"submit"}>
            {t("profile.save")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
