export const sepaCountries = [
  "FI",
  "AD",
  "AT",
  "PT",
  "BE",
  "ES",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GI",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "PT",
  "MT",
  "MC",
  "NL",
  "PT",
  "SM",
  "SK",
  "SI",
];
