import React, { useState } from "react";
import { notify } from "reapop";

import { useDispatch } from "react-redux";
import { GKBilling } from "gkshared";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ValidateEmail } from "../../services/validators";
import { Profile } from "../../models/user";
import { NotificationModal } from "../Modals/NotificationModal/NotificationModal";
import { getBrowserCountry } from "../../services/country";
import { PasswordInput } from "../PasswordInput/PasswordInput";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { CountrySelect } from "../CountrySelect/CountrySelect";

const gkb = new GKBilling(process.env.APPLICATION_ID as string);

interface PersonalDataProps {
  profile: Profile;
}

export const RegisterFormComponent = ({ onChangeTab }) => {
  const { t, i18n } = useTranslation();
  const lng = getBrowserCountry();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Country, setCountry] = useState(lng);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState({score: 0, password: ""})
  const [confirm, setConfirm] = useState({score: 0, password: ""});
  const [check, setCheck] = useState(true);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const router = useHistory();

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const formValid = () => {
    if (!check) {
      return false;
    }

    if (Password.password === "" || Password.password !== confirm.password) {
      return false;
    }

    // if(Password.score < 3){
    //   return false;
    // }

    if (Email === "") {
      return false;
    }
    if (!ValidateEmail(Email)) {
      return false;
    }

    if (FirstName === "") {
      return false;
    }
    if (LastName === "") {
      return false;
    }

    return true;
  };

  const handleSaveChanges = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let errors = false;

    // if (Password.score < 3) {
    //   errors = true;
    //   setPasswordError(t("login.error-passwords-not-complex"));
    // }

    if (Password.password !== confirm.password) {
      errors = true;
      setPasswordError(t("login.error-passwords-not-match"));
    }

    if (Password.password === "") {
      errors = true;
      setPasswordError(t("login.error-passwords-empty"));
    }

    if (Email === "") {
      setEmailError(t("login.error-email-empty"));
      errors = true;
    } else if (!ValidateEmail(Email)) {
      setEmailError(t("login.error-email-invalid"));
      errors = true;
    }

    if (FirstName === "") {
      setFirstNameError(t("login.error-name-empty"));
      errors = true;
    }
    if (LastName === "") {
      setLastNameError(t("login.error-lastname-empty"));
      errors = true;
    }

    if (errors) {
      return;
    }

    setSaving(true);

    try {
      const response: any = await gkb.Signup(
        Email.toLowerCase(),
        Password.password,
        FirstName,
        LastName,
        Country,
        ""
      );

      if (response?.status === 200) {
        dispatch(notify(t("login.account-created-successfully"), "success"));
        ReactGA.event({
          category: "login",
          action: `User registered ${Email.toLowerCase()}`,
        });
        setShow(true);
      } else if (
        response?.error.error.details === t("login.account-already-registered")
      ) {
        dispatch(notify(t("login.account-already-registered"), "error"));
      } else {
        dispatch(notify(t("login.error-creating-account"), "error"));
      }
    } catch (err) {
      dispatch(notify(t("login.error-creating-account"), "error"));
    }
    setSaving(false);
  };

  const handleChangeCountry = (code) => {
    setCountry(code);
  };

  const handleCloseDialog = (event: any) => {
    event.preventDefault();

    setShow(false);
    router.push("/login");
  };

  return (
    <>
      <NotificationModal
        show={show}
        title={t("login.account-created")}
        content={t("login.activation-email-sended")}
        onClose={handleCloseDialog}
      />
      <div className="flex-auto px-4 lg:px-10 py-10" style={{ maxWidth: 700 }}>
        <form onSubmit={handleSaveChanges}>
          <div className="flex flex-wrap w-full">
            <div className="w-full px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-country"
              >
                {t("profile.country")}
              </label>
              <CountrySelect
                searchable={true}
                selected={Country}
                onSelect={handleChangeCountry}
                showSelectedLabel
                showOptionLabel
                placeholder={t("register.select-country")}
                searchPlaceholder={t("register.search-country")}
              />
              <p
                className={
                  "text-sm text-left flex items-center text-yellow-600"
                }
              >
                {t("login.country-info")}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap w-full mt-8">
            <div className="w-full lg:w-full px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-email"
              >
                {t("login.email")}
              </label>
              <div className="flex items-center justify-end">
                <input
                  id="grid-email"
                  type="email"
                  disabled={Country === ""}
                  className={`${
                    emailError !== "" ? "border-1 border-red-600" : "border-0"
                  }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                  defaultValue={Email}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value.toLowerCase());
                    setEmailError("");
                  }}
                />
              </div>
            </div>
          </div>
          {emailError && (
            <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
              {emailError}
            </div>
          )}

          <div className="flex flex-wrap w-full mt-10">
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-name"
              >
                {t("login.name")}
              </label>
              <input
                id="grid-name"
                type="text"
                disabled={Country === ""}
                className={`${
                  firstNameError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={FirstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
              />
              {firstNameError && (
                <div className="text-red-600 italic text-sm text-left">
                  {firstNameError}
                </div>
              )}
            </div>
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-lastname"
              >
                {t("login.lastname")}
              </label>
              <input
                id="grid-lastname"
                type="text"
                disabled={Country === ""}
                className={`${
                  lastNameError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={LastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
              />
              {lastNameError && (
                <div className="text-red-600 italic text-sm text-left">
                  {lastNameError}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap w-full mt-10">
            <div className="w-6/12 lg:w-6/12 px-4">
              <PasswordInput
                showValidation={false}
                disabled={Country === ""}
                label={t("login.password")}
                value={Password.password}
                onChange={(p) => {
                  setPassword(p);
                }}
              />
            </div>
            <div className="w-6/12 lg:w-6/12 px-4">
              <PasswordInput
                showValidation={false}
                disabled={Country === ""}
                label={t("login.password")}
                value={confirm.password}
                onChange={(p) => {
                  setConfirm(p);
                }}
              />
            </div>
          </div>
          {passwordError && (
            <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
              {passwordError}
            </div>
          )}

          <div className="flex flex-wrap w-full mt-10">
            <div className="w-full lg:w-full px-4">
              <div className="flex items-center start">
                <input
                  id="grid-consent"
                  type="checkbox"
                  disabled={Country === ""}
                  className={`${
                    emailError !== "" ? "border-1 border-red-600" : "border-0"
                  }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring ease-linear mr-4 `}
                  checked={check}
                  onChange={(e) => {
                    setCheck(e.currentTarget.checked);
                  }}
                />
                <p>
                  {t("login.terms1")}{" "}
                  <a
                    target="_blank"
                    href="/legal/conditions"
                    className="underline hover:text-green-600"
                  >
                    {t("login.terms2")}
                  </a>{" "}
                  {t("login.terms3")}{" "}
                  <a
                    target="_blank"
                    href="/legal/privacy"
                    className="underline hover:text-green-600"
                  >
                    {t("login.terms4")}
                  </a>
                </p>
              </div>
            </div>
          </div>
            <div className="flex flex-wrap w-full mt-10 justify-around">
            <PrimaryButton
              //onClick={handleSaveChanges}
              type={"submit"}
              loading={saving}
              disabled={!formValid()}
            >
              {t("login.signup")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
};
