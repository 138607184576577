import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PaymentDetails } from "../../../../components/Subscription/PaymentDetails";
import { DispatchSetLoading } from "../../../../store/ui/actions";
import { gkb } from "../../../../services/gkb";

const ViewSubscriptionPayment = () => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState(null);
  const { id, pid } = useParams<{ id: string; pid: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    loadData().then().catch();
    const itv = setInterval(async () => {
      await loadData(false);
    }, 5000);
    return () => {
      clearInterval(itv);
    };
  }, []);

  const loadData = async (showLoading = true) => {
    showLoading && DispatchSetLoading(dispatch, true);
    gkb
      .GetApiResource("invoices", parseInt(pid, 10))
      .then((inv) => {
        setInvoice(inv);
      })
      .catch(() => {})
      .finally(() => {
        showLoading && DispatchSetLoading(dispatch, false);
      });
  };

  const handleOnReload = async () => {
    await loadData();
  };

  return (
    <section className="px-4 text-center pb-40 w-full">
      <ul className="text-left pb-4 flex text-green-600 text-lg">
        <li className="underline hover:opacity-50">
          <Link to="/my-account/subscriptions">{t("subscriptions.title")}</Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li>
          <Link
            className="underline hover:opacity-50"
            to={`/my-account/subscriptions/${id}`}
          >
            {t("subscriptions.subscription")} #{id}
          </Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li>
          <Link
            className="underline hover:opacity-50"
            to={`/my-account/subscriptions/${id}/payments/`}
          >
            {t("orders.payments")}
          </Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li className="text-gray-600">
          <span>
            {t("orders.payment")} #{pid}
          </span>
        </li>
      </ul>
      {invoice && (
        <PaymentDetails invoice={invoice} onReload={handleOnReload} />
      )}
    </section>
  );
};

export default ViewSubscriptionPayment;
