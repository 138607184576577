import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../components/Table/Table";
import { gkb } from "../../../../services/gkb";
import { PrimaryButton } from "../../../../components/Buttons/PrimaryButton";
import { AssignSubordinateModal } from "../../../../components/Modals/AssignSubordinate";
import { NotificationModal } from "../../../../components/Modals/NotificationModal/NotificationModal";
import { DispatchSetLoading } from "../../../../store/ui/actions";
import { useDispatch } from "react-redux";

const SubordinatesPage = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [search, setSearch] = React.useState("");
  const [showAssignModal, setShowAssignModal] = React.useState(false);
  const [showClearModal, setShowClearModal] = React.useState(false);
  const [currentCard, setCurrentCard] = React.useState(null);

  const handleAssignSubordinate = async (email, name) => {
    DispatchSetLoading(dispatch, true);

    try {
      setShowAssignModal(false);
      await gkb.AssignCard({
        ID: currentCard,
        Assignation: {
          Email: email,
          Name: name,
        },
      });
      DispatchSetLoading(dispatch, false);
      window.dispatchEvent(new Event("reload-table"));
    } catch (err) {
      setShowAssignModal(false);
      DispatchSetLoading(dispatch, false);
    }
  };

  const handleClearSubordinate = async () => {
    DispatchSetLoading(dispatch, true);
    try {
      setShowClearModal(false);
      await handleAssignSubordinate("", "");
      await gkb.ClearCard(currentCard);
      window.dispatchEvent(new Event("reload-table"));
      DispatchSetLoading(dispatch, false);
    } catch (err) {
      setShowClearModal(false);
      DispatchSetLoading(dispatch, false);
    }
  };

  const settings = {
    search: true,
    export: {
      toExcel: true,
    },
    columns: [
      { text: "#", field: "ID", sort: true },
      { text: t("created_at"), field: "CreatedAt", type: "date", sort: true },
      {
        text: t("cards.status"),
        width: 100,
        field: "Status",
        sort: true,
        getTextStyle: (value) => {
          switch (value.toLowerCase()) {
            case "incomplete":
              return "rounded bg-yellow-400 py-1 px-3 text-xs font-bold";
            case "expired":
              return "rounded bg-yellow-400 py-1 px-3 text-xs font-bold";
            case "active":
              return "rounded bg-green-400 py-1 px-3 text-xs font-bold";
            default:
              return "";
          }
        },
      },
      { text: t("type"), field: "Sku", sort: true },
      { text: t("name_alias"), field: "Name", sort: true },
      { text: t("Asignada a"), field: "AssignedName", sort: true },
      { text: t("Asignada email"), field: "AssignedTo", sort: true },
      { text: t("estado"), field: "AssignedStatus", sort: true },
      { text: "", field: "actions" },
    ],
    actions: [
      {
        text: t("cards.go"),
        action: "goto-card",
        color: "text-yellow-600",
        icon: "fa-external-link",
      },
      {
        text: t("editor.card-assign"),
        action: "assign-card",
        color: "text-green-600",
        icon: "fa-arrow-circle-right",
      },
      {
        text: t("editor.card-clear"),
        action: "clear-card",
        color: "text-green-600",
        icon: "fa-times",
      },

      {
        text: t("cards.configure"),
        action: "configure-card",
        color: "text-green-600",
        icon: "fa-edit",
      },
      {
        text: t("cards.configure-company"),
        action: "configure-company",
        color: "text-green-600",
        icon: "fa-building",
      },
      {
        text: t("cards.configure-template"),
        action: "configure-template",
        color: "text-green-600",
        icon: "fa-id-card",
      },
      {
        text: t("cards.configure-subordinates"),
        action: "configure-subordinates",
        color: "text-green-600",
        icon: "fa-address-book",
      },
    ],
  };

  const fetchData = async (pageSize, page, searchText, excel = false, sortField = null, sortDirection = 'asc') => {
    try {
      const response: any = await gkb.LoadSubordinatesByCompanyId(
        id,
        pageSize,
        page,
        searchText,
        sortField,
        sortDirection,
        excel
      );

      if (excel) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `subordinadas_${id}.xlsx`); // Nombre del archivo para guardar
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        return;
      }

      return {
        data: response.data.Data.map((s) => ({
          ...s,
        })),
        totalPages: response.data.TotalPages,
      };
    } catch (err) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    // };
  };

  const handleTableAction = async (action, row) => {
    if (action === "goto-card") {
      window.open("https://v2.gkd.es/" + row.Name, "_blank").focus();
    } else if (action === "configure-card") {
      router.push(`/editor/card/${row.ID}`);
    } else if (action === "configure-company") {
      router.push(`/my-account/cards/company/${row.ID}`);
    } else if (action === "configure-template") {
      router.push(`/editor/template/${row.MastercardID}`);
    } else if (action === "assign-card") {
      setCurrentCard(row.ContentID);
      setShowAssignModal(true);
    } else if (action === "clear-card") {
      setCurrentCard(row.ContentID);
      setShowClearModal(true);
    }
  };

  const handleRenderText = (value, column, row) => {
    if (column.field === "Sku") {
      return (
        <span className="capitalize">
          {t(value.split(":")[1].toLowerCase())}
        </span>
      );
    }
    if (column.field === "AssignedTo") {
      if (
        row.AssignedStatus === "assigned" ||
        row.AssignedStatus === "pending"
      ) {
        return <span>{row.AssignedTo}</span>;
      } else {
        return <span></span>;
      }
    }
    if (column.field === "AssignedStatus") {
      if (row.AssignedStatus === "assigned") {
        return <span>{t("cards.assigned")}</span>;
      } else if (row.AssignedStatus === "pending") {
        return <span>{t("cards.pending")}</span>;
      } else {
        return <span>{t("cards.available")}</span>;
      }
    }
    return value;
  };

  const handleRenderAction = (action, row) => {
    if (row.Status !== "active") return false;

    if (action === "goto-card") {
      return (
        row.Name != "" &&
        (row.Sku === "card:professional" || row.Sku.startsWith("sub:"))
      );
    }
    if (action === "configure-company") {
      return row.ContentType === "company";
    }
    if (action === "configure-card") {
      return row.ContentType === "card";
    }
    if (action === "assign-card") {
      return row.ContentType === "card" && row.AssignedStatus === "available";
    }
    if (action === "clear-card") {
      return (
        row.ContentType === "card" &&
        (row.AssignedStatus === "assigned" || row.AssignedStatus === "pending")
      );
    }
    if (action === "configure-template") {
      return row.ContentType === "company";
    }
    if (action === "configure-subordinates") {
      return row.ContentType === "company";
    }

    return true;
  };

  return (
    <div>
      <AssignSubordinateModal
        show={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        onChange={handleAssignSubordinate}
      />
      <NotificationModal
        show={showClearModal}
        title={t("cards.clear-subbordinate-title")}
        content={t("cards.clear-subordinate-content")}
        onClose={handleClearSubordinate}
        onCancel={() => setShowClearModal(false)}
      />

      <section className="px-4 text-center pb-40 w-full">
        <ul className="text-left pb-4 flex text-green-600 text-lg">
          <li className="underline hover:opacity-50">
            <Link to="/my-account/cards">{t("cards.title")} </Link>
          </li>
          <li>
            <span className="mx-1">/</span>
          </li>
          <li>
            <span className="text-gray-600">
              {t("cards.company")} #{id}
            </span>
          </li>
          <li>
            <span className="mx-1">/</span>
          </li>

          <li>
            <span className="text-gray-600">{t("cards.subordinates")}</span>
          </li>
        </ul>
        <div
          className="flex flex-1 justify-end "
          style={{ transform: "translateY(-8px)" }}
        >
          <PrimaryButton
            onClick={() => {
              router.push(
                `/cart?add-to-cart=card:subordinate-year&resource=${id}`
              );
            }}
          >
            {t("cards.buy-subordinates")}
          </PrimaryButton>
        </div>
        <Table
          settings={settings}
          fetchData={fetchData}
          onAction={handleTableAction}
          renderText={handleRenderText}
          renderAction={handleRenderAction}
        />
      </section>
    </div>
  );
};

export default SubordinatesPage;

function clearCard(card: any): any {
  const newCard = JSON.parse(JSON.stringify(card));
  newCard.Alias = "";
  newCard.Language = "";
  newCard.Corporate = this.newCorporateData();
  newCard.Contact = this.newContactData();
  newCard.Search = this.newSearchData();
  newCard.Header = this.newHeaderData();
  newCard.Footer = this.newFooterData();
  newCard.Sections = [];
  newCard.Assignation = this.newAssignationData();
  newCard.CustomCss = "";
  newCard.CustomJs = "";

  return newCard;
}
