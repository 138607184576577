import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { Verification } from "../../../models/reseller";

export const OnBoardingStep0 = ({ nextStep }: any) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const handleSubmit = async () => {
    nextStep();
  };

  return (
    <div>
      <div className="w-full px-4 lg:px-10 py-4 flex justify-center">
        <form className="w-full flex-col flex items-center">
          <h1 className="text-2xl font-bold text-green-600 mb-4">Onboarding</h1>
          <div className="text-left">
            <div className="w-full lg:w-full px-4">
              <p className="mb-4">{t("onbard0.text1")}</p>
              <p className="mb-4">
                {t("onbard0.text2a")}
                <span className="text-green-600 font-bold">
                  {t("onbard0.text2b")}
                </span>
              </p>
              <p className="mb-4">{t("onbard0.text3")}</p>
              <p className="mb-4 text-green-600 font-bold">
                {t("onbard0.text4")}
              </p>
            </div>
            <div className="w-full lg:w-full px-4 text-center mb-4">
              <div className="mt-12">
                <input
                  className="cursor-pointer text-md"
                  type="checkbox"
                  id="tos"
                  checked={checked}
                  onChange={(e: any) => setChecked(e.currentTarget.checked)}
                />
                <label
                  className={`${
                    checked ? "text-green-600" : "text-red-400"
                  } ml-2 cursor-pointer  text-lg font-bold`}
                  htmlFor="tos"
                >
                  {t("onbard0.acept")}
                </label>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end mt-12">
            <PrimaryButton disabled={!checked} onClick={handleSubmit}>
              {t("onbard0.start")}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};
