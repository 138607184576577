import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NotificationModal/NotificationModal.module.scss";
import { DangerButton } from "../Buttons/DangerButton";
import { ValidateEmail } from "../../services/validators";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { useTheme } from "../../store/ui/selector";

export const LostPasswordModal = ({ show, onClose, onChange }) => {
  const { t, i18n } = useTranslation();

  const [saving, setSaving] = useState(false);
  const [dataValid, isDataValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const locale = i18n.language?.toLowerCase() ?? "";
  const {theme} = useTheme();

  useEffect(() => {
    if (!show) {
      setEmail("");
    }
  }, [show]);

  const handleOnBillingDataChange = () => {
    if (email !== "" && !ValidateEmail(email)) {
      setEmailError(t("profile.error-email-invalid"));
      isDataValid(false);
      return false;
    } else if (email === "") {
      isDataValid(false);
    } else {
      isDataValid(true);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(email);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={`${styles.backdrop} fixed z-1000 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full md:w-3/12"
          style={{maxWidth: "500px"}}>
        <div className="text-center text-2xl p-4 bg-green-600 text-white font-bold"
             style={{backgroundColor: theme?.Content?.styles?.secondaryColor}}>
          {t("login.lost-password-modal")}
        </div>
        <div className="w-full text-center p-4 text-gray-600 mt-4">
          {t("login.lost-password")}
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="bg-white px-4  pb-4 sm:p-6 sm:pb-4">
            <div className="flex">
              <div className="w-full text-lg text-center font-bold flex-1">
                <div className="flex flex-wrap w-full">
                  <div className="w-full lg:w-full px-4">
                    <label
                      className="block uppercase text-red-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-email"
                    >
                      {t("profile.email")}{" "}
                      <span className={"text-red-600 italic"}>*</span>
                    </label>
                    <div className="flex items-center justify-end">
                      <input
                        id="grid-email"
                        type="email"
                        className={`${
                          emailError !== ""
                            ? "border-1 border-red-600"
                            : "border-0"
                        }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                        value={email}
                        onBlur={(e) => {
                          setEmail(e.currentTarget.value.toLowerCase());
                          setEmailError("");
                          handleOnBillingDataChange();
                        }}
                        onChange={(e) => {
                          setEmail(e.currentTarget.value.toLowerCase());
                          setEmailError("");
                          handleOnBillingDataChange();
                        }}
                      />
                    </div>
                  </div>
                </div>
                {emailError && (
                  <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
                    {emailError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="w-full text-right p-4 bg-gray-200 text-white font-bold flex justify-end">
          <div className="flex-1 text-right">
            <DangerButton onClick={onClose} className={"mr-2"}>
              <span className="uppercase">{t("editor.cancel")}</span>
            </DangerButton>
            <PrimaryButton
              disabled={!dataValid}
              loading={saving}
              type={"submit"}
              onClick={handleOnSubmit}
            >
              <span className="uppercase font-bold">{t("editor.acept")}</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
