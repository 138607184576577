import React from "react";
import styles from "./LocalLoader.module.scss";

export default function localLoader(): any {
  return (
    <div className={`${styles.gkloader} flex items-center justify-center`}>
      <p style={{ color: "transparent" }}>Loading...</p>
    </div>
  );
}
