import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../styles/pages/faqs.module.scss";
import { ContactForm } from "../components/Sections/ContactForm";
import { gkb } from "../services/gkb";
import { DispatchSetLoading } from "../store/ui/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "../store/ui/selector";

const SectionFooter = ({ t }) => (
  <section className="px-4 text-center pt-5 pb-40 bg-blueGray-800">
    <div className="w-full max-w-6xl mx-auto">
      <h2 className="text-4xl my-10 text-center font-semibold font-heading text-white">
        {t("faqs.title")}
      </h2>
      <h2 className="text-2xl text-gray-400 mt-10 mb-20 leading-tight font-semibold font-heading">
        {t("faqs.subtitle")}
      </h2>

      <ContactForm form="faqs" />
    </div>
  </section>
);

export default function FaqsPage() {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [features, setFeatures] = useState<any>(null);
  const dispatch = useDispatch();
  const { theme } = useTheme();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        { name: "features", filter: '"group"=eq(faqs)' },
        { pageSize: 10, sort: '"order".ASC' }
      )
      .then((res) => {
        setFeatures(res.Data.filter((d) => d.Button.Url !== "/faqs/resellers"));
        DispatchSetLoading(dispatch, false);
      })
      .catch();
  }, []);

  return (
    <>
      <section className="pt-12 pb-40 px-4 text-center">
        <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10"
            style={{ ...theme?.Content?.styles?.faqs?.title }}>
          {t("faqs.title2")}
        </h2>
        <p className="text-gray-400 text-xl my-10">{t("faqs.subtitle2")}</p>

        <div className="flex flex-wrap justify-center">
          {features?.map((f, i) => (
            <div
              key={i}
              className={`${styles.card} shadow-xl`}
              onClick={() => {
                router.push(f.Button.Url || "#");
              }}
              style={{ ...theme?.Content?.styles?.faqs?.card }}>
              <div dangerouslySetInnerHTML={{ __html: f.Content[language] }} />
            </div>
          ))}
        </div>
      </section>
      <SectionFooter t={t} />
    </>
  );
}
