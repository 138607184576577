/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContactForm } from "../components/Sections/ContactForm";
import { gkb } from "../services/gkb";
import { DispatchSetLoading } from "../store/ui/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "../store/ui/selector";

const Faq = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false);
  const { theme } = useTheme();

  return (
    <div className="rounded shadow-lg">
      <button
        className="flex items-center w-full p-4 mb-4 text-left focus:outline-none"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <span className="flex-grow text-green-600 font-bold"
          style={{ color: theme?.Content?.styles?.faqs?.title?.color }}
        >{title}</span>
        <svg
          className={`text-green-600 w-6 h-6 ${
            expanded ? "transform rotate-90" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>

      {expanded && (
        <Fade>
          <p className="border-t-solid border-t py-6 mb-4 text-left px-4">
            {content}
          </p>
        </Fade>
      )}
    </div>
  );
};

const SectionFooter = ({ t }) => (
  <section className="px-4 text-center pt-5 pb-40 bg-blueGray-800">
    <div className="w-full max-w-6xl mx-auto">
      <h2 className="text-4xl my-10 text-center font-semibold font-heading text-white">
        {t("faqs.title")}
      </h2>
      <h2 className="text-2xl text-gray-400 mt-10 mb-20 leading-tight font-semibold font-heading">
        {t("faqs.subtitle")}
      </h2>

      <ContactForm form="faqs" />
    </div>
  </section>
);

export default function FaqsSectionPage() {
  const { section } = useParams<{ section: string }>();
  const { t, i18n } = useTranslation();
  const welcome = `${t("questions")} ${t(section)}`;
  const [faqs, setFaqs] = useState([]);
  const dispatch = useDispatch();
  const { theme } = useTheme();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        {
          name: "faqs",
          filter: `category=eq(${section})`,
        },
        {}
      )
      .then((resp: any) => {
        setFaqs(resp.Data);
      })
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  return (
    <>
      <section
        className="pt-12 pb-40 px-4 text-center"
        style={{ marginTop: 80 }}
      >
        <div className="max-w-3xl mx-auto">
          <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10"
          style={{ ...theme?.Content?.styles?.faqs?.title }}
          >
            {t("faqs.title2")}
          </h2>
          <p className="text-gray-400 text-xl my-10">{t(welcome)}</p>
          {faqs.map((q: any) => (
            <Faq
              key={q.ID}
              title={q.Question[i18n.language]}
              content={q.Response[i18n.language]}
            />
          ))}
        </div>
      </section>
      <SectionFooter t={t} />
    </>
  );
}
