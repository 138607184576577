// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1VJdhufSYhbyWXnCu-YFu3{background-color:#f7f8f3;border:1px solid #258c80;color:#258c80;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;align-self:center}._2wm7sQSWsNqmLKVlZ9shNm{color:gray}._2wm7sQSWsNqmLKVlZ9shNm:hover{cursor:default}", "",{"version":3,"sources":["webpack://./src/components/Buttons/Buttons.module.scss"],"names":[],"mappings":"AAAA,yBACE,wBAAA,CACA,wBAAA,CACA,aAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,iBAAA,CAGF,yBACI,UAAA,CAGJ,+BACE,cAAA","sourcesContent":[".secondaryButton {\n  background-color: #f7f8f3;\n  border: 1px solid #258c80;\n  color: #258c80;\n  width: fit-content;\n  align-self: center;\n}\n\n.disabled {\n    color: gray;\n}\n\n.disabled:hover {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondaryButton": "_1VJdhufSYhbyWXnCu-YFu3",
	"disabled": "_2wm7sQSWsNqmLKVlZ9shNm"
};
export default ___CSS_LOADER_EXPORT___;
