// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3B378y1n3_uOoXbhE1VJ0{--tw-bg-opacity: 1;background-color:rgba(4, 120, 87, var(--tw-bg-opacity))}._3B378y1n3_uOoXbhE1VJ0 span{color:#f5f5f5}._3B378y1n3_uOoXbhE1VJ0:hover span{color:#000}.kkZg8EkfRVeGmcCPvM45s{height:-webkit-fit-content !important;height:-moz-fit-content !important;height:fit-content !important;min-width:256px}", "",{"version":3,"sources":["webpack://./src/components/Navbars/MyAccountNav.module.scss"],"names":[],"mappings":"AAAA,wBACI,kBAAA,CACA,uDAAA,CAEA,6BACI,aAAA,CAID,mCACC,UAAA,CAKR,uBACI,qCAAA,CAAA,kCAAA,CAAA,6BAAA,CACA,eAAA","sourcesContent":[".selected {\n    --tw-bg-opacity: 1;\n    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));\n    \n    span {\n        color: whitesmoke; \n   }\n\n   &:hover {\n       span {\n        color: black;\n       }\n   }\n}\n\n.navWrapper {\n    height: fit-content !important;\n    min-width: 256px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "_3B378y1n3_uOoXbhE1VJ0",
	"navWrapper": "kkZg8EkfRVeGmcCPvM45s"
};
export default ___CSS_LOADER_EXPORT___;
