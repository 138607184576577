import React from "react";
import styles from "./Buttons.module.scss";

type ButtonsProperties = {
  children: any;
  onClick: any;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  className?: string;
};

export const DangerButton = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  full = false,
  className = "",
}: ButtonsProperties) => {
  if (loading) {
    return (
      <button
        className={`${styles.disabled} opacity-50 bg-red-700 text-white text font-bold uppercase px-4 py-2 rounded shadow  outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type="button"
      >
        <i className="fas fa-spinner animate-spin" />
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        className={`${styles.disabled} ${
          full ? "w-full" : ""
        } bg-gray-400  text font-bold uppercase px-4 py-2 rounded shadow outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={`bg-red-700 ${
        full ? "w-full" : ""
      }   text-white active:bg-red-600 hover:bg-red-600 text font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
