import { useSelector } from "react-redux";
import { StoreState } from "../index";

export function useLoading(): boolean {
  return useSelector((state: StoreState) => state.ui.loading > 0);
}

export function useTheme(): any {
  return useSelector((state: StoreState) => ({theme: state.ui.theme, defaultTheme: state.ui.defaultTheme}));
}
