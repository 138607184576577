import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import { DispatchSetLoading } from "../../../store/ui/actions";
import { gkb } from "../../../services/gkb";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { ValidateEmail } from "../../../services/validators";
import { notify } from "reapop";
import PhoneInput from "react-phone-input-2";
import ReactGA from "react-ga";

const ViewCompany = () => {
  const { t, i18n } = useTranslation();
  const [company, setCompany] = useState<any>(null);
  const [resource, setResource] = useState<any>(null);
  const [emailError, setEmailError] = useState("");
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .GetApiResource("resources", parseInt(id, 10))
      .then((res) => {
        setCompany(res.Company);
        setResource(res.Resource);
        DispatchSetLoading(dispatch, false);
      })
      .catch(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  const setField = (name: string, value: string) => {
    const newCompany = { ...company };
    newCompany[name] = value;
    setCompany(newCompany);
  };

  const handleSaveChanges = async () => {
    if (company.Email) {
      if (!ValidateEmail(company.Email)) {
        setEmailError(t("profile.error-email-invalid"));
        return;
      }
    }

    setSaving(true);

    let phoneAux = company.Phone;
    if (company.Phone && !company.Phone.startsWith("+")) {
      phoneAux = `+${company.Phone}`;
    }

    company.Phone = phoneAux;
    const response = await gkb.UpdateApiResource({
      name: "companies",
      id: company.ID,
      body: { ...company },
    });

    if (response) {
      ReactGA.event({
        category: "cards",
        action: `Company changed ${company.ID}`,
      });
      dispatch(notify(t("profile.billing-saved-success"), "success"));
    } else {
      dispatch(notify(t("profile.billing-saved-error"), "error"));
    }
    setSaving(false);
  };

  const locale = i18n.language?.toLowerCase() ?? "";

  return (
    <section className="px-4 text-center w-full">
      <ul className="text-left pb-4 flex text-green-600 text-lg">
        <li className="underline hover:opacity-50">
          <Link to="/my-account/cards">{t("cards.title")} </Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li>
          <span className="text-gray-600">
            {t(
              resource?.Sku === "card:fidelium"
                ? "cards.organization"
                : "cards.company"
            )}{" "}
            #{id}
          </span>
        </li>
      </ul>
      {company && (
        <div className="flex-auto px-4 lg:px-10 py-10 border rounded shadow-lg">
          <form>
            <div className="flex flex-wrap w-full mt-10">
              <div className="w-6/12 lg:w-6/12 px-4">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                  htmlFor="grid-name"
                >
                  {t("profile.business-name")}
                </label>
                <input
                  id="grid-name"
                  type="text"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                  defaultValue={company.Name}
                  onChange={(e) => setField("Name", e.currentTarget.value)}
                />
              </div>
              <div className="w-6/12 lg:w-6/12 px-4">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                  htmlFor="grid-email"
                >
                  {t("profile.email")}
                </label>
                <input
                  id="grid-email"
                  type="email"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                  defaultValue={company.Email}
                  onChange={(e) => setField("Email", e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap w-full mt-10">
              <div className="w-6/12 lg:w-6/12 px-4">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                  htmlFor="grid-url"
                >
                  {t("profile.web")}
                </label>
                <input
                  id="grid-url"
                  type="url"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                  defaultValue={company.Url}
                  onChange={(e) => setField("Url", e.currentTarget.value)}
                />
              </div>
              <div className="w-6/12 lg:w-6/12 px-4">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                  htmlFor="grid-phone"
                >
                  {t("profile.phone")}
                </label>
                <PhoneInput
                  inputClass="text-md"
                  localization={locale === "es" ? es : {}}
                  country="es"
                  value={company.Phone}
                  onChange={(value) => setField("Phone", value)}
                />
              </div>
            </div>
            <div className="w-full lg:w-full px-4 mt-10">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-address"
              >
                {t("profile.address")}
              </label>
              <textarea
                style={{ height: 200, resize: "none" }}
                id="grid-address"
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={company.Address}
                onChange={(e) => setField("Address", e.currentTarget.value)}
              />
            </div>
            <div className="flex flex-wrap w-full mt-10 justify-around">
              <PrimaryButton onClick={handleSaveChanges} loading={saving}>
                {t("profile.save")}
              </PrimaryButton>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default ViewCompany;
