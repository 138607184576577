export function ValidateEmail(mail: string) {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

export function validateDate(date: string) {
  const currentYear = new Date().getFullYear();
  const dt = date.split("/");
  if (dt.length !== 3) {
    return false;
  }

  const [day, month, year] = dt.map((s) => parseInt(s, 10));

  if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) {
    return false;
  }

  if (day <= 0 || day > 31) {
    return false;
  }
  if (month <= 0 || month > 12) {
    return false;
  }
  if (year <= 1900 || year > currentYear) {
    return false;
  }

  return true;
}
