import BgLoader from "./components/bgLoader/BgLoader";
import React, { Suspense, useEffect, useState } from "react";
import Session from "react-session-persist";
import { PersistGate } from "redux-persist/integration/react";
import Cookies from "js-cookie";
import { App } from "./App";
import "./i18n";
import { Provider } from "react-redux";
import { NotificationsProvider } from "reapop";
import { loadStripe } from "@stripe/stripe-js/pure";
import configureStore from "./store";

// Import styles
import "./index.css";
import "./styles/tailwind.css";
import "./styles/toggle.css";
import "./styles/react-tel-input.css";
import { Stripe } from "@stripe/stripe-js";

declare global {
  interface Navigator {
    msSaveBlob: (blob: any, defaultName?: string) => boolean
  }
}

const { store, persistor } = configureStore();

const isLocal = window.location.host.indexOf(":") > -1;
let stripePromise:Promise<Stripe> = loadStripe(
          isLocal? (process.env.STRIPE_TEST_PUBLIC_KEY as string || "") : (process.env.STRIPE_PUBLIC_KEY as string || "")
        );

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const reseller = urlParams.get("reseller");
if (reseller) {
  // @ts-ignore
  Cookies.set("gkb-reseller", reseller);
}

export const Root: any = () => {
  const [ tenant, setTenant ] = useState(null);

  // useEffect(() => {
  //   gkb.GetMyTenant().then((response) => {
  //     if(response.status === 200) {
  //       if(response.data?.Testing) {
  //         stripePromise = loadStripe(
  //           (process.env.STRIPE_TEST_PUBLIC_KEY as string) || ""
  //         );
  //       } else {
  //         stripePromise = loadStripe(
  //           (process.env.STRIPE_PUBLIC_KEY as string) || ""
  //         );
  //       }
  //       setTenant(response.data);
  //     }
  //   })
  // }, []);
  //
  // if(!tenant) return <BgLoader />;

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<BgLoader />}>
            <NotificationsProvider>
              {/* @ts-ignore */}
              <Session>
                <App stripe={stripePromise}/>
              </Session>
            </NotificationsProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
