import React, { useEffect, useState } from "react";
import Parcel from "single-spa-react/parcel";

import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSession } from "react-session-persist/lib";

export default function EditorPage() {
  const { t } = useTranslation();
  const { authenticated, session, user } = useSession();
  const router = useHistory();
  const { id } = useParams<{ id: string }>();

  if (!authenticated) {
    router.replace("/");
    return null;
  }

  const parcelConfig = {
    // optional
    bootstrap(props) {
      // one time initialization
      return System.import("@greenkard/editor");
    },
    // required
    mount(props) {
      // use a framework to create dom nodes and mount the parcel
      alert("Parcel mount");
      return Promise.resolve();
    },
    // required
    unmount(props) {
      // use a framework to unmount dom nodes and perform other cleanup
      alert("Parcel unmount");
      return Promise.resolve();
    },
    // optional
    update(props) {
      // use a framework to update dom nodes
      return Promise.resolve();
    },
  };

  return (
    <>
      <section className="text-center bg-gray-100">
        <div className="w-full">
          <div id={"editor-container"} />
          <Parcel
            // @ts-ignore
            config={() => System.import("@greenkard/editor")}
            wrapWith="div"
            handleError={(err) => console.error(err)}
            user={user}
            unmountOnExit={true}
          />
        </div>
      </section>
    </>
  );
}
