import {
  Ach,
  Affirm,
  Afterpay,
  Alfamart,
  Alipay,
  Amazon,
  AmazonTransparent,
  Amex,
  Applepay,
  AtmMomo,
  Aura,
  Bacs,
  Bancontact,
  Bankaxept,
  Bb,
  BbTransparent,
  Benefit,
  Bitcoin,
  Blik,
  Boleto,
  BoletoTransparent,
  Boost,
  Bradesco,
  BradescoTransparent,
  Caixa,
  CaixaTransparent,
  CartesBancaires,
  Cielo,
  CieloTransparent,
  Cirrus,
  Clearpay,
  Dana,
  Dankort,
  Diners,
  DinersClub,
  DirectDebit,
  Discover,
  DokuWallet,
  Dotpay,
  Ebay,
  EftposAustralia,
  Elavon,
  ElavonTransparent,
  Elo,
  Eps,
  Eway,
  Fawry,
  GCash,
  Generic,
  GenericTransparent,
  Girocard,
  Giropay,
  Global,
  GlobalTransparent,
  Googlepay,
  GrabPay,
  Green,
  Hiper,
  Hsbc,
  HsbcTransparent,
  Ideal,
  InteracLogo,
  Itau,
  ItauTransparent,
  Jcb,
  KakaoPay,
  Klarna,
  Knet,
  Konbini,
  MPesa,
  Mada,
  Maestro,
  Mastercard,
  MbWay,
  Meeza,
  MobilePay,
  Multibanco,
  Naps,
  Omannet,
  Oxxo,
  Pagseguro,
  PagseguroTransparent,
  PayEasy,
  Payco,
  Paypal,
  PaypalTransparent,
  Paysafecard,
  Payshop,
  Paywithgoogle,
  Pix,
  Poli,
  Ratepay,
  RazerGold,
  Rede,
  RedeTransparent,
  Sage,
  SamsungPay,
  Santander,
  SantanderTransparent,
  Sepa,
  Shopify,
  Skrill,
  SkrillMoneybookers,
  Solo,
  Stone,
  StoneTransparent,
  Swish,
  Troy,
  Trustly,
  Twint,
  UnionPay,
  Vipps,
  Visa,
  VisaElectron,
  Visadankort,
  Vpay,
  Wallet,
  WalletTransparent,
  Wechatpay,
  Western,
  Worldpay,
  WorldpayTransparent,
} from "react-pay-icons";
import React from "react";

const icons = {
  ach: Ach,
  affirm: Affirm,
  afterpay: Afterpay,
  alfamart: Alfamart,
  alipay: Alipay,
  amazon: Amazon,
  amazonTransparent: AmazonTransparent,
  amex: Amex,
  applepay: Applepay,
  atmMomo: AtmMomo,
  aura: Aura,
  bacs: Bacs,
  bancontact: Bancontact,
  bankaxept: Bankaxept,
  bb: Bb,
  bbTransparent: BbTransparent,
  benefit: Benefit,
  bitcoin: Bitcoin,
  blik: Blik,
  boleto: Boleto,
  boletoTransparent: BoletoTransparent,
  boost: Boost,
  bradesco: Bradesco,
  bradescoTransparent: BradescoTransparent,
  caixa: Caixa,
  caixaTransparent: CaixaTransparent,
  cartesBancaires: CartesBancaires,
  cielo: Cielo,
  cieloTransparent: CieloTransparent,
  cirrus: Cirrus,
  clearpay: Clearpay,
  dana: Dana,
  dankort: Dankort,
  diners: Diners,
  dinersClub: DinersClub,
  directDebit: DirectDebit,
  discover: Discover,
  dokuWallet: DokuWallet,
  dotpay: Dotpay,
  ebay: Ebay,
  eftposAustralia: EftposAustralia,
  elavon: Elavon,
  elavonTransparent: ElavonTransparent,
  elo: Elo,
  eps: Eps,
  eway: Eway,
  fawry: Fawry,
  gCash: GCash,
  generic: Generic,
  genericTransparent: GenericTransparent,
  girocard: Girocard,
  giropay: Giropay,
  global: Global,
  globalTransparent: GlobalTransparent,
  googlepay: Googlepay,
  grabPay: GrabPay,
  green: Green,
  hiper: Hiper,
  hsbc: Hsbc,
  hsbcTransparent: HsbcTransparent,
  ideal: Ideal,
  interacLogo: InteracLogo,
  itau: Itau,
  itauTransparent: ItauTransparent,
  jcb: Jcb,
  kakaoPay: KakaoPay,
  klarna: Klarna,
  knet: Knet,
  konbini: Konbini,
  mPesa: MPesa,
  mada: Mada,
  maestro: Maestro,
  mastercard: Mastercard,
  mbWay: MbWay,
  meeza: Meeza,
  mobilePay: MobilePay,
  multibanco: Multibanco,
  naps: Naps,
  omannet: Omannet,
  oxxo: Oxxo,
  pagseguro: Pagseguro,
  pagseguroTransparent: PagseguroTransparent,
  payEasy: PayEasy,
  payco: Payco,
  paypal: Paypal,
  paypalTransparent: PaypalTransparent,
  paysafecard: Paysafecard,
  payshop: Payshop,
  paywithgoogle: Paywithgoogle,
  pix: Pix,
  poli: Poli,
  ratepay: Ratepay,
  razerGold: RazerGold,
  rede: Rede,
  redeTransparent: RedeTransparent,
  sage: Sage,
  samsungPay: SamsungPay,
  santander: Santander,
  santanderTransparent: SantanderTransparent,
  sepa: Sepa,
  shopify: Shopify,
  skrill: Skrill,
  skrillMoneybookers: SkrillMoneybookers,
  solo: Solo,
  stone: Stone,
  stoneTransparent: StoneTransparent,
  swish: Swish,
  troy: Troy,
  trustly: Trustly,
  twint: Twint,
  unionPay: UnionPay,
  vipps: Vipps,
  visa: Visa,
  visaElectron: VisaElectron,
  visadankort: Visadankort,
  vpay: Vpay,
  wallet: Wallet,
  walletTransparent: WalletTransparent,
  wechatpay: Wechatpay,
  western: Western,
  worldpay: Worldpay,
  worldpayTransparent: WorldpayTransparent,
};

export const PaymentIcon = ({ icon, style }) => {
  let component: any = null;
  if (icons[icon]) {
    component = React.createElement(icons[icon], {
      style,
    });
  }
  return component;
};
