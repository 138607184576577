// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HOyGXd-E9wcffxws1u8f4{width:400px;margin:40px;padding:10px;border-radius:.5rem}", "",{"version":3,"sources":["webpack://./src/components/Sections/SectionFeatures.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,WAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".card {\n  width: 400px;\n  margin: 40px;\n  padding: 10px;\n  border-radius: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "HOyGXd-E9wcffxws1u8f4"
};
export default ___CSS_LOADER_EXPORT___;
