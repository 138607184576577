import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../components/Table/Table";
import { gkb } from "../../../../services/gkb";
import { DownloadUrl } from "../../../../services/file-download";

const PaymentsSubscriptionPage = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const settings = {
    columns: [
      { text: "#", field: "ID" },
      {
        text: t("orders.status"),
        width: 100,
        field: "PaymentStatus",
        getTextStyle: (value) => {
          switch (value.toLowerCase()) {
            case "failed":
              return "rounded bg-red-400 py-1 px-3 text-xs font-bold";
            case "succeeded":
              return "rounded bg-green-200 py-1 px-3 text-xs font-bold";
            case "pending":
              return "rounded bg-yellow-200 py-1 px-3 text-xs font-bold";
            default:
              return "";
          }
        },
      },
      { text: t("orders.subscription"), field: "SubscriptionID" },
      { text: t("orders.reason"), field: "BillingReason" },
      {
        text: t("orders.date"),
        field: "Created",
        type: "date",
      },
      {
        text: t("orders.total"),
        field: "Total",
        type: "currency",
        currencyField: "Currency",
      },
      { text: "", field: "actions" },
    ],
    rowColor: (row) => {
      if (row.PaymentStatus === "FAILED") {
        return "bg-red-100";
      } else {
        return "bg-white";
      }
    },
    actions: [
      {
        text: t("orders.view-invoice"),
        action: "view-invoice",
        color: "text-red-600",
        icon: "fa-file-pdf",
      },
      {
        text: t("orders.details"),
        action: "view",
        color: "text-green-600",
        icon: "fa-file",
      },
    ],
  };

  const fetchData = async (pageSize, page) => {
    try {
      const response = await gkb.ListApiResource(
        { name: "invoices", filter: `subscription_id=equal(${id})` },
        { pageSize, page }
      );

      return {
        data: response.Data.map((s) => ({
          ...s,
        })),
        totalPages: response.TotalPages,
      };
    } catch (err) {
      return {
        data: [],
        totalCount: 0,
      };
    }
  };

  const handleTableAction = async (action, row) => {
    if (action === "view") {
      router.push(`/my-account/subscriptions/${id}/payments/${row.ID}`);
    } else if (action === "view-invoice") {
      const response = await gkb.DownloadInvoice(row.ID);
      if (response?.data) {
        DownloadUrl(response.data.InvoiceUrl, response.data.FileName);
      }
    }
  };

  const handleRenderText = (value, column) => {
    if (column.field === "PaymentStatus") {
      return t(value.toLowerCase());
    }
    if (column.field === "BillingReason") {
      return t(value);
    }
    return value;
  };

  const handleRenderAction = (action, row) => {
    if (action === "view-invoice") {
      return row.PaymentStatus === "SUCCEEDED";
    }

    return true;
  };

  return (
    <>
      <section className="px-4 text-center pb-40 w-full">
        <ul className="text-left pb-4 flex text-green-600 text-lg">
          <li className="underline hover:opacity-50">
            <Link to="/my-account/subscriptions">
              {t("subscriptions.title")}
            </Link>
          </li>
          <li>
            <span className="mx-1">/</span>
          </li>
          <li>
            <Link
              className="underline hover:opacity-50"
              to={`/my-account/subscriptions/${id}`}
            >
              {t("subscriptions.subscription")} #{id}
            </Link>
          </li>
          <li>
            <span className="mx-1">/</span>
          </li>
          <li className="text-gray-600">
            <span>{t("orders.payments")}</span>
          </li>
        </ul>
        <Table
          settings={settings}
          fetchData={fetchData}
          onAction={handleTableAction}
          renderText={handleRenderText}
          renderAction={handleRenderAction}
        />
      </section>
    </>
  );
};

export default PaymentsSubscriptionPage;
