import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { gkb } from "../../services/gkb";
import { useTranslation } from "react-i18next";
import styles from "./NotificationModal/NotificationModal.module.scss";
import { DangerButton } from "../Buttons/DangerButton";
import stripeLogo from "../../assets/img/stripe-ssl2.png";
import PhoneInput from "react-phone-input-2";
import ReactFlagsSelect from "react-flags-select";
import esCountries from "../../models/countries.json";
import { ValidateEmail } from "../../services/validators";
import es from "react-phone-input-2/lang/es.json";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { CountrySelect } from "../CountrySelect/CountrySelect";

export const AddPaymentMethodModal = ({
  title,
  profile,
  show,
  onClose,
  onComplete,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const { t, i18n } = useTranslation();

  const [cardValid, isCardValid] = useState(false);
  const [dataValid, isDataValid] = useState(false);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(profile.BillingDataName ?? "");
  const [cif, setCIF] = useState(profile.BillingDataVat ?? "");
  const [phone, setPhone] = useState(profile.BillingDataPhone ?? "");
  const [address, setAddress] = useState(profile.BillingDataAddress ?? "");
  const [email, setEmail] = useState(profile.BillingDataEmail ?? "");
  const [city, setCity] = useState(profile.BillingDataCity ?? "");
  const [country, setCountry] = useState(
    profile.BillingDataCountry?.toUpperCase() ?? ""
  );
  const [state, setState] = useState(profile.BillingDataState ?? "");
  const [zipCode, setZipCode] = useState(profile.BillingDataZipCode ?? "");
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const locale = i18n.language?.toLowerCase() ?? "";

  useEffect(() => {
    handleOnBillingDataChange();
  }, []);

  const handleOnBillingDataChange = () => {
    if (email !== "" && !ValidateEmail(email)) {
      setEmailError(t("profile.error-email-invalid"));
      return false;
    }

    if (email !== "" && name !== "" && zipCode !== "") {
      isDataValid(true);
    } else {
      isDataValid(false);
    }
  };

  const handleOnChange = (event) => {
    if (event.complete) {
      isCardValid(true);
    } else {
      isCardValid(false);
    }
  };

  const handleChangeLanguage = (code) => {
    setCountry(code);
  };

  const handlePay = async () => {
    if (email !== "") {
      if (!ValidateEmail(email)) {
        setEmailError(t("profile.error-email-invalid"));
        return;
      }
    }

    setSaving(true);

    let phoneAux = phone;
    if (!phone.startsWith("+")) {
      phoneAux = `+${phone}`;
    }

    const billingData = {
      name,
      cif,
      phone: phoneAux,
      address,
      email,
      city,
      state,
      country,
      zipCode,
    };
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !cardElement) {
      setSaving(false);
      return;
    }
    const response = await gkb.CreateSetupIntent();

    const { setupIntent, error } = await stripe.confirmCardSetup(
      response.intent.client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            phone: billingData.phone !== "" ? billingData.phone : undefined,
            email: billingData.email !== "" ? billingData.email : undefined,
            name: billingData.name !== "" ? billingData.name : undefined,
            address: {
              city: billingData.city !== "" ? billingData.city : undefined,
              country:
                billingData.country !== "" ? billingData.country : undefined,
              state: billingData.state !== "" ? billingData.state : undefined,
              postal_code:
                billingData.zipCode !== "" ? billingData.zipCode : undefined,
              line1:
                billingData.address !== "" ? billingData.address : undefined,
            },
          },
          metadata: {
            vat: billingData.cif,
          },
        },
      }
    );

    if (error) {
      // Display error.message in your UI.
    } else {
      if (setupIntent.status === "succeeded") {
        onComplete(setupIntent);
      }
    }

    setSaving(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={`${styles.backdrop} fixed z-1000 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div style={{minWidth: "500px"}} className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-5/12">
        <div className="w-full text-center text-2xl p-4 bg-green-600 text-white font-bold">
          {title}
        </div>
        <form>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex">
              <div className="w-full text-lg text-center my-4 font-bold flex-1">
                <div className="flex flex-wrap w-full">
                  <div className="w-full lg:w-full px-4">
                    <label
                      className="block uppercase text-xs font-bold mb-2 text-left text-red-600"
                      htmlFor="grid-name"
                    >
                      {t("profile.business-name")}{" "}
                      <span className={"text-red-600 italic"}>*</span>
                    </label>
                    <div className="flex items-center justify-end">
                      <input
                        id="grid-name"
                        type="text"
                        className={`${
                          nameError !== ""
                            ? "border-1 border-red-600"
                            : "border-0"
                        }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                        value={name}
                        onChange={(e) => {
                          setName(e.currentTarget.value);
                          setNameError("");
                          handleOnBillingDataChange();
                        }}
                      />
                    </div>
                  </div>
                </div>
                {nameError && (
                  <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
                    {nameError}
                  </div>
                )}
                <div className="flex flex-wrap w-full mt-5">
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-cif"
                    >
                      {t("profile.cif")}
                    </label>
                    <input
                      id="grid-cif"
                      type="text"
                      className={`border-0  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                      value={cif}
                      onChange={(e) => {
                        setCIF(e.currentTarget.value);
                        handleOnBillingDataChange();
                      }}
                    />
                  </div>
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-phone"
                    >
                      {t("profile.phone")}
                    </label>
                    <PhoneInput
                      inputClass="text-md"
                      localization={locale === "es" ? es : {}}
                      country="es"
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                        handleOnBillingDataChange();
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap w-full mt-5">
                  <div className="w-full lg:w-full px-4">
                    <label
                      className="block uppercase text-red-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-email"
                    >
                      {t("profile.email")}{" "}
                      <span className={"text-red-600 italic"}>*</span>
                    </label>
                    <div className="flex items-center justify-end">
                      <input
                        id="grid-email"
                        type="email"
                        className={`${
                          emailError !== ""
                            ? "border-1 border-red-600"
                            : "border-0"
                        }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.currentTarget.value.toLowerCase());
                          setEmailError("");
                          handleOnBillingDataChange();
                        }}
                      />
                    </div>
                  </div>
                </div>
                {emailError && (
                  <div className="w-6/12 lg:w-6/12 px-4 text-red-600 italic text-sm text-left">
                    {emailError}
                  </div>
                )}
                <div className="flex flex-wrap w-full mt-5">
                  <div className="w-full lg:w-full px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-address"
                    >
                      {t("profile.address")}
                    </label>
                    <div className="flex items-center justify-end">
                      <input
                        id="grid-address"
                        type="text"
                        className={`${
                          addressError !== ""
                            ? "border-1 border-red-600"
                            : "border-0"
                        }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.currentTarget.value);
                          setAddressError("");
                          handleOnBillingDataChange();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full mt-5">
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-country"
                    >
                      {t("profile.country")}
                    </label>
                    <CountrySelect
                      searchable={true}
                      selected={country}
                      onSelect={handleChangeLanguage}
                      showSelectedLabel
                      showOptionLabel
                    />
                  </div>
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-state"
                    >
                      {t("profile.state")}
                    </label>
                    <input
                      id="grid-state"
                      type="text"
                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                      value={state}
                      onChange={(e) => {
                        setState(e.currentTarget.value);
                        handleOnBillingDataChange();
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap w-full mt-5">
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                      htmlFor="grid-city"
                    >
                      {t("profile.city")}
                    </label>
                    <input
                      id="grid-city"
                      type="text"
                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                      value={city}
                      onChange={(e) => {
                        setCity(e.currentTarget.value);
                        handleOnBillingDataChange();
                      }}
                    />
                  </div>
                  <div className="w-6/12 lg:w-6/12 px-4">
                    <label
                      className="block uppercase text-xs font-bold mb-2 text-left text-red-600"
                      htmlFor="grid-zip"
                    >
                      {t("profile.zip-code")}{" "}
                      <span className={"text-red-600 italic"}>*</span>
                    </label>
                    <input
                      id="grid-zip"
                      type="text"
                      className={`border-0  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                      value={zipCode}
                      onChange={(e) => {
                        setZipCode(e.currentTarget.value);
                        handleOnBillingDataChange();
                      }}
                    />
                  </div>
                </div>
                <div className={"mt-5 px-4"}>
                  <label
                    className="block uppercase text-red-600 text-xs font-bold mb-2 text-left"
                    htmlFor="grid-zip"
                  >
                    {t("profile.card-number")}{" "}
                    <span className={"text-red-600 italic"}>*</span>
                  </label>
                  <div className="bg-white rounded my-4">
                    <CardElement
                      id="stripe-card-element"
                      className={`${
                        !cardValid ? "border-red-600" : "border-green-600"
                      } border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
                      options={{
                        hidePostalCode: true,
                      }}
                      onChange={handleOnChange}
                    />
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </form>
        <div className="w-full text-right p-4 bg-gray-200 text-white font-bold flex justify-end">
          <div className="text-left">
            <img src={stripeLogo} style={{ height: 40 }} />
          </div>
          <div className="flex-1 text-right">
            <DangerButton onClick={onClose} className={"mr-2"}>
              <span className="uppercase">{t("editor.cancel")}</span>
            </DangerButton>
            <PrimaryButton
              disabled={!cardValid || !dataValid}
              loading={saving}
              onClick={handlePay}
            >
              <span className="uppercase font-bold">{t("editor.acept")}</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
