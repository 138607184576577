import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const styles = {
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    borderColor: state.isFocused
      ? "rgb(5, 150, 105) !important"
      : "rgb(5, 150, 105)",
    boxShadow: state.isFocused
      ? "0 0 0 1px rgb(5, 150, 105) !important"
      : provided.boxShadow,
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: isSelected
      ? "rgb(4, 120, 87)"
      : isFocused
      ? "rgb(209, 250, 229)"
      : "white",
    ":active": {
      ...provided[":active"],
      backgroundColor: "rgba(209, 250, 229)",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 350,
  }),
};

export const PeriodDropDown = ({ onChange }) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      { value: "current-month", label: t("period.current-month") },
      { value: "previous-month", label: t("period.previous-month") },
      { value: "current-year", label: t("period.current-year") },
      { value: "previous-year", label: t("period.previous-year") },
      { value: "1-trimester", label: t("period.1-trimester") },
      { value: "2-trimester", label: t("period.2-trimester") },
      { value: "3-trimester", label: t("period.3-trimester") },
      { value: "4-trimester", label: t("period.4-trimester") },
    ],
    [t]
  );
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  return (
    <div className="w-1/4">
      <Select
        defaultValue={selectedOption}
        label="Single select"
        isSearchable={false}
        options={options}
        styles={styles}
        onChange={setSelectedOption}
      />
    </div>
  );
};
