import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import styles from "./SectionFeatures.module.scss";
import { gkb } from "../../services/gkb";
import { DispatchSetLoading } from "../../store/ui/actions";
import { useDispatch } from "react-redux";

export const SectionFeatures = () => {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [features, setFeatures] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        { name: "features", filter: '"group"=eq(feature)&"visible"=eq(true)' },
        { pageSize: 10, sort: '"order".ASC' }
      )
      .then((res) => {
        setFeatures(res.Data);
      })
      .catch()
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  return (
    <section
      className="py-12 px-4 text-center"
      style={{ backgroundColor: "white" }}
    >
      <h2 className="text-4xl my-10 leading-tight font-semibold font-heading">
        {t("index.features.title")}
      </h2>
      <p className="text-gray-400 mb-10">{t("index.features.subtitle")}</p>

      <div className="flex flex-wrap justify-center">
        {features?.map((f, i) => (
          <div key={i} className={"w-full md:w-4/12 flex justify-center"} >
            <div className={`${styles.card} ${f.ClassName}`} >
              <div className="flex flex-center">
                <img
                  alt="section"
                  src={f.Image}
                  style={{ maxHeight: 250, width: "100%" }}
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: f.Content[language] }} />
              {f.Button?.Text && (
                <SecondaryButton
                  onClick={() => {
                    router.push(f.Button.Url);
                  }}
                >
                  {f.Button.Text[language]}
                </SecondaryButton>
              )}
            </div>
          </div>
        ))}
      </div>
      <PrimaryButton
        onClick={() => {
          router.push("/products");
        }}
      >
        {t("index.features.button")}
      </PrimaryButton>
    </section>
  );
};
