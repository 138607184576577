import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { useDispatch } from "react-redux";
import { gkb } from "../../services/gkb";
import { DispatchSetLoading } from "../../store/ui/actions";

export const ResellerNetInfo = () => {
  const [net, setNet] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);

    gkb
      .GetResellerNet()
      .then((data: any) => {
        if (data) {
          setNet({ ...data });
        }
      })
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  const renderNode = ({ nodeDatum, foreignObjectProps }: any) => {
    let bg = "bg-white";
    if (nodeDatum.attributes?.Level === 1) {
      bg = "bg-green-200";
    }
    if (nodeDatum.attributes?.Level === 2) {
      bg = "bg-green-400";
    }
    if (nodeDatum.attributes?.Level === 3) {
      bg = "bg-green-700 text-white";
    }
    return (
      <g>
        <circle r={8} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <foreignObject {...foreignObjectProps}>
          <div className={`${bg} border border-green-600 rounded bg-white`}>
            <div className="flex w-full justify-between px-2">
              <h3 className="text-left">{nodeDatum.name}</h3>
              <h3 className="text-right  text-xs">
                #{nodeDatum.attributes?.ResellerID}
              </h3>
            </div>
            <h1 className="px-2 text-xs italic text-left">
              {nodeDatum.attributes?.Email || ""}
            </h1>
          </div>
        </foreignObject>
      </g>
    );
  };

  const nodeSize = { x: 200, y: 100 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -10,
    y: -22,
  };

  return (
    <div className="w-full rounded bg-white shadow-lg">
      <div id="treeWrapper" className="w-full" style={{ height: "600px" }}>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        {net?.name && (
          <Tree
            data={net}
            zoomable
            nodeSize={{ x: 300, y: 100 }}
            pathFunc="step"
            translate={{ x: 20, y: 280 }}
            enableLegacyTransitions={false}
            renderCustomNodeElement={(rd3tProps) =>
              renderNode({ ...rd3tProps, foreignObjectProps })
            }
          />
        )}
      </div>
    </div>
  );
};
