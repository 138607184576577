// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oqZ18Wl83BMAnLbCcgJKN{overflow:hidden}._29DAXpkwfUhnfP0uBGe7hu{text-shadow:1px 1px 1px #fff;z-index:40;line-height:600px;text-align:center;position:absolute;top:0;width:10%;font-size:3em;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#f5f5f5;left:0%}._1PkhUiyYDIX5JUbdNVKwPQ{text-shadow:1px 1px 1px #fff;z-index:40;line-height:600px;text-align:center;position:absolute;top:0;width:10%;font-size:3em;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#f5f5f5;left:90%}", "",{"version":3,"sources":["webpack://./src/components/Slider/Slider.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CAGF,yBACE,4BAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,SAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,aAAA,CACA,OAAA,CAIF,yBACE,4BAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,SAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,aAAA,CACA,QAAA","sourcesContent":[".slider {\n  overflow: hidden;\n}\n\n.arrowLeft {\n  text-shadow: 1px 1px 1px #fff;\n  z-index: 40;\n  line-height: 600px;\n  text-align: center;\n  position: absolute;\n  top: 0;\n  width: 10%;\n  font-size: 3em;\n  cursor: pointer;\n  user-select: none;\n  color: #f5f5f5;\n  left: 0%;\n\n}\n\n.arrowRight {\n  text-shadow: 1px 1px 1px #fff;\n  z-index: 40;\n  line-height: 600px;\n  text-align: center;\n  position: absolute;\n  top: 0;\n  width: 10%;\n  font-size: 3em;\n  cursor: pointer;\n  user-select: none;\n  color: #f5f5f5;\n  left: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "oqZ18Wl83BMAnLbCcgJKN",
	"arrowLeft": "_29DAXpkwfUhnfP0uBGe7hu",
	"arrowRight": "_1PkhUiyYDIX5JUbdNVKwPQ"
};
export default ___CSS_LOADER_EXPORT___;
