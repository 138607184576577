import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DangerButton } from "../Buttons/DangerButton";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import { PaymentDetails } from "./PaymentDetails";
import styles from "./SubscriptionDetails.module.scss";
import { gkb } from "../../services/gkb";
import { AddPaymentMethodModal } from "../Modals/AddPaymentMethodModal";
import { useSession } from "react-session-persist/lib";
import { notify } from "reapop";
import { useDispatch } from "react-redux";
import { DispatchSetLoading } from "../../store/ui/actions";
import ReactGA from "react-ga";

export const SubscriptionDetails = ({ subscription, onReload }) => {
  const { t, i18n } = useTranslation();
  const router = useHistory();
  const [details, setDetails] = useState({ ...subscription });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSession();
  const locale = i18n.language === "es" ? "es-ES" : i18n.language;

  useEffect(() => {
    setDetails(subscription);
  }, [subscription]);

  const beginDate = details.CurrentPeriodStart
    ? DateTime.fromISO(details.CurrentPeriodStart).toLocaleString(
        Object.assign(DateTime.DATE_FULL, { locale })
      )
    : "";
  const nextPayment = DateTime.fromISO(details.CurrentPeriodEnd).toLocaleString(
    Object.assign(DateTime.DATE_FULL, { locale })
  );
  const cancelationDate = DateTime.fromISO(
    details.CancelAt || details.EndedAt
  ).toLocaleString(Object.assign(DateTime.DATE_FULL, { locale }));

  let { Status } = details;
  if (Status === "active" && details.CancelAtPeriodEnd) {
    Status = "pending-cancel";
  }

  const handleCancelSubscription = async () => {
    setLoading2(true);
    try {
      const newSubs = await gkb.UpdateApiResource({
        name: "subscriptions",
        id: details.ID,
        body: { CancelAtPeriodEnd: true },
      });
      setDetails({
        ...details,
        Status: newSubs.Status,
        CancelAtPeriodEnd: newSubs.CancelAtPeriodEnd,
      });
      ReactGA.event({
        category: "subscription",
        action: `Subscription ${subscription.ID} canceled`,
      });
    } catch (err) {
    } finally {
      setLoading2(false);
    }
  };

  const handleReactivateSubscription = async () => {
    setLoading2(true);
    try {
      const newSubs = await gkb.UpdateApiResource({
        name: "subscriptions",
        id: details.ID,
        body: { CancelAtPeriodEnd: false },
      });
      setDetails({
        ...details,
        Status: newSubs.Status,
        CancelAtPeriodEnd: newSubs.CancelAtPeriodEnd,
      });
      ReactGA.event({
        category: "subscription",
        action: `Subscription ${subscription.ID} reactivated`,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading2(false);
    }
  };

  const handleChangePaymentMethod = () => {
    setShowModal(true);
  };

  const handleChangePaymentMethodComplete = async (intent: any) => {
    DispatchSetLoading(dispatch, true);
    setShowModal(false);
    const response = await gkb.AssignPaymentMethodToSubscription(
      subscription.ID,
      intent.payment_method
    );
    if (response?.status === 200) {
      dispatch(notify(t("subscription-method-add-successfully"), "success"));
    } else {
      dispatch(notify(t("subscription-method-add-error"), "error"));
    }
    ReactGA.event({
      category: "subscription",
      action: `Payment method change for subscription ${subscription.ID}`,
    });
    setTimeout(() => {
      DispatchSetLoading(dispatch, false);
      onReload && onReload();
    }, 200);
  };

  const renderStatus = (): any => {
    if (Status === "active") {
      return (
        <span className="bg-green-200 py-1 px-4 rounded">{t(Status)}</span>
      );
    }
    if (Status === "pending-cancel" || Status === "incomplete") {
      return (
        <span className="bg-yellow-200 py-1 px-4 rounded">{t(Status)}</span>
      );
    }
    if (Status === "canceled") {
      return <span className="bg-red-200 py-1 px-4 rounded">{t(Status)}</span>;
    }
    if (Status === "on-hold") {
      return (
        <span className="bg-yellow-200 py-1 px-4 rounded">{t(Status)}</span>
      );
    }
    if (Status === "trialing") {
      return <span className="bg-blue-200 py-1 px-4 rounded">{t(Status)}</span>;
    }

    return <span className="py-1 px-4 rounded">{t(Status)}</span>;
  };

  return (
    <>
      {showModal && (
        <AddPaymentMethodModal
          profile={user}
          show={showModal}
          title={
            subscription.DefaultPaymentMethod === ""
              ? t("add-payment-method")
              : t("change-payment-method")
          }
          onComplete={handleChangePaymentMethodComplete}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className="w-full bg-white shadow-lg rounded">
        <ul>
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
          >
            <div className="w-48 text-green-600 text-left font-bold">
              {t("number")}
            </div>
            <div className="flex-1 text-left italic">#{details.ID}</div>
          </li>
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
          >
            <div className="w-48 text-green-600 text-left font-bold">
              {t("status")}
            </div>
            <div className="flex-1 text-left">{renderStatus()}</div>
          </li>
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
          >
            <div className="w-48 text-green-600 text-left font-bold">
              {t("begin-date")}
            </div>
            <div className="flex-1 text-left">{beginDate}</div>
          </li>
          {Status === "active" && (
            <li
              className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
            >
              <div className="w-48 text-green-600 text-left font-bold">
                {t("next-payment")}
              </div>
              <div className="flex-1 text-left">{nextPayment}</div>
            </li>
          )}
          {Status === "pending-cancel" && (
            <li
              className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
            >
              <div className="w-48 text-green-600 text-left font-bold">
                {t("available-until")}
              </div>
              <div className="flex-1 text-left">{nextPayment}</div>
            </li>
          )}
          {Status === "canceled" && (
            <li
              className={`${styles.gktable} px-4 py-2 border-b flex justify-between`}
            >
              <div className="w-48 text-green-600 text-left font-bold">
                {t("cancel-date")}
              </div>
              <div className="flex-1 text-left">{cancelationDate}</div>
            </li>
          )}
        </ul>
      </div>
      <div
        className={`${styles.gktable} mt-4 flex justify-end align-items flex`}
      >
        <PrimaryButton
          onClick={handleChangePaymentMethod}
          loading={loading}
          className={"mr-2"}
        >
          {t(
            subscription.DefaultPaymentMethod === ""
              ? "add-payment-method"
              : "change-payment-method"
          )}
        </PrimaryButton>

        {Status === "active" && (
          <DangerButton
            onClick={handleCancelSubscription}
            loading={loading2}
            className="ml-2"
          >
            {t("cancel-subscription")}
          </DangerButton>
        )}
        {Status === "pending-cancel" && (
          <PrimaryButton
            onClick={handleReactivateSubscription}
            loading={loading2}
          >
            {t("reactivate")}
          </PrimaryButton>
        )}
      </div>

      {details.LatestInvoice && (
        <div className="mt-6">
          <div className="text-left font-bold text-2xl mx-2">
            {t("last-payment")}
          </div>
          <PaymentDetails invoice={details.LatestInvoice} onReload={onReload} />

          <div className="mt-6">
            <SecondaryButton
              onClick={() => {
                router.push({
                  pathname: `/my-account/subscriptions/${details.ID}/payments`,
                });
              }}
            >
              {t("view-payments")}
            </SecondaryButton>
          </div>
        </div>
      )}
    </>
  );
};
