import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import styles from "./SectionHowItWorks.module.scss";
import { gkb } from "../../services/gkb";
import { useDispatch } from "react-redux";
import { DispatchSetLoading } from "../../store/ui/actions";
import { useTheme } from "../../store/ui/selector";

export const SectionHowItWorks = () => {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [steps, setSteps] = useState<any>(null);
  const dispatch = useDispatch();
  const { theme } = useTheme();

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .ListApiResource(
        { name: "features", filter: '"group"=eq(how-works)' },
        { pageSize: 10, sort: '"order".ASC' }
      )
      .then((res) => {
        setSteps(res.Data);
      })
      .catch()
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  return (
    <section
      className="py-12 px-4 text-center"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading">
        {t("index.how-works.title")}
      </h2>
      <p className="text-gray-400">{t("index.how-works.subtitle")}</p>

      <div className="flex flex-wrap justify-center my-10">
        {steps?.map((f, i) => (
          <div key={i} className={`${styles.card} ${f.ClassName}`}
               style={{ ...theme?.Content?.styles?.features }}>
            <div className="flex flex-center justify-center">
              <img src={f.Image} style={{ maxHeight: 150, height: 150 }} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: f.Content[language] }} />
          </div>
        ))}
      </div>
      <PrimaryButton
        onClick={() => {
          router.push("/products");
        }}
      >
        {t("index.how-works.button")}
      </PrimaryButton>
    </section>
  );
};
