import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { gkb } from "../../../services/gkb";
import { SubscriptionDetails } from "../../../components/Subscription/SubscriptionDetails";
import { DispatchSetLoading } from "../../../store/ui/actions";

const ViewSubscription = () => {
  const router = useHistory();
  const { state } = useLocation();
  const [subscription, setSubscription] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    loadData().then().catch();
    const itv = setInterval(async () => {
      await loadData(false);
    }, 5000);
    return () => {
      clearInterval(itv);
    };
  }, []);

  const loadData = async (showLoading = true) => {
    showLoading && DispatchSetLoading(dispatch, true);
    gkb
      .GetApiResource("subscriptions", parseInt(id, 10))
      .then((subs) => {
        setSubscription(subs);
      })
      .catch((err) => {})
      .finally(() => {
        showLoading && DispatchSetLoading(dispatch, false);
      });
  };

  const handleOnReload = async () => {
    await loadData();
  };

  return (
    <section className="px-4 text-center pb-40 w-full">
      <ul className="text-left pb-4 flex text-green-600 text-lg">
        <li className="underline hover:opacity-50">
          <Link to="/my-account/subscriptions">{t("subscriptions.title")}</Link>
        </li>
        <li>
          <span className="mx-1">/</span>
        </li>
        <li className="text-gray-600">
          <span>
            {t("subscriptions.subscription")} #{id}
          </span>
        </li>
      </ul>
      {subscription && (
        <SubscriptionDetails
          subscription={subscription}
          onReload={handleOnReload}
        />
      )}
    </section>
  );
};

export default ViewSubscription;
