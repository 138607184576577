// React component

import React from 'react';
import { useTheme } from '../../store/ui/selector';

type ProductsBannerProps = {
  banner: string;
}

const ProductsBanner = ({banner}: ProductsBannerProps) => {
  const { theme } = useTheme();
  const showBanner = banner && banner !== "store.products.banner";

  if(!showBanner) {
    return null;
  }

  return (
    <div className="mt-4"
      style={{
      background: theme?.Content?.styles?.priceBanner?.backgroundColor || '',
      color: theme?.Content?.styles?.priceBanner?.textColor || '',
      padding: "20px",
      borderRadius: "10px"
    }}>
      <div dangerouslySetInnerHTML={{ __html: banner }}>
      </div>
    </div>
  );
}

export default ProductsBanner;