import React from "react";

type Props = {
  image: string;
  style?: string;
};

export const Avatar = ({ image, style = "square" }: Props) => {
  return (
    <img
      className={`Avatar  border-green-600 ${
        style === "circle" ? "rounded-full border-2" : "rounded"
      }`}
      alt="avatar"
      src={image}
      width="60"
      height="60"
    />
  );
};
