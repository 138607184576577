import React from "react";

// components
import { SectionFeatures } from "../components/Sections/SectionFeatures";
import { SectionSlider } from "../components/Sections/Section1";
import { SectionHowItWorks } from "../components/Sections/SectionHowItWorks";
import { SectionCTA2 } from "../components/Sections/SectionCTA2";
import { Section } from "../components/Sections/Section";

function HomePage() {
  return (
    <>
      <SectionSlider />
      <Section id={1} />
      <Section id={4} />
      <SectionFeatures />
      <SectionHowItWorks />
      <SectionCTA2 />
    </>
  );
}

export default HomePage;
