import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useSession } from "react-session-persist";
import { ProfileData } from "../../components/Profile/Profile";
import MyAccountNav from "../../components/Navbars/MyAccountNav";
import SubscriptionsPage from "./subscriptions";
import ViewSubscription from "./subscriptions/details";
import PaymentsPage from "./payments";
import ViewPayment from "./payments/details";
import PaymentsSubscriptionPage from "./subscriptions/payments/payments";
import ViewSubscriptionPayment from "./subscriptions/payments/details";
import ResellersPage from "./reseller";
import ResellersOnBoardingPage from "./reseller/ResellersOnboarding";
import ResellersNetworkPage from "./reseller/ResellersNetwork";
import TransfersPage from "./reseller/transfers";
import CardsPage from "./cards";
import ViewCompany from "./cards/companyView";
import SubordinatesPage from "./cards/subordinates";

const MyAccountPage = () => {
  const router = useHistory();
  const [profile, setProfile] = useState<any>();
  const { authenticated, session, saveUser, saveSession, user } = useSession();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = parseInt(queryParams.get('tab') ?? "0");

  const { path, url } = useRouteMatch();

  useEffect(() => {
    if (!authenticated) {
      router.replace("/login");
      return;
    }
    if (user) {
      setProfile(user)
    }
  }, [user]);

  return (
    <div className="w-full bg-gray-100">
      <div className="flex px-20 text-center pt-10 pb-20  w-10/12 mx-auto">
        <MyAccountNav />
        <section className="px-4 text-center w-full">
          <Switch>
            <Route
              path={path}
              exact
              children={<ProfileData profile={profile} tab={tab}/>}
            />
            <Route
              path={`${path}/subscriptions`}
              exact
              children={<SubscriptionsPage />}
            />
            <Route
              path={`${path}/subscriptions/:id/payments`}
              exact
              children={<PaymentsSubscriptionPage />}
            />
            <Route
              path={`${path}/subscriptions/:id/payments/:pid`}
              exact
              children={<ViewSubscriptionPayment />}
            />
            <Route
              path={`${path}/subscriptions/:id`}
              exact
              children={<ViewSubscription />}
            />
            <Route
              path={`${path}/payments`}
              exact
              children={<PaymentsPage />}
            />
            <Route
              path={`${path}/payments/:id`}
              exact
              children={<ViewPayment />}
            />
            <Route
              path={`${path}/reseller/onboarding`}
              exact
              children={<ResellersOnBoardingPage />}
            />
            <Route
              path={`${path}/reseller/network`}
              exact
              children={<ResellersNetworkPage />}
            />
            <Route
              path={`${path}/reseller/transactions`}
              exact
              children={<TransfersPage />}
            />
            <Route
              path={`${path}/reseller`}
              exact
              children={<ResellersPage />}
            />
            <Route path={`${path}/cards`} exact children={<CardsPage />} />
            <Route
              path={`${path}/cards/:id/subordinates`}
              exact
              children={<SubordinatesPage />}
            />
            <Route
              path={`${path}/cards/company/:id`}
              exact
              children={<ViewCompany />}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  session: state.session,
});
export default connect(mapStateToProps, null)(MyAccountPage);
