import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { reducer as notificationsReducer } from "reapop";
import cartReducer, { CartInitialState, CartState } from "./cart/reducer";
import uiReducer, { UIState, UIInitialState } from "./ui/reducer";
import productsReducer, {
  ProductsInitialState,
  ProductsState,
} from "./products/reducer";

export interface StoreState {
  cart: CartState;
  ui: UIState;
  products: ProductsState;
  notifications: any;
}

const initialState: StoreState = {
  cart: CartInitialState,
  ui: UIInitialState,
  products: ProductsInitialState,
  notifications: [],
};

const persistConfig = {
  key: "cart",
  blacklist: ["ui"],
  storage,
};

const reducers = combineReducers({
  cart: cartReducer,
  ui: uiReducer,
  products: productsReducer,
  notifications: notificationsReducer(),
});
const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware())
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
