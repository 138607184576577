import React, { useEffect, useState } from "react";

// components
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSession } from "react-session-persist/lib";
import { Products } from "../models/prices";
import { PrimaryButton } from "../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../components/Buttons/SecondaryButton";
import { gkb } from "../services/gkb";
import { StoreState } from "../store";
import { DispatchSetLoading } from "../store/ui/actions";
import ReactGA from "react-ga";

export default function SuccessPage() {
  const { t } = useTranslation();
  const { authenticated } = useSession();
  const [subscription, setSubscription] = useState(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const router = useHistory();
  const products = useSelector(
    (state: StoreState) => state?.products?.products
  );

  useEffect(() => {
    const { subscription } = state as any;
    ReactGA.event({
      category: "cart",
      action: `Compra completada. Subscripción ${subscription.ID}`,
    });
    DispatchSetLoading(dispatch, true);
    gkb
      .GetApiResource("subscriptions", subscription)
      .then((sub) => setSubscription(sub))
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  if (!authenticated) {
    router.replace("/");
    return null;
  }

  const renderItem = (item: any) => {
    const product = products.find((pr) => pr.StripeID === item.Price.Product);

    if (item.Sku === "card:professional") {
      const productImage = product.ImageUrl;
      return (
        <>
          <div className="p-2 flex align-center justify-between">
            <div className="rounded bg-white border">
              <img src={productImage} style={{ height: 150, width: 200 }} />
            </div>
            <div className="text-left flex-1 flex flex-col">
              <div className="text-green-800 text-2xl px-10">
                {t(`products.pricing.professional.title`)}
                {item.Quantity > 1 && <span> x{item.Quantity}</span>}
              </div>
              <div className="px-10 my-2">
                {t(`products.pricing.professional.resume`)}
              </div>
              <div className="px-10 flex-1 flex items-end" />
            </div>
          </div>
          <div className="my-10 flex justify-center">
            <div className="mx-2">
              <SecondaryButton
                onClick={() => {
                  router.push(`/my-account/subscriptions/${item.Subscription}`);
                }}
              >
                {t("success.view_subscription")}
              </SecondaryButton>
            </div>
            <div className="mx-2">
              <PrimaryButton
                onClick={() => {
                  router.push(`/my-account/cards`);
                }}
              >
                {t("success.goto_cards")}
              </PrimaryButton>
            </div>
          </div>
        </>
      );
    }

    if (item.Sku === "card:corporate") {
      const productImage = product.ImageUrl;
      return (
        <>
          <div className="p-2 flex align-center justify-between">
            <div className="rounded bg-white border">
              <img
                alt="corporate"
                src={productImage}
                style={{ height: 150, width: 200 }}
              />
            </div>
            <div className="text-left flex-1 flex flex-col">
              <div className="text-green-800 text-2xl px-10">
                {t(`products.pricing.corporate.title`)}
              </div>
              <div className="px-10 my-2">
                {t(`products.pricing.corporate.resume`)}
              </div>
              <div className="px-10 pb-4 flex-1 flex items-end">
                {item.Quantity > 1 && (
                  <p className="text-green-800 font-bold">
                    {t("success.additional")}: {item.Quantity - 1}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="my-10 flex justify-center">
            <div className="mx-2">
              <SecondaryButton
                onClick={() => {
                  router.push(`/my-account/subscriptions/${item.Subscription}`);
                }}
              >
                {t("success.view_subscription")}
              </SecondaryButton>
            </div>
            <div className="mx-2">
              <PrimaryButton
                onClick={() => {
                  const company = subscription.Resources.filter(
                    (r: any) => r.ContentType === "company"
                  );
                  if (company.length > 0) {
                    router.push(`/my-account/cards/company/${company[0].ID}`);
                  }
                }}
              >
                {t("success.configure_corporate")}
              </PrimaryButton>
            </div>
          </div>
        </>
      );
    }
    if (item.Sku === "card:fidelium") {
      const productImage = product.ImageUrl;
      return (
        <>
          <div className="p-2 flex align-center justify-between">
            <div className="rounded bg-white border">
              <img
                src={productImage}
                alt="fidelium"
                style={{ height: 150, width: 200 }}
              />
            </div>
            <div className="text-left flex-1 flex flex-col">
              <div className="text-green-800 text-2xl px-10">
                {t(`products.pricing.fidelium.title`)}
              </div>
              <div className="px-10 my-2">
                {t(`products.pricing.fidelium.resume`)}
              </div>
              <div className="px-10 pb-4 flex-1 flex items-end">
                {item.Quantity > 1 && (
                  <p className="text-green-800 font-bold">
                    {t("success.additional")}: {item.Quantity - 1}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="my-10 flex justify-center">
            <div className="mx-2">
              <SecondaryButton
                onClick={() => {
                  router.push(`/my-account/subscriptions/${item.Subscription}`);
                }}
              >
                {t("success.view_subscription")}
              </SecondaryButton>
            </div>
            <div className="mx-2">
              <PrimaryButton
                onClick={() => {
                  const company = subscription.Resources.filter(
                    (r: any) => r.ContentType === "company"
                  );
                  if (company.length > 0) {
                    router.push(`/my-account/cards/company/${company[0].ID}`);
                  }
                }}
              >
                {t("success.configure_fidelium")}
              </PrimaryButton>
            </div>
          </div>
        </>
      );
    }

    if (item.Sku === "reseller") {
      const productImage = product.ImageUrl;
      return (
        <>
          <div className="flex justify-center flex-col">
            <h2 className="text-2xl  text-green-600 mt-4">
              ¡Gracias por hacerte distribuidor de GreenKard!
            </h2>
            <p className="text-xl  mt-4">
              Antes de poder distribuir tarjetas, es necesario que completes el
              proceso de registro de distribuidor
            </p>
          </div>
          <div className="my-10 flex justify-center">
            <div className="mx-2">
              <PrimaryButton
                onClick={() => {
                  router.push("/my-account/reseller/onboarding");
                }}
              >
                {t("success.complete_reseller")}
              </PrimaryButton>
            </div>
          </div>
        </>
      );
    }

    if (item.Sku === "card:subordinate") {
      const productImage = product.ImageUrl;
      return (
        <>
          <div className="p-2 flex align-center justify-between">
            <div className="rounded bg-white border">
              <img src={productImage} style={{ height: 150, width: 200 }} />
            </div>
            <div className="text-left flex-1 flex flex-col">
              <div className="text-green-800 text-2xl px-10">
                {t(`products.pricing.subordinate.title`)}
                <span> x{item.Quantity}</span>
              </div>
              <div className="px-10 my-2">
                {t(`products.pricing.subordinate.resume`)}
              </div>
              <div className="px-10 flex-1 flex items-end" />
            </div>
          </div>
          <div className="my-10 flex justify-center">
            <div className="mx-2">
              <SecondaryButton
                onClick={() => {
                  router.push(`/my-account/subscriptions/${item.Subscription}`);
                }}
              >
                {t("success.view_subscription")}
              </SecondaryButton>
            </div>
            <div className="mx-2">
              <PrimaryButton
                onClick={() => {
                  router.push(`/my-account/cards`);
                }}
              >
                {t("success.goto_cards")}
              </PrimaryButton>
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <section className="px-4 text-center pt-5 pb-40 bg-gray-100">
        <div className="w-full max-w-6xl mx-auto">
          <h2
            className="text-green-800 my-20  text-4xl text-center"
            data-cy="success-title"
          >
            {t("success.title")}
          </h2>
          <div className="w-full flex justify-center">
            <div>
              {(subscription as any)?.Items &&
                (subscription as any)?.Items.map((item) => renderItem(item))}
            </div>
          </div>
          <div className="mt-10" />
        </div>
      </section>
    </>
  );
}
