import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import esCountries from "../../../models/countries.json";

import styles from "../../Profile/Profile.module.scss";
import { Verification } from "../../../models/reseller";
import { SecondaryButton } from "../../Buttons/SecondaryButton";

export const OnBoardingStep3 = ({ onSubmit, nextStep, previousStep }: any) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<Verification>({
    Vat: "1234567890",
    CommercialName: "Tenant1.com",
    Phone: "+9112345678",
    AddressLine1: "La calle, 9",
    Country: "ES",
    AddressState: "Madrid",
    AddressCity: "Madrid",
    AddressPostalCode: "28042",
  });

  const [vatError, setVatError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [commercialNameError, setCommercialNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const validateForm = () => {
    let errors = false;
    if (state.Vat === "") {
      if (vatError === "") {
        setVatError(t("profile.error-vat-empty"));
      }
      errors = true;
    }
    if (state.CommercialName === "") {
      if (commercialNameError === "") {
        setCommercialNameError(t("profile.error-lastname-empty"));
      }
      errors = true;
    }
    if (state.Phone === "") {
      if (phoneError === "") {
        setPhoneError(t("profile.error-phone-required"));
      }
      errors = true;
    }
    if (state.AddressLine1 === "") {
      if (addressError === "") {
        setAddressError(t("profile.error-address-required"));
      }
      errors = true;
    }
    if (state.Country === "") {
      if (countryError === "") {
        setCountryError(t("profile.error-country-required"));
      }
      errors = true;
    }
    if (state.AddressState === "") {
      if (stateError === "") {
        setStateError(t("profile.error-state-required"));
      }
      errors = true;
    }
    if (state.AddressCity === "") {
      if (cityError === "") {
        setCityError(t("profile.error-city-required"));
      }
      errors = true;
    }
    if (state.AddressPostalCode === "") {
      if (zipCodeError === "") {
        setZipCodeError(t("profile.error-postalcode-required"));
      }
      errors = true;
    }

    return errors;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const errors = validateForm();

    if (!errors) {
      setLoading(true);
      let phoneAux = state.Phone || "";
      if (!phoneAux.startsWith("+")) {
        phoneAux = `+${state.Phone}`;
      }

      await onSubmit(2, { ...state, Phone: phoneAux });
      nextStep();
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChangeLanguage = (code) => {
    setState({ ...state, Country: code });
    setCountryError("");
  };

  const locale = i18n.language?.toLowerCase() ?? "";
  return (
    <div className="w-full px-4 lg:px-10 py-4 justify-center">
      <form>
        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-vat"
            >
              {t("profile.vat")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-vat"
                type="text"
                className={`${
                  vatError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={state.Vat}
                onChange={(e) => {
                  setState({ ...state, Vat: e.currentTarget.value });
                  setVatError("");
                }}
              />
            </div>
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-cname"
            >
              {t("profile.business-name")}
            </label>
            <input
              id="grid-cname"
              type="text"
              className={`${
                addressError !== "" ? "border-1 border-red-600" : "border-0"
              } px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={state.CommercialName}
              onChange={(e) => {
                setState({ ...state, CommercialName: e.currentTarget.value });
                setCommercialNameError("");
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-phone"
            >
              {t("profile.phone")}
            </label>

            <PhoneInput
              inputClass="text-md"
              containerClass={`${
                phoneError !== ""
                  ? "border border-red-600 bg-red-200"
                  : "border-0 bg-red-200"
              } rounded`}
              localization={locale === "es" ? es : {}}
              country="es"
              value={state.Phone}
              onChange={(value) => {
                setState({ ...state, Phone: value });
                setPhoneError("");
              }}
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-phone"
            >
              {t("profile.country")}
            </label>
            <ReactFlagsSelect
              selectButtonClassName={`${styles.countrySelect} ${
                countryError !== ""
                  ? "border-1 border-red-600 bg-red-200"
                  : "border-0"
              }`}
              className={`${styles.countryFlags}`}
              selected={state.Country}
              onSelect={handleChangeLanguage}
              searchable
              showSelectedLabel
              showOptionLabel
              customLabels={locale === "es" ? esCountries : {}}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-address"
            >
              {t("profile.address")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-address"
                type="text"
                className={`${
                  addressError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={state.AddressLine1}
                onChange={(e) => {
                  setState({ ...state, AddressLine1: e.currentTarget.value });
                  setAddressError("");
                }}
              />
            </div>
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-state"
            >
              {t("profile.state")}
            </label>
            <input
              id="grid-state"
              type="text"
              className={`${
                stateError !== "" ? "border border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={state.AddressState}
              onChange={(e) => {
                setState({ ...state, AddressState: e.currentTarget.value });
                setStateError("");
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-5">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-address"
            >
              {t("profile.city")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-city"
                type="text"
                className={`${
                  cityError !== "" ? "border border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                value={state.AddressCity}
                onChange={(e) => {
                  setState({ ...state, AddressCity: e.currentTarget.value });
                  setCityError("");
                }}
              />
            </div>
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-zip"
            >
              {t("profile.zip-code")}
            </label>
            <input
              id="grid-zip"
              type="text"
              className={`${
                zipCodeError !== "" ? "border border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              value={state.AddressPostalCode}
              onChange={(e) => {
                setState({
                  ...state,
                  AddressPostalCode: e.currentTarget.value,
                });
                setZipCodeError("");
              }}
            />
          </div>
        </div>

        <div className="w-full flex justify-end mt-12">
          {!loading && (
            <SecondaryButton onClick={previousStep} className="mr-2">
              {t("back")}
            </SecondaryButton>
          )}

          <PrimaryButton
            onClick={handleSubmit}
            loading={loading}
            disabled={validateForm()}
          >
            {t("next")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
