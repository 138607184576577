import React from "react";
import { Slider } from "../Slider/Slider";
import styles from "./Section1.module.scss";

export const SectionSlider = () => {
  return (
    <section
      className={`${styles.section} header relative pt-16 items-center flex h-screen max-h-600-px`}
    >
      <Slider></Slider>
    </section>
  );
};
