/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSession } from "react-session-persist/lib";
import StepWizard from "react-step-wizard";
import { useTranslation } from "react-i18next";
import { gkb } from "../../../services/gkb";
import { DispatchSetLoading } from "../../../store/ui/actions";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { Verification } from "../../../models/reseller";
import { OnBoardingStep1 } from "../../../components/Reseller/onboarding/OnBoarding1";
import { OnBoardingStep2 } from "../../../components/Reseller/onboarding/OnBoarding2";
import { OnBoardingStep3 } from "../../../components/Reseller/onboarding/OnBoarding3";
import { OnBoardingStep4 } from "../../../components/Reseller/onboarding/OnBoarding4";
import { OnBoardingStep5 } from "../../../components/Reseller/onboarding/OnBoarding5";
import { OnBoardingStep0 } from "../../../components/Reseller/onboarding/OnBoarding0";
import ReactGA from "react-ga";

export default function ResellersOnBoardingPage() {
  const router = useHistory();
  const { user } = useSession();
  const dispatch = useDispatch();
  const [account, setAccount] = useState<any>(null);
  const { t } = useTranslation();
  const [state, setState] = useState<Verification>({});

  useEffect(() => {
    DispatchSetLoading(dispatch, true);
    gkb
      .GetResellerAccount()
      .then((acct: any) => {
        if (acct?.Reseller) {
          if (
            acct?.Reseller.VerificationStatus === "activated" ||
            acct?.Reseller.VerificationStatus === "pending"
          ) {
            router.replace("/my-account/reseller");
          }
        } else {
          router.replace("/resellers");
        }
      })
      .catch(() => {})
      .finally(() => {
        DispatchSetLoading(dispatch, false);
      });
  }, []);

  const handleOnSubmit = async (step: number, newState: any) => {
    let aux = { ...state, ...newState };
    setState(aux);

    if (step === 4) {
      const DayOfBirth = aux.DayOfBirth.split("/");
      const [day, month, year] = DayOfBirth;
      aux = {
        ...aux,
        DayOfBirth: {
          day: parseInt(day, 10),
          month: parseInt(month, 10),
          year: parseInt(year, 10),
        },
      };
      4;

      const acct = await gkb.CreateResellerAccount(aux);
      ReactGA.event({
        category: "reseller",
        action: `OnBoarding completed for reseller ${acct.ID}`,
      });
      if (acct) {
        router.replace("/my-account/reseller");
      }
    }
  };

  return (
    <section className="px-4 text-center w-full" style={{ minHeight: 650 }}>
      <ul className="text-left pb-4 text-green-600 text-xl">
        <li className="text-gray-600">
          <span>Onboarding</span>
        </li>
      </ul>{" "}
      <div className="w-full rounded p-4 shadow bg-gray-200">
        <StepWizard transitions={{}}>
          <OnBoardingStep0 />
          <OnBoardingStep1 onSubmit={handleOnSubmit} />
          <OnBoardingStep2 onSubmit={handleOnSubmit} />
          <OnBoardingStep3 onSubmit={handleOnSubmit} />
          <OnBoardingStep4 onSubmit={handleOnSubmit} />
          <OnBoardingStep5 onSubmit={handleOnSubmit} />
        </StepWizard>
      </div>
    </section>
  );
}
