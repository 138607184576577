export function getBrowserCountry(): string {
  const lng = navigator.language.split("-");
  if (lng[0] === "") {
    return "";
  }

  if (lng.length == 1) {
    return lng[0];
  }

  if (lng.length > 1) {
    return lng[1];
  }
}
