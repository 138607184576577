/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { gkb } from "../services/gkb";
import { notify } from "reapop";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";

export default function ActivatePage() {
  const { t } = useTranslation();
  const router = useHistory();
  const dispatch = useDispatch();
  const { code } = useParams<{ code: string }>();

  useEffect(() => {
    gkb
      .VerifyAccount(code)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(notify(t("activateaccount.success"), "success"));
          ReactGA.event({
            category: "login",
            action: `Account activated ${response.data?.data?.ID ?? 0}`,
          });

          router.push("/welcome");
        } else {
          dispatch(notify(t("activateaccount.error"), "error"));
          router.push("/404");
        }
      })
      .catch((err) => {
        dispatch(notify(t("activateaccount.error"), "error"));
      })
      .finally(() => {});
  }, []);

  return null;
}
