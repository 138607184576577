import React from "react";
import { useTheme } from "../../store/ui/selector";
import styles from "./Buttons.module.scss";

type ButtonsProperties = {
  children: any;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  id?: string;
  className?: string;
};

export const SecondaryButton = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  full = false,
  id = "",
  className = "",
}: ButtonsProperties) => {
  const { theme, defaultTheme } = useTheme();

  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  if (loading) {
    return (
      <button
        id={id}
        style={{ color: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor, borderColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
        className={`${styles.secondaryButton} ${styles.disabled} opacity-50  text-green-700 text font-bold uppercase px-4 py-2 rounded shadow  outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type="button"
      >
        <i className="fas fa-spinner animate-spin" />
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        id={id}
        style={{ color: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor, borderColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
        className={`${styles.secondaryButton} ${styles.disabled} ${
          full ? "w-full" : ""
        } bg-gray-400  text font-bold uppercase px-4 py-2 rounded shadow outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <button
      id={id}
      style={{ color: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor, borderColor: theme?.Content?.styles?.primaryColor || defaultTheme?.Content?.styles?.primaryColor }}
      className={`${styles.secondaryButton} ${
        full ? "w-full" : ""
      }   text-green-700 active:bg-gray-100 hover:bg-gray-100 text font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150 ${className}`}
      type="button"
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};
