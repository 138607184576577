/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import welcome1 from "../assets/img/welcome1.svg";
import { useTheme } from "../store/ui/selector";

export default function WelcomePage() {
  const { t } = useTranslation();
  const {theme} = useTheme();

  return (
    <section className="pt-12 pb-40 px-4 text-center">
      <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10"
          style={{color: theme?.Content?.styles?.primaryColor}}>
      {t("welcome.title")}
      </h2>
      <div className="w-full flex justify-center">
        <img alt="welcome" src={welcome1} height={400} width={400} />
      </div>
      <p className="text-gray-400 text-xl my-10">{t("welcome.thanks")}</p>
      <p className="text-gray-400 text-xl my-10">{t("welcome.message1")}</p>
      <div className="w-full lg:w-6/12 py-4 px-10 mx-auto mt-10 bg-gray-100 shadow-xl">
        <p className="text-gray-400 text-xl my-10">
          <Link to="/login">
            <span className="cursor-pointer underline text-green-600 hover:text-green-400"
                  style={{color: theme?.Content?.styles?.primaryColor}}>
              {t("welcome.click-here")}
            </span>
          </Link>{" "}
          {t("welcome.to-login")}
        </p>
      </div>
    </section>
  );
}
