import React, { useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { useDispatch } from "react-redux";
import { GKBilling } from "gkshared";
import { Link } from "react-router-dom";
import { useSession } from "react-session-persist/lib";
import { Avatar } from "../Avatar/Avatar";
import { DispatchSetLoading } from "../../store/ui/actions";

const gkb = new GKBilling((process.env.APPLICATION_ID as string) || "");

const IndexDropdownMobile = ({ t }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const dispatch = useDispatch();
  const { user, removeSession } = useSession();
  const btnDropdownRef = React.createRef<any>();
  const popoverDropdownRef = React.createRef<any>();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleClickOutside = () => {
    closeDropdownPopover();
  };

  const handleSignout = async (e: any) => {
    e.preventDefault();
    DispatchSetLoading(dispatch, true);
    await removeSession();
    await gkb.Logout();
    DispatchSetLoading(dispatch, false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={"overflow-ellipsis overflow-hidden w-full"}>
      <div
        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer hover:opacity-80 w-full justify-center"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          if (dropdownPopoverShow) {
            closeDropdownPopover();
          } else {
            openDropdownPopover();
          }
        }}
      >
        {user && (
          <Avatar image={(user as any)?.ImageUrl || ""} style="circle" />
        )}
        <div className="text-sm ml-2">
          <p>{(user as any)?.FirstName}</p>
        </div>
      </div>
      <div
        ref={popoverDropdownRef}
        className={`bg-white text-base z-50 float-left list-none text-left shadow-2xl border-t border-b w-full`}
      >
        <div className="flex w-full justify-around">
          <div className="flex-1  text-center border-r">
            <Link to="/my-account">
              <span className="py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-green-100">
                <span className="">
                  <i className="fal fa-user mr-2 w-5" />
                  {t("navbar.profile")}
                </span>
              </span>
            </Link>
          </div>
          <div className="flex-1 text-center">
            <Link to="/my-account/cards">
              <span className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-green-100">
                <span className="">
                  <i className="fal fa-id-card mr-2 w-5" />
                  {t("navbar.cards")}
                </span>
              </span>
            </Link>
          </div>
        </div>
        <div className="flex w-full justify-around">
          <div className="flex-1  text-center border-r">
            <Link to="/my-account/subscriptions">
              <span className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-green-100">
                <span className="">
                  <i className="fal fa-file-contract mr-2 w-5" />
                  {t("navbar.subscriptions")}
                </span>
              </span>
            </Link>
          </div>
          <div className="flex-1  text-center">
            <Link to="/my-account/payments">
              <span className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-green-100">
                <span className="">
                  <i className="fal fa-credit-card mr-2 w-5" />
                  {t("navbar.payments")}
                </span>
              </span>
            </Link>
          </div>
        </div>
        <div className="flex w-full justify-around">
          {/*<div className="flex-1 text-center border-r">*/}
          {/*  <Link to="/my-account/reseller">*/}
          {/*    <span className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-green-100">*/}
          {/*      <span className="">*/}
          {/*        <i className="fal fa-suitcase mr-2 w-5" />*/}
          {/*        {t("navbar.reseller")}*/}
          {/*      </span>*/}
          {/*    </span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className="flex-1  text-center">
            <div className="h-0 border border-solid border-blueGray-100 " />
            <span
              className="py-4 px-4 font-normal block whitespace-nowrap bg-black text-blueGray-700  hover:opacity-60 cursor-pointer"
              onClick={handleSignout}
            >
              <span className="text-white">
                <i className="fal fa-sign-out-alt mr-4 transform rotate-180" />
                {t("navbar.logout")}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexDropdownMobile;
