import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BillingDataComponent } from "./BillingData";
import { PersonalData } from "./PersonalData";
import { useHistory } from "react-router-dom";
import { useTheme } from "../../store/ui/selector";

export const ProfileData = ({ profile, tab }: any) => {
  const { t } = useTranslation();
  const [tabSelected, selectTab] = useState(tab <= 1 ? tab : 0);
  const selectedClass = "text-green-600 border-green-600 font-bold";
  const [currentProfile, setCurrentProfile] = useState(profile);
  const history = useHistory();
  const { theme } = useTheme();

  useEffect(() => {
    history.replace({
      search: tabSelected === 1 ? `?tab=${tabSelected}` : ""
    });
  }, [tabSelected, history]);

  useEffect(() => {
    setCurrentProfile(profile);
  }, [profile]);

  if (!currentProfile) {
    return null;
  }

  return (
    <section className="px-4 text-center pb-40 w-full lg:w-full">
      <ul className="text-left pb-4 text-green-600 text-xl"
          style={{ color: theme?.Content?.styles?.primaryColor }}>
        <li>
          <span>{t("my-account.profile.title")}</span>
        </li>
      </ul>
      <div className="border rounded shadow-lg">
        <>
          <ul className="w-full flex justify-center py-4">
            <li
              className={`text-lg cursor-pointer flex flex-1 justify-center border-b ${
                tabSelected === 0 ? selectedClass : ""
              }`}
              onClick={() => {
                selectTab(0);
              }}
              style={{
                color: tabSelected === 0 ? theme?.Content?.styles?.primaryColor : "",
                borderBottomColor: tabSelected === 0 ? theme?.Content?.styles?.primaryColor : ""
              }}
            >
              {t("my-account.profile.personal-data")}
            </li>
            <li
              className={`text-lg cursor-pointer flex flex-1 justify-center border-b ${
                tabSelected === 1 ? selectedClass : ""
              }`}
              onClick={() => {
                selectTab(1);
              }}
              style={{
                color: tabSelected === 1 ? theme?.Content?.styles?.primaryColor : "",
                borderBottomColor: tabSelected === 1 ? theme?.Content?.styles?.primaryColor : ""
              }}
            >
              {t("my-account.profile.billing-information")}
            </li>
          </ul>
          <div className="w-full justify-center flex">
            {tabSelected === 1 && (
              <BillingDataComponent profile={currentProfile} />
            )}
            {tabSelected === 0 && <PersonalData profile={currentProfile} />}
          </div>
        </>
      </div>
    </section>
  );
};
