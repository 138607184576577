import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "../Buttons/SecondaryButton";

export default function Footer() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <footer className="relative bg-gray-900 pt-8 pb-6 max-h-860-px hidden md:block">
        <div className="flex flex-wrap max-w-7xl mx-auto">
          <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0">
            <h3 className="text-2xl font-semibold font-heading text-gray-400 my-4">
              {t("footer.contact-us")}
            </h3>
            <div className="-mx-4">
              <SecondaryButton
                onClick={() => {
                  history.push("/contact");
                }}
              >
                {t("footer.contact-form")}
              </SecondaryButton>
            </div>
          </div>
          <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0">
            <h3 className="text-2xl font-semibold font-heading text-gray-400 my-4">
              {t("footer.links")}
            </h3>
            <ul>
              <li className="text-white">
                <Link to="/" className="hover:underline">
                  <a href="#">{t("footer.home")}</a>
                </Link>
              </li>
              <li className="text-white">
                <Link to="/products">
                  <a href="#" className="hover:underline">
                    {t("footer.products")}
                  </a>
                </Link>
              </li>
              {/*<li className="text-white">*/}
              {/*  <Link to="/resellers">*/}
              {/*    <a href="#" className="hover:underline">*/}
              {/*      {t("footer.resellers")}*/}
              {/*    </a>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="text-white">
                <Link to="/faqs">
                  <a href={"#"} className="hover:underline">
                    {t("footer.faqs")}
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <footer className="relative bg-black pt-8 pb-6 max-h-860-px px-10 flex justify-around text-white hidden md:block">
        <p>EKard © 2024</p>
        <div className="flex flex-1 justify-end">
          <Link to="/legal/privacy">
            <p className="mx-10 hover:underline">
              {t("footer.privacy")}
            </p>
          </Link>
          <Link to="/legal/conditions">
            <p className="mx-10 hover:underline">
              {t("footer.terms")}
            </p>
          </Link>
          <Link to="/legal/cookies">
            <p className="mx-10 hover:underline">
              {t("footer.cookies")}
            </p>
          </Link>
        </div>
      </footer>
    </>
  );
}
