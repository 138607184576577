import React from "react";
import styles from "./Buttons.module.scss";

type ButtonsProperties = {
  children: any;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  type?: "button" | "submit";
};

export const PaymentButton = ({
  children,
  disabled = false,
  loading = false,
  full = false,
  type = "button",
}: ButtonsProperties) => {
  if (loading) {
    return (
      <button
        className={`${styles.disabled} ${
          full ? "w-full" : ""
        } opacity-50 bg-green-700 text-white text   px-4 py-2 rounded shadow  outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150`}
        data-cy="pay-now"
        type={type}
        style={{
          height: 45,
          backgroundColor: "rgb(0, 116, 212)",
        }}
      >
        <i className="fas fa-spinner animate-spin" />
      </button>
    );
  }

  if (disabled) {
    return (
      <button
        className={`${styles.disabled} ${
          full ? "w-full" : ""
        } bg-gray-400  text  px-4 py-2 rounded shadow outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150`}
        data-cy="pay-now"
        type={type}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={`bg-green-700 ${
        full ? "w-full" : ""
      }   text-white active:bg-green-600 hover:bg-green-600 text  px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 mb-3 ease-linear transition-all duration-150`}
      type={type}
      data-cy="pay-now"
      style={{
        height: 45,
        backgroundColor: "rgb(0, 116, 212)",
      }}
    >
      {children}
    </button>
  );
};
