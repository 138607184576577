export function getCurrency(countries: any) {
  const dl = navigator.language || (navigator as any).userLanguage;
  const [_, countryCode] = dl.split("-");
  let currency = "eur";

  for (let i = 0; i < countries.length; i++) {
    if (countries[i].countryCode.toLowerCase() === countryCode.toLowerCase()) {
      currency = countries[i].currencyCode.toLowerCase();
      break;
    }
  }

  if (currency !== "usd" && currency !== "eur" && currency !== "gbp") {
    return "eur";
  }

  return currency;
}

export function getCurrencySymbol(currency: string) {
  if (currency === "eur") {
    return { s: "€", p: "right" };
  }
  if (currency === "usd") {
    return { s: "$", p: "left" };
  }
  if (currency === "gbp") {
    return { s: "£", p: "left" };
  }
}
