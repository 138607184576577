import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-maskedinput";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { ValidateEmail, validateDate } from "../../../services/validators";
import { Verification } from "../../../models/reseller";
import { SecondaryButton } from "../../Buttons/SecondaryButton";

export const OnBoardingStep2 = ({ onSubmit, nextStep, previousStep }: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Verification>({
    FirstName: "Juan",
    LastName: "del Valle",
    Email: "juandvallero@gmail.com",
    DayOfBirth: "19/02/1978",
  });

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dobError, setDOBError] = useState("");

  const validateForm = () => {
    let errors = false;
    if (state.Email === "") {
      if (emailError === "") {
        setEmailError(t("profile.error-email-empty"));
      }
      errors = true;
    } else if (!ValidateEmail(state.Email || "")) {
      if (emailError === "") {
        setEmailError(t("profile.error-email-invalid"));
      }
      errors = true;
    }

    if (state.FirstName === "") {
      if (firstNameError === "") {
        setFirstNameError(t("profile.error-name-empty"));
      }
      errors = true;
    }
    if (state.LastName === "") {
      if (lastNameError === "") {
        setLastNameError(t("profile.error-lastname-empty"));
      }
      errors = true;
    }
    if (!state.DayOfBirth) {
      if (dobError === "") {
        setDOBError(t("profile.error-dob-required"));
      }
      errors = true;
    } else if (!validateDate(state.DayOfBirth) && dobError === "") {
      errors = true;
      setDOBError(t("profile.error-dob-invalid"));
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();

    if (!errors) {
      setLoading(true);
      await onSubmit(1, state);
      nextStep();
      setLoading(false);
    }
  };

  return (
    <div className="w-full px-4 lg:px-10 py-4 justify-center">
      <form>
        <div className="flex flex-wrap w-full mt-6">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-name"
            >
              {t("profile.name")}
            </label>
            <input
              id="grid-name"
              type="text"
              className={`${
                firstNameError !== "" ? "border-1 border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              defaultValue={state.FirstName}
              onChange={(e) => {
                setState({ ...state, FirstName: e.currentTarget.value });
                setFirstNameError("");
              }}
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-lastname"
            >
              {t("profile.lastname")}
            </label>
            <input
              id="grid-lastname"
              type="text"
              className={`${
                lastNameError !== "" ? "border-1 border-red-600" : "border-0"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              defaultValue={state.LastName}
              onChange={(e) => {
                setState({ ...state, LastName: e.currentTarget.value });
                setLastNameError("");
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-10">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("profile.email")}
            </label>
            <div className="flex items-center justify-end">
              <input
                id="grid-email"
                type="email"
                className={`${
                  emailError !== "" ? "border-1 border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                defaultValue={state.Email}
                onChange={(e) => {
                  setState({ ...state, Email: e.currentTarget.value });
                  setEmailError("");
                }}
              />
            </div>
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-email"
            >
              {t("profile.birthday")}
            </label>
            <div className="flex items-center justify-end">
              <MaskedInput
                className={`${
                  dobError !== "" ? "border border-red-600" : "border-0"
                }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
                mask="11/11/1111"
                name="grid-email"
                placeholder="dd/mm/yyyy"
                onChange={(e) => {
                  setState({ ...state, DayOfBirth: e.target.value });
                  setDOBError("");
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12">
          {!loading && (
            <SecondaryButton onClick={previousStep} className="mr-2">
              {t("back")}
            </SecondaryButton>
          )}
          <PrimaryButton
            onClick={handleSubmit}
            loading={loading}
            disabled={validateForm()}
          >
            {t("next")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
