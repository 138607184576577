import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PrimaryButton } from "../../Buttons/PrimaryButton";
import { Verification } from "../../../models/reseller";
import styles from "../../Profile/Profile.module.scss";
import esCountries from "../../../models/countries.json";
import { sepaCountries } from "../../../models/sepa";
import { SecondaryButton } from "../../Buttons/SecondaryButton";

export const OnBoardingStep4 = ({ onSubmit, nextStep, previousStep }: any) => {
  const { t, i18n } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Verification>({
    AccountHolderName: "Juan del Valle",
    AccountCountry: "ES",
    AccountCurrency: "EUR",
    AccountType: "individual",
    AccountNumber: "ES0700120345030000067890",
  });
  const [accountHolderNameError, setAccountHolderNameError] = useState("");
  const [accountCountryError, setAccountCountryError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [isSepa, setIsSepa] = useState(true);

  const validateForm = () => {
    let errors = false;
    if (state.AccountCountry === "") {
      if (accountCountryError === "") {
        setAccountCountryError(t("profile.error-account-country-empty"));
      }
      errors = true;
    }
    if (state.AccountHolderName === "") {
      if (accountHolderNameError === "") {
        setAccountHolderNameError(t("profile.error-account-holder-name-empty"));
      }
      errors = true;
    }

    if (state.AccountNumber === "") {
      if (accountNumberError === "") {
        setAccountNumberError(t("profile.error-account-number-empty"));
      }
      errors = true;
    }
    return errors;
  };

  const createToken = async (): Promise<any> => {
    const ibanElement = elements?.getElement(IbanElement);
    if (!stripe || !ibanElement) {
      return state;
    }

    if (isSepa) {
      const response: any = await stripe?.createToken(ibanElement, {
        currency: "eur",
        account_holder_name: state.AccountHolderName || "",
        account_holder_type: "individual",
      });
      if (response?.token) {
        return { ...state, IbanToken: response.token };
      }
    } else {
      // await stripe?.createToken("bank_account", {
      //   country: state.AccountCountry,
      //   currency: "usd",
      // });
    }

    return state;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const newState = await createToken();
    await onSubmit(3, newState);
    nextStep();
    setLoading(false);
  };

  const handleChangeCountry = (code) => {
    setState({ ...state, AccountCountry: code });
    setAccountCountryError("");
    setIsSepa(sepaCountries.includes(code));
  };

  const locale = i18n.language?.toLowerCase() ?? "";
  return (
    <div className="w-full px-4 lg:px-10 py-4 justify-center">
      <form>
        <div className="flex flex-wrap w-full mt-6">
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-name"
            >
              {t("profile.account-holder-name")}
            </label>
            <input
              id="grid-name"
              type="text"
              className={`${
                accountHolderNameError !== ""
                  ? "border border-red-600"
                  : "border border-green-600"
              }  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
              defaultValue={state.AccountHolderName}
              onChange={(e) => {
                setState({
                  ...state,
                  AccountHolderName: e.currentTarget.value,
                });
                setAccountHolderNameError("");
              }}
            />
          </div>
          <div className="w-6/12 lg:w-6/12 px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
              htmlFor="grid-phone"
            >
              {t("profile.account-country")}
            </label>
            <ReactFlagsSelect
              countries={sepaCountries}
              selectButtonClassName={`${styles.countrySelect} ${
                accountCountryError !== ""
                  ? "border border-red-600 bg-red-200"
                  : "border border-green-600"
              }`}
              className={`${styles.countryFlags}`}
              selected={state.AccountCountry || "ES"}
              onSelect={handleChangeCountry}
              searchable
              showSelectedLabel
              showOptionLabel
              customLabels={locale === "es" ? esCountries : {}}
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full mt-10">
          {isSepa && (
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-email"
              >
                {t("profile.account-iban")}
              </label>
              <div className=" w-full items-center justify-end">
                <IbanElement
                  className={`${
                    accountNumberError !== ""
                      ? "border-red-600"
                      : "border-green-600"
                  } border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear  `}
                  onChange={(event: any) => {
                    if (event.complete) {
                      setState({ ...state, AccountNumber: event.value });
                      setAccountNumberError("");
                    } else if (accountNumberError === "") {
                      setAccountNumberError(
                        t("profile.account-number-invalid")
                      );
                    }
                    return false;
                  }}
                  options={{
                    supportedCountries: ["SEPA"],
                    placeholderCountry: state.AccountCountry,
                  }}
                />
              </div>
            </div>
          )}
          {!isSepa && (
            <div className="w-6/12 lg:w-6/12 px-4">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                htmlFor="grid-email"
              >
                {t("profile.account-iban")}
              </label>
              <div className=" w-full items-center justify-end">
                <input
                  className={`${
                    accountNumberError !== ""
                      ? "border-red-600"
                      : "border-green-600"
                  } border px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-md shadow focus:outline-none focus:ring w-full ease-linear  `}
                  onChange={(event: any) => {
                    setState({ ...state, AccountNumber: event.target.value });
                    setAccountNumberError("");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex justify-end mt-12">
          {!loading && (
            <SecondaryButton onClick={previousStep} className="mr-2">
              {t("back")}
            </SecondaryButton>
          )}

          <PrimaryButton
            onClick={handleSubmit}
            loading={loading}
            disabled={validateForm()}
          >
            {t("next")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
