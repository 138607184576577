import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentIcon } from "./PaymentIcon";
import styles from "./SubscriptionDetails.module.scss";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { gkb } from "../../services/gkb";
import { DownloadUrl } from "../../services/file-download";
import { AddPaymentMethodModal } from "../Modals/AddPaymentMethodModal";
import { useSession } from "react-session-persist/lib";
import { useDispatch } from "react-redux";
import { DispatchSetLoading } from "../../store/ui/actions";
import { notify } from "reapop";
import { getCurrencySymbol } from "../../services/currency";
import { NotificationModal } from "../Modals/NotificationModal/NotificationModal";
import ReactGA from "react-ga";

const dl = navigator.language || (navigator as any).userLanguage;

const showPrice = (price: number, currency: string) => {
  const symbol = getCurrencySymbol(currency);

  const pr = Number(price).toLocaleString(dl, {
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
  });

  if (symbol.p === "right") {
    return `${pr} ${symbol.s}`;
  } else {
    return `${symbol.s} ${pr}`;
  }
};

export const PaymentDetails = ({ invoice, onReload }: any) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language === "es" ? "es-ES" : i18n.language;
  const { user } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);

  const dispatch = useDispatch();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  if (!invoice) {
    return null;
  }

  const paymentDate = invoice.StatusTransitionsFinalizedAt
    ? DateTime.fromISO(invoice.StatusTransitionsFinalizedAt).toLocaleString(
        Object.assign(DateTime.DATE_FULL, { locale })
      )
    : "";

  const total = showPrice((invoice?.Total ?? 0) / 100, invoice?.Currency);
  const subtotal = showPrice((invoice?.Subtotal ?? 0) / 100, invoice?.Currency);
  const tax = showPrice((invoice?.Tax ?? 0) / 100, invoice?.Currency);
  const totalDiscount = showPrice(
    (invoice?.TotalDiscount ?? 0) / 100,
    invoice?.Currency
  );

  const handleDownloadInvoice = async () => {
    const {BillingDataVat, BillingDataName, BillingDataAddress, BillingDataCity, BillingDataCountry, BillingDataPostalCode}  = user as any
    if(!BillingDataVat || !BillingDataName || !BillingDataAddress || !BillingDataCity || !BillingDataCountry || !BillingDataPostalCode) {
      setShowBillingModal(true);
      //alert("Please fill in your billing information before downloading the invoice")
      return null;
    }

    const response = await gkb.DownloadInvoice(invoice.ID);
    if (response?.data) {
      DownloadUrl(response.data.InvoiceUrl, response.data.FileName);
      ReactGA.event({
        category: "payment",
        action: `Download invoice for payment ${invoice.ID}`,
      });
    }
  };

  const handleRequiresAction = async (paymentMethodId?: string) => {
    const confirmation: any = await stripe?.confirmCardPayment(
      invoice.PaymentIntentClientSecret,
      {
        payment_method: paymentMethodId || invoice.PaymentIntentMethodID,
      }
    );
  };

  const handleChangePaymentMethod = () => {
    setShowModal(true);
  };

  const handleChangePaymentMethodComplete = async (intent: any) => {
    DispatchSetLoading(dispatch, true);
    setShowModal(false);
    const response = await gkb.AssignPaymentMethodToSubscription(
      invoice.SubscriptionID,
      intent.payment_method
    );
    if (response?.status === 200) {
      setShowPaymentModal(true);
      ReactGA.event({
        category: "payment",
        action: `Payment method changed for invoice ${invoice.ID}`,
      });
    } else {
      dispatch(notify(t("subscription-method-add-error"), "error"));
    }
    setTimeout(() => {
      DispatchSetLoading(dispatch, false);
      onReload && onReload();
    }, 200);
  };

  const renderProductLine = (item, index) => {
    const productName = item.Description;
    const price = showPrice(item.Amount / 100, invoice?.Currency);

    return (
      <li
        key={`si-${index}`}
        className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600 items-center`}
      >
        {item.status === "COMPLETED" && (
          <span className="mr-2 rounded-full bg-green-400 w-3 h-3 font-bold" />
        )}
        {item.status === "PENDING" && (
          <span className="mr-4 rounded bg-yellow-400 w-3 h-3 font-bold" />
        )}
        <div className="flex-1 text-black text-left">
          {productName}
          <span className="font-bold ml-2">
            {item.quantity > 0 ? `x${item.quantity}` : ""}
          </span>
        </div>
        <div className="flex-1 text-black text-right">{price}</div>
      </li>
    );
  };

  return (
    <>
      {showModal && (
        <AddPaymentMethodModal
          profile={user}
          show={showModal}
          title={t("complete-payment")}
          onComplete={handleChangePaymentMethodComplete}
          onClose={() => setShowModal(false)}
        />
      )}
      {showPaymentModal && (
        <NotificationModal
          show={true}
          title={t("cards.payment-completed")}
          content={t("cards.payment-completed-successfully")}
          onClose={() => {
            setShowPaymentModal(false);
          }}
        />
      )}
      {showBillingModal && (
        <NotificationModal
          show={true}
          title={t("cards.billing-incompleted-title")}
          content={t("cards.billing-incompleted-content")}
          onClose={() => {
            setShowBillingModal(false);
          }}
        />
      )}
      <div className="w-full rounded bg-white shadow-lg">
        <ul className="">
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
          >
            <div className="flex-1 text-green-600 text-left font-bold">
              {t("orders.payment-date")}
            </div>
            <div className="flex-1 font-bold text-center">
              {t("orders.payment-method")}
            </div>
            <div className="flex-1 font-bold text-center">
              {t("orders.reason")}
            </div>
            <div className="flex-1 font-bold text-right">
              {t("orders.status")}
            </div>
          </li>
          <li
            className={`${
              styles.gktable
            } px-4 py-2 border-b flex justify-between text-green-600 items-center ${
              invoice.PaymentStatus === "FAILED" ? "bg-red-100" : ""
            }`}
          >
            <div className="flex-1 text-gray-600 text-left">{paymentDate}</div>
            <div className="flex-1 text-gray-600 text-center flex justify-center items-center">
              <PaymentIcon
                icon={invoice?.PaymentMethod?.card?.brand ?? ""}
                style={{ margin: 10, width: 30 }}
              />
              <p className="text-xs ml-2 italic">{`···· ${
                invoice?.PaymentMethod?.card?.last4 ?? "····"
              }`}</p>
            </div>
            <div className="flex-1 text-gray-600 text-center">
              {t(invoice?.BillingReason || "")}
            </div>
            <div
              className={`flex-1 font-bold text-right ${
                (invoice?.PaymentStatus.toLowerCase() || "") === "failed"
                  ? "text-red-600"
                  : ""
              }`}
            >
              {t(invoice?.PaymentStatus.toLowerCase() || "")}
            </div>
          </li>
        </ul>
      </div>
      <div className="w-full rounded bg-white shadow-lg mt-4">
        <ul>
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
          >
            <div className="flex-1 text-green-600 text-left font-bold">
              {t("orders.product")}
            </div>
            <div className="flex-1 font-bold text-right">Total</div>
          </li>
          {invoice?.Lines?.map((item, i) => renderProductLine(item, i))}
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
          >
            <div className="flex-1 text-green-600 text-left font-bold">
              {t("orders.subtotal")}:
            </div>
            <div className="flex-1 text-black text-right">{subtotal}</div>
          </li>
          {parseInt(totalDiscount, 10) > 0 && (
            <li
              className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
            >
              <div className="flex-1 text-green-600 text-left font-bold">
                {t("orders.discount")}:
              </div>
              <div className="flex-1 text-black text-right">
                -{totalDiscount}
              </div>
            </li>
          )}
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
          >
            <div className="flex-1 text-green-600 text-left font-bold">
              {t("orders.tax")}:
            </div>
            <div className="flex-1  text-black text-right">{tax}</div>
          </li>
          <li
            className={`${styles.gktable} px-4 py-2 border-b flex justify-between text-green-600`}
          >
            <div className="flex-1 text-green-600 text-left font-bold">
              {t("orders.total")}:
            </div>
            <div className="flex-1 font-bold text-black text-right">
              {total}
            </div>
          </li>
        </ul>
      </div>
      <div className="flex justify-end mt-4">
        {invoice &&
          invoice.Status !== "draft" &&
          invoice.PaymentStatus === "SUCCEEDED" && (
            <PrimaryButton onClick={handleDownloadInvoice} className={"mr-4"}>
              {t("download-invoice")}
            </PrimaryButton>
          )}
        {invoice &&
          invoice.PaymentStatus === "FAILED" &&
          invoice.PaymentIntentStatus === "requires_action" && (
            <SecondaryButton onClick={handleRequiresAction}>
              {t("complete-payment")}
            </SecondaryButton>
          )}
        {invoice &&
          invoice.PaymentStatus === "FAILED" &&
          invoice.PaymentIntentStatus === "requires_payment_method" && (
            <PrimaryButton
              className={"bg-blue-200"}
              onClick={handleChangePaymentMethod}
              loading={loading}
            >
              {t("complete-payment")}
            </PrimaryButton>
          )}
      </div>
    </>
  );
};
