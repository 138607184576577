/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useDispatch } from "react-redux";
import { notify } from "reapop";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";
import { gkb } from "../services/gkb";
import image from "../assets/img/send_message.svg";
import { useTheme } from "../store/ui/selector";

export default function NotVerifiedPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { theme } = useTheme();

  const handleResendEmail = async (event: any) => {
    event.preventDefault();
    const parsed = queryString.parse(search);
    const email = (parsed.email as string) || "";

    if (email) {
      const response = await gkb.ReverifyAccount(email);
      if (response?.status === 200) {
        dispatch(notify("Email enviado correctamente", "success"));
      } else {
        dispatch(notify("Error enviando el email", "error"));
      }
    }
  };

  return (
    <section className="pt-12 pb-40 px-4 text-center">
      <h2 className="text-4xl mb-2 leading-tight font-semibold font-heading text-green-800 my-10"
      style={{color: theme?.Content?.styles?.primaryColor}}>
        {t("notverified.account-not-activated")}
      </h2>
      <div className="w-full flex justify-center">
        <img alt="message sended" src={image} height={200} width={200} />
      </div>
      <p className="text-gray-400 text-xl my-10">{t("notverified.info1")}</p>
      <p className="text-gray-400 text-xl my-10">
        {t("notverified.info2")}{" "}
        <span
          className="cursor-pointer underline text-green-600 hover:text-green-400"
          onClick={handleResendEmail}
          style={{color: theme?.Content?.styles?.primaryColor}}
        >
          {t("notverified.info3")}
        </span>{" "}
        {t("notverified.info4")}
      </p>
      <p className="text-gray-400 text-xl my-10">
        {t("notverified.info5")}{" "}
        <Link to="/login">
          <span className="cursor-pointer underline text-green-600 hover:text-green-400"
                style={{color: theme?.Content?.styles?.primaryColor}}>
            {t("notverified.info3")}
          </span>
        </Link>{" "}
        {t("notverified.info6")}
      </p>
    </section>
  );
}
